import React from "react";
import { KTIcon } from "../../../../_metronic/helpers";
import { UsersListFilter } from "./UsersListFilter";
import "./style.css";
import { PatternFormat } from "react-number-format";

const Header = ({ search, setSearch, searchType, setSearchType, setFilter, filter }) => {
  return (
    <>
    <div className="card-header border-0 pt-6">
      <div className="d-flex align-items-center position-relative my-1 search-wrapper" style={{ gap: "15px" }}>
        <select
          className="form-select form-select-solid fw-bolder"
          data-kt-select2="true"
          data-placeholder="Select option"
          data-allow-clear="true"
          data-kt-user-table-filter="two-step"
          data-hide-search="true"
          value={searchType}
          onChange={(e) => {
            setSearch("")
            setSearchType(e.target.value)
          }}

        >
          <option value="reservation_code" selected>
            Reservation Number
          </option>
          <option value="customer_name">
            Member Name
          </option>
          <option value="customer_public_id">
            Member Number
          </option>
          <option value="listing_name">
            Listing Name
          </option>
          <option value="company_name">
            Company Name
          </option>
          <option value="customer_phone">
            Phone Number
          </option>
          <option value="customer_email">
            Email
          </option>
          <option value="plate_number">
            Plate Number
          </option>
          <option value="truck_number">
            Truck Number
          </option>
          <option value="trailer_number">
            Trailer Number
          </option>
          <option value="vehicle_company">
            Vehicle Company Name
          </option>
        </select>

        {searchType &&
          <div className="d-flex align-items-center position-relative my-1">
            <KTIcon
              iconName="magnifier"
              className="fs-1 position-absolute ms-6"
            />
            {searchType !== "customer_phone" ? (
              <input
                type="text"
                data-kt-user-table-filter="search"
                className="form-control form-control-solid w-300px ps-14"
                placeholder={`Search By ${searchType === "reservation_code" ? "Reservation Number"
                  : searchType === "customer_public_id" ? "Member Number"
                    : searchType === "customer_email" ? "Email"
                      : searchType === "plate_number" ? "Plate Number"
                          : searchType === "customer_name" ? "Member Name"
                            : searchType === "listing_name" ? "Listing Name"
                              : searchType === "truck_number" ? "Truck Number"
                                : searchType === "trailer_number" ? "Trailer Number"
                                  : searchType === "company_name" ? "Company Name"
                                    : searchType === "vehicle_company" ? "Vehicle Company Name"
                                  : ""
                }`}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            ) : (
              <PatternFormat
                format={"+1 (###) ###-####"}
                className={"form-control form-control-solid w-300px ps-14"}
                value={search}
                onValueChange={(values) => setSearch(values.value)}
                placeholder="Search By Phone Number"

              />
            )}
          </div>
        }
      </div>
      <div className="card-toolbar">
        <div
          className="d-flex justify-content-end"
          data-kt-user-table-toolbar="base"
        >
          <UsersListFilter setFilter={setFilter} filter={filter} />
        </div>
      </div>
    </div>
    <div className=" card-header form-check form-check-custom form-check-solid mb-2 border-0 pt-1 justify-content-start" style={{minHeight:'auto'}}>
        <input
          className="form-check-input"
          type="checkbox"
          id='is_referral'
          value={filter?.is_referral}
          checked={filter?.is_referral}
          onChange={() => setFilter({
            ...filter,
            is_referral: !filter?.is_referral
          })}
        />
        <label
          className="form-check-label"
          htmlFor="is_referral"
          style={{ fontWeight: "700", color: "#3f4254" }}
        >
          Show Marketing Link Bookings Only
        </label>
      </div>
      </>
  );
};

export default Header;
