import { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import clsx from "clsx";
import axios from "axios";
import { toast } from "react-toastify";

const VehicleModalForm = ({
  user,
  setShowEditModal,
  getUser,
  setVehicle,
  customerDetails,
  hide,
  confirmationModal,
  setConfirmationModal
}) => {
  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const [truckNumber, setTruckNumber] = useState("");
  const [make, setMake] = useState("");
  const [plate, setPlate] = useState("");
  const [model, setModel] = useState("");
  const [trailerNumber, setTrailerNumber] = useState("");
  const [trailerPlate, setTrailerPlate] = useState("");

  const cancel = () => {
    setShowEditModal(false);
    setVehicle(null);
  };

  useEffect(() => {
    if (user) {
      setType(user.vehicle_type);
      setName(user.name);
      setTruckNumber(user.truck_number);
      setMake(user.make);
      setPlate(user.plate_number);
      setModel(user.model);
      setTrailerNumber(user.trailer_number);
      setTrailerPlate(user.trailer_plate);
    } else {
      setType("");
      setName("");
      setTrailerNumber("");
      setMake("");
      setPlate("");
      setModel("");
      setTrailerNumber("");
      setTrailerPlate("");
    }
  }, [user]);

  const editVehicle = () => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/vehicle/${user.id || user.vehicle_id}`, {
          vehicle_type: type,
          ...(name && { name }),
          ...(make && { make }),
          ...(model && { model }),
          ...(+type !== 2 && { plate_number: plate }),
          ...(+type !== 1 && { trailer_plate: trailerPlate }),
          ...(truckNumber && { truck_number: truckNumber }),
          ...(trailerNumber && { trailer_number: trailerNumber })
        }
      )
      .then((res) => {
        toast("Vehicle Details Updated Successfully", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        getUser();
        setShowEditModal(false);
        setVehicle(null);
      })
      .catch((err) => {
        if(err && err.response && err.response?.data && err.response?.data?.message){
          toast(err.response.data.message, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }else{
        toast("Technical Error, Please Try Again", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
      });
  };

  const addVehicle = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/vehicle`, {
          vehicle_type: type,
          ...(name && { name }),
          ...(make && { make }),
          ...(model && { model }),
          ...(plate && { plate_number: plate }),
          ...(trailerPlate && { trailer_plate: trailerPlate }),
          ...(truckNumber && { truck_number: truckNumber }),
          ...(trailerNumber && { trailer_number: trailerNumber }),
          customer_id: customerDetails.id,
        }
      )
      .then((res) => {
        toast("New Vehicle Added Successfully", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        getUser();
        setShowEditModal(false);
        setVehicle(null);
      })
      .catch((err) => {
        if(err && err.response && err.response?.data && err.response?.data?.message){
          toast(err.response.data.message, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }else{
        toast("Technical Error, Please Try Again", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
      });
  };

  const deleteVehicle = () => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/vehicle/${user.id}`)
      .then((res) => {
        toast("Vehicle Deleted Successfully", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        getUser();
        setShowEditModal(false);
        setVehicle(null);
      })
      .catch((err) => {
        if(err && err.response && err.response?.data && err.response?.data?.message){
          toast(err.response.data.message, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }else{
        toast("Technical Error, Please Try Again", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
      });
  };

  const disabled = () => {
    if (!type) return true;
    if (type) {
      if (+type === 1) {
        if (name && make && truckNumber) {
          return false;
        }
      }
      if (+type === 2) {
        if (model && trailerNumber) {
          return false;
        }
      }
      if (+type === 3) {
        if (name && make && truckNumber && model && trailerNumber) {
          return false;
        }
      }
    }
    return true;
  };

  const blankImg = toAbsoluteUrl("/media/logos/blank.png");
  // const userAvatarImg = toAbsoluteUrl(`/media/${userForEdit.avatar}`)

  return (
    <>
      {/* begin::Scroll */}
      <div
        className="d-flex flex-column scroll-y me-n7 pe-7"
        id="kt_modal_add_user_scroll"
        data-kt-scroll="true"
        data-kt-scroll-activate="{default: false, lg: true}"
        data-kt-scroll-max-height="auto"
        data-kt-scroll-dependencies="#kt_modal_add_user_header"
        data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
        data-kt-scroll-offset="300px"
      >
        {user && confirmationModal ?
        <div>
        <h4>
          Are you sure you want to{" "}
          <span style={{ color: "#ed2626", fontWeight:700 }}>Delete</span> this Vehicle {"  "}
          <span style={{ color: "#ed2626", fontWeight:700 }}>{`${user?.vehicle_type === 1 ? "Truck" : user?.vehicle_type === 2 ? "Trailer" : "Truck + trailer"}
           ${user?.vehicle_type === 1 ? user?.truck_number : user?.vehicle_type === 2 ? user?.trailer_number : `${user?.truck_number}/${user?.trailer_number}`}
           `}</span> ?
        </h4>
        <div className="text-center pt-5">
        <button
            type="reset"
            onClick={() => setConfirmationModal(false)}
            className="btn btn-light me-3"
            data-kt-users-modal-action="cancel"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn"
            id='approve'
            style={{ backgroundColor: "#ed2626", color: "#fff" }}
            data-kt-users-modal-action="submit"
            onClick={() => {
              deleteVehicle()
            }}
          >
            <span className="indicator-label">Delete Vehicle</span>
          </button>
        </div>
      </div>
      :
        <>
        <div className="mb-5">
          <label className="form-label fs-6 fw-bold">Vehicle Type:</label>
          <select
            className="form-select form-select-solid fw-bolder"
            data-kt-select2="true"
            data-placeholder="Select option"
            data-allow-clear="true"
            data-kt-user-table-filter="two-step"
            data-hide-search="true"
            value={type}
            onChange={(e) => setType(Number(e.target.value))}
            disabled={user ? true : false}
          >
            <option value="">Select Type</option>
            <option value="1">Truck</option>
            <option value="2">Trailer</option>
            <option value="3">Truck + Trailer</option>
          </select>
        </div>
        {type && (
          <div>
            {type !== 2 && (
              <>
                <div className="fv-row mb-7">
                  <label className="required fw-bold fs-6 mb-2">
                    Company Name
                  </label>
                  <input
                    placeholder="Company Name"
                    type="text"
                    name="companyname"
                    className={clsx(
                      "form-control form-control-solid mb-3 mb-lg-0"
                    )}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoComplete="off"
                  />
                </div>
                <div className="fv-row mb-7">
                  <label className="required fw-bold fs-6 mb-2">Truck #</label>
                  <input
                    placeholder="Truck #"
                    type="text"
                    name="truckNumber"
                    className={clsx(
                      "form-control form-control-solid mb-3 mb-lg-0"
                    )}
                    value={truckNumber}
                    onChange={(e) => setTruckNumber(e.target.value)}
                    autoComplete="off"
                  />
                </div>
                <div className="fv-row mb-7">
                  <label className="required fw-bold fs-6 mb-2">Truck Make</label>
                  <input
                    placeholder="Truck Make"
                    type="text"
                    name="make"
                    className={clsx(
                      "form-control form-control-solid mb-3 mb-lg-0"
                    )}
                    value={make}
                    onChange={(e) => setMake(e.target.value)}
                    autoComplete="off"
                  />
                </div>
                <div className="fv-row mb-7">
                  <label className="fw-bold fs-6 mb-2">
                    Truck Plate # (optional)
                  </label>
                  <input
                    placeholder="Truck Plate #"
                    type="text"
                    name="plate"
                    className={clsx(
                      "form-control form-control-solid mb-3 mb-lg-0"
                    )}
                    value={plate}
                    onChange={(e) => setPlate(e.target.value)}
                    autoComplete="off"
                  />
                </div>
              </>
            )}
            {type !== 1 && (
              <>
                <div className="fv-row mb-7">
                  <label className="required fw-bold fs-6 mb-2">Trailer Type</label>
                  <input
                    placeholder="Trailer Type"
                    type="text"
                    name="model"
                    className={clsx(
                      "form-control form-control-solid mb-3 mb-lg-0"
                    )}
                    value={model}
                    onChange={(e) => setModel(e.target.value)}
                    autoComplete="off"
                  />
                </div>
                <div className="fv-row mb-7">
                  <label className="required fw-bold fs-6 mb-2">Trailer #</label>
                  <input
                    placeholder="Trailer #"
                    type="text"
                    name="trailerNumber"
                    className={clsx(
                      "form-control form-control-solid mb-3 mb-lg-0"
                    )}
                    value={trailerNumber}
                    onChange={(e) => setTrailerNumber(e.target.value)}
                    autoComplete="off"
                  />
                </div>
              {type == 2 &&  <div className="fv-row mb-7">
                  <label className="fw-bold fs-6 mb-2">
                    Company Name (optional)
                  </label>
                  <input
                    placeholder="Company Name"
                    type="text"
                    name="companyname"
                    className={clsx(
                      "form-control form-control-solid mb-3 mb-lg-0"
                    )}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoComplete="off"
                  />
                </div>
                }
                <div className="fv-row mb-7">
                  <label className="fw-bold fs-6 mb-2">
                    Trailer Plate # (optional)
                  </label>
                  <input
                    placeholder="Trailer Plate #"
                    type="text"
                    name="trailer_plate"
                    className={clsx(
                      "form-control form-control-solid mb-3 mb-lg-0"
                    )}
                    value={trailerPlate}
                    onChange={(e) => setTrailerPlate(e.target.value)}
                    autoComplete="off"
                  />
                </div>
              </>
            )}
          </div>
        )}
        </>
        }
      </div>
      {/* end::Scroll */}

      {/* begin::Actions */}
     {!confirmationModal &&
      <div className="text-center pt-15">
        <button
          type="reset"
          onClick={() => cancel()}
          className="btn btn-light me-3"
          data-kt-users-modal-action="cancel"
        >
          Discard
        </button>
        {user && !hide && (
          <button
            type="reset"
            onClick={() => setConfirmationModal(true)}
            className="btn btn-danger me-3"
            data-kt-users-modal-action="cancel"
          >
            Delete Vehicle
          </button>
        )}
        <button
          type="submit"
          className="btn"
          data-kt-users-modal-action="submit"
          style={{ background: "#ffa459", color: "#fff" }}
          disabled={disabled()}
          onClick={user ? editVehicle : addVehicle}
        >
          <span className="indicator-label">
            {user ? "Edit Vehicle" : "Add Vehicle"}
          </span>
        </button>
      </div>
      }
      {/* end::Actions */}
    </>
  );
};

export { VehicleModalForm };
