import { useNavigate } from "react-router-dom";

const ListingModalForm = ({
  selectedReport,
  setSelectedReport,
  setOpenListingModal
}) => {
const navigate = useNavigate()

  const cancel = () => {
    setOpenListingModal(false);
    setSelectedReport(null);
  };


  return (
    <>
      <div className="kt_body">
        <div style={{ maxHeight: '600px', overflowY: 'auto', minHeight: 'auto', display: 'grid', gap: '10px' }}>
          {
            selectedReport?.listings && selectedReport?.listings?.length > 0 ?
              <>
                {selectedReport?.listings?.map((item, index) => {
                  return <div
                    className="form-check form-check-custom form-check-solid d-flex align-items-center justify-content-between flex-wrap pointer"
                    key={index}
                    style={{
                      background: "#f9f9f9",
                      padding: "10px",
                      borderRadius: "10px",
                    }}
                    onClick={() => navigate(`/listings/${item.id}`)}
                  >
                    <h5 className="mb-0 fw-bolder text-main">{item.name}</h5>
                  </div>
                })
                }
              </>
              :
              <h4>No Listings Assigned</h4>
          }
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <button
            type="button"
            className="btn mt-4 w-100 btn-primary"
            onClick={() => cancel()}
            style={{
              color: "#fff",
              height: "46px",
              fontWeight: "700",
            }}
          >
            Go Back
          </button>
        </div>
      </div>
    </>
  );
};

export { ListingModalForm };
