import {ListingBookingModalForm} from './ListingBookingModalForm'


const ListingBookingModalFormWrapper = ({selectedListingId, setSelectedListingId}) => {
    
  if (selectedListingId) {
    return <ListingBookingModalForm setSelectedListingId={setSelectedListingId} selectedListingId={selectedListingId} />
  }
  return null
}

export {ListingBookingModalFormWrapper}
