
const NotifierEmailModalForm = ({
  selectedReport,
  setSelectedReport,
  openNotifierEmailModal,
  setOpenNotifierEmailModal,
}) => {
  const cancel = () => {
    setOpenNotifierEmailModal({
      status: false,
      type: ''
    });
    setSelectedReport(null);
  };


  return (
    <>
      <div className="kt_body">
        <div style={{ maxHeight: '600px', overflowY: 'auto', minHeight: 'auto', display: 'grid', gap: '10px' }}>
          {openNotifierEmailModal?.type === 'sent' ?
            selectedReport?.sent_to && selectedReport?.sent_to?.length > 0 ?
              <>
                {selectedReport?.sent_to?.map((item, index) => {
                  return <div
                    className="form-check form-check-custom form-check-solid d-flex align-items-center justify-content-between flex-wrap"
                    key={index}
                    style={{
                      background: "#f9f9f9",
                      padding: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    <h5 className="mb-0 fw-bolder text-main">{item}</h5>
                  </div>
                })
                }
              </>
              :
              <h4>No Emails</h4>
            :
            openNotifierEmailModal?.type === 'admin' ?
              selectedReport?.member_emails && selectedReport?.member_emails?.length > 0 ?
                <>
                  {selectedReport?.member_emails?.map((item, index) => {
                    return <div
                      className="form-check form-check-custom form-check-solid d-flex align-items-center justify-content-between flex-wrap"
                      key={index}
                      style={{
                        background: "#f9f9f9",
                        padding: "10px",
                        borderRadius: "10px",
                      }}
                    >
                      <h5 className="mb-0 fw-bolder text-main">{item}</h5>
                    </div>
                  })
                  }
                </>
                :
                <h4>No Admin Emails</h4>
              :
              selectedReport?.emails && selectedReport?.emails?.length > 0 ?
                <>
                  {selectedReport?.emails?.map((item, index) => {
                    return <div
                      className="form-check form-check-custom form-check-solid d-flex align-items-center justify-content-between flex-wrap"
                      key={index}
                      style={{
                        background: "#f9f9f9",
                        padding: "10px",
                        borderRadius: "10px",
                      }}
                    >
                      <h5 className="mb-0 fw-bolder text-main">{item}</h5>
                    </div>
                  })
                  }
                </>
                :
                <h4>No Notifier Emails</h4>
          }
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <button
            type="button"
            className="btn mt-4 w-100 btn-primary"
            onClick={() => cancel()}
            style={{
              color: "#fff",
              height: "46px",
              fontWeight: "700",
            }}
          >
            Go Back
          </button>
        </div>
      </div>
    </>
  );
};

export { NotifierEmailModalForm };
