import { KTIcon } from '../../../../_metronic/helpers'
const ScheduleReportsHeader = ({
  setOpenNewReportModal,
  currentUser,
  haveAccess
}) => {
  return (
    <>
      <div className='card-header border-0 pt-6 justify-content-end align-items-center'>
        <div className='card-toolbar' style={{ gap: '5px' }}>
        {(haveAccess(currentUser, 'reports_create')) && <button type='button' className='btn text-white' style={{ backgroundColor: "#2ecc71" }}
            onClick={() => {
              setOpenNewReportModal(true)
            }}
          >
            <KTIcon iconName='plus' className='fs-2 text-white' />
            Add New Scheduler
          </button>
          }
        </div>
        {/* end::Card toolbar */}
      </div>
    </>
  )
}

export { ScheduleReportsHeader }
