import {AddNewReportModalForm} from './AddNewReportModalForm'

const AddNewReportModalFormWrapper = ({
    setOpenNewReportModal,
    getScheduleReports,
    selectedReport,
    setSelectedReport,
    daysOfWeek
}) => {
    return <AddNewReportModalForm
    setOpenNewReportModal={setOpenNewReportModal}
    getScheduleReports={getScheduleReports}
    selectedReport={selectedReport}
    setSelectedReport={setSelectedReport}
    daysOfWeek={daysOfWeek}
    />

}

export {AddNewReportModalFormWrapper}
