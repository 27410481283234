import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const WalletFreezeModalForm = ({
  setOpenFreezeWalletModal,
  getUser,
  user
}) => {
  const [enabled, setEnabled] = useState();

  const cancel = () => {
    setOpenFreezeWalletModal(false);
  };

  useEffect(() => {
    setEnabled(user?.is_wallet_active);
  }, [user])

  const walletFreeze = () => {
    axios.patch(`${process.env.REACT_APP_API_URL}/user/${user.uid}/wallet`, {
      is_active: !enabled
    })
      .then(() => {
        toast.success(`${enabled ? "Wallet have been Freezed" : "Wallet have been Unfreezed"} for this user successfully`);
        getUser();
        cancel();
      })
      .catch((err) => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Technical Error, Please Try Again");
        }
      });
  }

  return (
    <>
      <div
        className="d-flex flex-column scroll-y me-n7 pe-7"
        id="kt_modal_add_user_scroll"
        data-kt-scroll="true"
        data-kt-scroll-activate="{default: false, lg: true}"
        data-kt-scroll-max-height="auto"
        data-kt-scroll-dependencies="#kt_modal_add_user_header"
        data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
        data-kt-scroll-offset="300px"
      >
        <div>
          <h4>
            Are you sure you want to{" "}
            <span className="text-danger fw-bolder">{enabled ? 'Freeze' : 'Unfreeze'}</span> the wallet for this user
          </h4>
          <div className="text-center pt-5">
            <button
              type="reset"
              onClick={() => cancel()}
              className="btn btn-light me-3"
              data-kt-users-modal-action="cancel"
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`btn ${enabled ? 'btn-danger' : 'btn-success'}`}
              id='approve'
              data-kt-users-modal-action="submit"
              onClick={() => {
                walletFreeze();
              }}
            >
              <span className="indicator-label">Confirm</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export { WalletFreezeModalForm };
