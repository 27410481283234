import { useState, useEffect } from "react";
import axios from "axios";
import {
  KTCard,
  KTIcon,
  useDebounce,
  KTCardBody
} from "../../../_metronic/helpers";
import Header from "./components/Header";
import { useNavigate, Link, useSearchParams, useLocation } from "react-router-dom";
import moment from "moment-timezone";
import clsx from "clsx";
import { toast } from "react-toastify";
import { VehicleEditModal } from "./vehicleUpdateModal/VehicleEditModal";
import { BookingEditModal } from "./editBookingModal/BookingEditModal";
import { ExtendModal } from "./ExtendModal/ExtendModal";
import "./style.css";
import { convertUTCTimeToListingTime } from "../../../_metronic/helpers/convertTime.js";
import { useAuth } from "../auth";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { DiscountModal } from "./discountModal/DiscountModal";
import { formatPrice, BookingStatus, getBookingStatusText, generateURL, isSearchAndFilterApplied, ToastStyle } from "../../../_metronic/helpers/utils";

const Index = () => {
  const { pathname, search: urlParams } = useLocation()
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
  const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
  const { currentUser, bookingPage, setBookingPage, haveAccess, walletsEnabled } = useAuth();
  const [search, setSearch] = useState(undefined);
  const [searchType, setSearchType] = useState("reservation_code");
  const [filter, setFilter] = useState({
    status: "",
    type: "",
    is_referral: false
  });
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [listings, setListings] = useState([]);
  const [total, setTotal] = useState(null);
  const [bookingsCount, setBookingsCount] = useState({});
  const [notifications, setNotifications] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [vehicles, setVehicles] = useState([]);
  const [openEditBookingModal, setOpenEditBookingModal] = useState(false);
  const [openDiscountModal, setOpenDiscountModal] = useState(false);
  const [bookingToDiscount, setBookingToDiscount] = useState(null);
  const [openExtendModal, setOpenExtendModal] = useState(false);
  const navigate = useNavigate();
  const [shareActive, setShareActive] = useState({
    status: false,
    id: null
  });
  const [email, setEmail] = useState("");
  const [expandMore, setExpandMore] = useState({
    status: false,
    id: null
  });
  const [showDeleteModal, setShowDeleteModal] = useState({
    status: false,
    id: null
  });
  const [giveRefund, setGiveRefund] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState({
    status: false,
    id: null
  });

  // const { placePredictions, isPlacePredictionsLoading } =
  //   useGoogle({
  //     apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  //   });

  const [bookingDetails, setBookingDetails] = useState(null);
  const [refundReasonType, setRefundReasonType] = useState("");
  const [reason, setReason] = useState("");
  const [refundAmount, setRefundAmount] = useState('');
  const [walletRefundAmount, setWalletRefundAmount] = useState('');
  const [cardRefundAmount, setCardRefundAmount] = useState('');
  const [step, setStep] = useState(1);
  const [refundData, setRefundData] = useState(null);
  const [isReversal, setReversal] = useState(false);
  const [notes, setNotes] = useState("");
  const [cancellationType, setCancellationType] = useState("");
  const [showPriceBreakup, setShowPriceBreakup] = useState({
    id: null,
    status: false
  });
  const isProd = STRIPE_PUBLIC_KEY.indexOf('pk_live') === 0
  const [cardDetails, setCardDetails] = useState(null);
  const searchParamSearchType = searchParams.get('search_type');
  const searchParamSearch = searchParams.get('search')
  const searchParamBookingStatus = searchParams.get("booking_status");
  const searchParamBookingType = searchParams.get("booking_type");
  const searchParamIsReferral = searchParams.get("is_referral");
  const searchParamPage = searchParams.get("page");
  const searchParamPageSize = searchParams.get("page_size");
  const [lastLocation, setLastLocation] = useState(null);
  const [loading, setLoading] = useState(false)


  const paymentMode = {
    1: "Card",
    2: "ACH",
    3: "Google Pay",
    4: "Apple Pay"
  }


  useEffect(() => {
    if (searchParamSearchType || searchParamSearch || searchParamBookingStatus || searchParamBookingType || searchParamPage || searchParamPageSize || searchParamIsReferral) {
      setSearchType(searchParamSearchType ? searchParamSearchType : 'reservation_code')
      setSearch(searchParamSearch ? searchParamSearch : '')
      setFilter({ ...filter, status: searchParamBookingStatus, type: searchParamBookingType, is_referral: searchParamIsReferral ? true : false });
      setPage(searchParamPage ? parseInt(searchParamPage) : 1)
      setBookingPage(searchParamPage ? parseInt(searchParamPage) : 1)
      setPageSize(searchParamPageSize ? parseInt(searchParamPageSize) : 10) 
    } else {
      setSearchType("reservation_code");
      setSearch("")
      setFilter({ status: "", type: "", is_referral: false });
      setPage(1)
      setBookingPage(1)
      setPageSize(10)
    }
  }, [searchParams]);

  useEffect(() => {
    setWalletRefundAmount(refundAmount)
    if (refundAmount === '') {
      setCardRefundAmount('')
    } else {
      setCardRefundAmount('0')
    }
  }, [refundAmount])

  const requestCancellation = (refund) => {
    setLoading(true)
    axios.post(`${process.env.REACT_APP_API_V2_URL}/booking/cancellation`, {
      ...(bookingDetails?.status !== 11 && { refund_amount: refundAmount * 100}),
      ...(bookingDetails?.status !== 11 && {is_refund: !refund ? giveRefund : refund}),
      ...(giveRefund && {stripe_amount: cardRefundAmount * 100}),
      booking_id: bookingDetails.booking_id,
      ...(bookingDetails?.status !== 11 && {reversal_from_parking: isReversal}),
      reason,
      notes,
      refund_reason: refundReasonType,
    })
      .then(() => {
        fetchListing();
        bookingById(bookingDetails.booking_id);
        if(!giveRefund && cancellationType !== 'upcoming') {
          toast.success("Booking canceled successfully without refund");
        }else if (cancellationType === 'upcoming' && !giveRefund) {
          toast.success("Upcoming Subscription has been canceled successfully");
        }else if (giveRefund) {
          toast.success("Cancellation request successfully created");
        }
        setShowDeleteModal({
          status: false,
          id: null,
          reservation_number: null,
          type: null
        });
        setGiveRefund(false);
        setReason("");
        setNotes("");
        setReversal(false);
        setRefundAmount('');
        setRefundReasonType("");
        setCancellationType("");
        setLoading(false)
      })
      .catch(err => {
        toast.error(err.response.data.message);
        setLoading(false)
      });
  };

  // show update vehicle modal
  const openEditUserVehicleModal = (id, booking) => {
    setSelectedBookingId(id);
    setVehicles(booking?.vehicles);
  };

  useEffect(() => {
    setShareActive({
      status: false,
      id: null
    });
  }, [searchType]);


  // fetch Booking list
  const fetchListing = () => {
    let queryString = ''
    switch (filter.status) {
      case 'pending':
        queryString += 'status=0'
        break;
      case 'canceled':
        queryString += 'status=4,9'
        break
      case 'completed':
        queryString += 'status=6'
        break
      case 'active_not_renewed':
        queryString += 'status=8'
        break
      case 'refunded':
        queryString += 'status=9,10'
        break
      case 'ongoing':
        queryString += 'send_ongoing=true'
        break
      case 'upcoming':
        queryString += 'send_upcoming=true'
        break
      case 'due':
        queryString += 'status=11'
        break
      case 'failed':
        queryString += 'status=7'
        break
      case 'suspended':
        queryString += 'status=12'
        break
      default:
        queryString += ''
    }

    axios.get(`${process.env.REACT_APP_API_URL}/booking?page=${bookingPage}&page_size=${pageSize}&sort=4&` +
      `${(searchType && debouncedSearchTerm) ?
      `${searchType}=${encodeURIComponent(debouncedSearchTerm?.trim())}&` : ""}` +
      `${filter.type ? `booking_type=${filter.type}&` : ""}` + 
      `${filter.is_referral ? `is_referral=${filter.is_referral}&` : ""}` +
      queryString
    )
      .then((res) => {
        setListings(res.data?.bookings);
        setTotal(res.data?.total || 0);
      })
      .catch((err) => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast(err.response.data.message, ToastStyle);
        } else {
          toast("Technical Error, Please Try Again", ToastStyle);
        }
      });
  };

  // useEffect(() => {
  //   if (params.res_code && search) {
  //     fetchListing();
  //   }
  // }, [params.res_code]);

  // cancel Booking/subscription
  const cancelBooking = (id, type) => {
    axios
      .patch(`${process.env.REACT_APP_API_URL}/booking/${id}/cancel?refund=${giveRefund}`)
      .then((res) => {
        if (type == 4) {
          toast(`Subscription has been Canceled Successfully`, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark"
          });
        } else {
          toast(`Booking Canceled Successfully ${giveRefund ? "with refund" : "without refund"}`, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark"
          });
        }
        fetchListing();
        setShowDeleteModal({
          status: false,
          id: null,
          reservation_number: null,
          type: null,
          refund: false
        });
        setGiveRefund(false);
      })
      .catch((err) => {
        if (err && err.response && err.response.data && err.response.data.message === "Can't cancel bookings after clock-in time") {
          setShowConfirmDeleteModal({
            status: true,
            id: id,
            type: type,
            reservation_number: showDeleteModal.reservation_number,
            error_type: "clock_in"
          });
          setShowDeleteModal({
            status: false,
            id: null,
            reservation_number: null,
            type: null,
            refund: false
          });
        } else if ((type == 4) && err && err.response && err.response?.data && err.response?.data?.message) {
          setShowConfirmDeleteModal({
            status: true,
            id: id,
            type: type,
            reservation_number: showDeleteModal.reservation_number,
            error_type: ""
          });
          setShowDeleteModal({
            status: false,
            id: null,
            reservation_number: null,
            type: null,
            refund: false
          });
        } else {
          if (err && err.response && err.response?.data && err.response?.data?.message) {
            toast(err.response.data.message, {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark"
            });
          } else {
            toast("Technical Error, Please Try Again", {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark"
            });
          }
        }
      });
  };


  const cancelSubRefund = (id, type) => {
    axios.patch(`${process.env.REACT_APP_API_URL}/booking/${id}/cancel?cancel_now=true&refund=true`).then((res) => {
      toast(`Subscription has been Canceled Successfully with Refund`, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      });
      fetchListing();
      setShowDeleteModal({
        status: false,
        id: null,
        reservation_number: null,
        type: null,
        refund: false
      });
    }).catch((err) => {
      if (err && err.response && err.response?.data && err.response?.data?.message) {
        toast(err.response.data.message, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        });
      } else {
        toast("Technical Error, Please Try Again", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        });
      }
    });
  };

  const cancelForceBooking = (id, type) => {
    axios.patch(`${process.env.REACT_APP_API_URL}/booking/${id}/cancel?force_cancel=${true}&refund=${giveRefund}`).then((res) => {
      if (type == 4) {
        toast(`Subscription has been Canceled Successfully`, ToastStyle);
      } else {
        toast(`Booking Canceled Successfully ${giveRefund ? "with refund" : "without refund"}`, ToastStyle);
      }
      fetchListing();
      setShowConfirmDeleteModal({
        status: false,
        id: null,
        reservation_number: null,
        type: null
      });
      setGiveRefund(false);
    }).catch((err) => {
      if (err && err.response && err.response?.data && err.response?.data?.message) {
        toast(err.response.data.message, ToastStyle);
      } else {
        toast("Technical Error, Please Try Again", ToastStyle);
      }
    });
  };

  const cancelBookingLocal = (id) => {
    axios.patch(`${process.env.REACT_APP_API_URL}/booking/${id}/cancel-local`).then((res) => {
      toast("Subscription has been Canceled Successfully", ToastStyle);
      fetchListing();
      setShowConfirmDeleteModal({
        status: false,
        id: null,
        reservation_number: null,
        type: null
      });
    }).catch((err) => {
      if (err && err.response && err.response?.data && err.response?.data?.message) {
        toast(err.response.data.message, ToastStyle);
      } else {
        toast("Technical Error, Please Try Again", ToastStyle);
      }
    });
  };


  const sendRefund = () => {
    axios.post(`${process.env.REACT_APP_API_V2_URL}/booking/refund`, {
      refund_amount: refundAmount * 100,
      booking_id: bookingDetails.booking_id,
      reversal_from_parking: isReversal,
      reason,
      notes
    }).then((res) => {
      fetchListing();
      toast.success("Refund Successful");
      setShowDeleteModal({
        status: false,
        id: null,
        reservation_number: null,
        type: null
      });
      setGiveRefund(false);
      setReason("");
      setNotes("");
      setReversal(false);
      setRefundAmount('');
    }).catch((err) => {
      if (err && err.response && err.response?.data && err.response?.data?.message) {
        toast(err.response.data.message, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        });
      } else {
        toast("Technical Error, Please Try Again", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        });
      }
    });
  };


  // pagination
  const updatePage = (type) => {
    if (type === "dec" && bookingPage > 1 && bookingPage !== 1) {
      setPage(bookingPage - 1);
      setBookingPage(bookingPage - 1);
    } else if (type === "inc" && bookingPage >= 1) {
      if (bookingPage !== Math.ceil(total / pageSize)) {
        setPage(bookingPage + 1);
        setBookingPage(bookingPage + 1);
      }
    } else {
      setPage(type)
      setBookingPage(type)
    }
    window.scrollTo(0, 0);
  };

  // debounce search
  const debouncedSearchTerm = useDebounce(search, 700);
  const debounceFilter = useDebounce(filter, 500);
  // Effect for API call
  useEffect(() => {
    if (debouncedSearchTerm !== undefined && search !== undefined) {
      // setPage(1);
      const newUrl = generateURL('/bookings', {
        page: total < pageSize ? 1 : page,
        page_size: pageSize,
        search_type: searchType,
        search: debouncedSearchTerm,
        booking_type: debounceFilter?.type,
        booking_status: debounceFilter?.status,
        is_referral: debounceFilter?.is_referral
      })
      if (lastLocation && lastLocation !== newUrl) {
        navigate(newUrl)
      }
      setLastLocation(newUrl)
      fetchListing();
    }
  }, [debouncedSearchTerm, debounceFilter, page, pageSize, total]);

  useEffect(() => {
    if(total && (Math.ceil(total / pageSize) < page)){
      setPage(1);
      setBookingPage(1)
    }
  },[total, page])
  

  // useEffect(() => {
  //   if (search && debouncedSearchTerm) {
  //     setPage(1);
  //     setBookingPage(1);
  //   }
  //   if (pageSize !== 10) {
  //     setPage(1);
  //     setBookingPage(1);
  //   }
  // }, [debouncedSearchTerm, pageSize]);


  // booking type
  const duration = (duration) => {
    switch (duration) {
      case 1:
        return "Per Hour";
        break;
      case 2:
        return "Per Day";
        break;
      case 3:
        return "Per Week";
        break;
      case 4:
        return "Recurring Subscription";
        break;
    }
  };

  const getHours = (bookingData, timezone) => {
    const start = moment.tz(bookingData.start_date, timezone).utc();
    const end = moment.tz(bookingData.end_date, timezone).add(1, "minute").utc();
    return end.diff(start, "hours");
  };

  const getDays = (bookingData, timezone) => {
    const start = moment.tz(bookingData.start_date, timezone).utc();
    const end = moment.tz(bookingData.end_date, timezone).add(1, "minute").utc();
    return end.diff(start, "days");
  };


  const getWeeks = (bookingData, timezone) => {
    const start = moment.tz(bookingData.start_date, timezone).utc();
    const end = moment.tz(bookingData.end_date, timezone).add(1, "minute").utc();
    return end.diff(start, "weeks");
  };

  // get duration for the booking
  const getDuration = (bookingData, timezone) => {
    if (bookingData.booking_type === 1) {
      return {
        amount: getHours(bookingData, timezone),
        type: +getHours(bookingData, timezone) === 1 ? "hour" : "hours"
      };
    } else if (bookingData.booking_type === 2) {
      return {
        amount: getDays(bookingData, timezone),
        type: +getDays(bookingData, timezone) === 1 ? "day" : "days"
      };
    } else if (bookingData.booking_type === 3) {
      return {
        amount: getWeeks(bookingData, timezone),
        type: "week"
      };
    } else if (bookingData.booking_type === 4) {
      return {
        amount: 1,
        type: "month"
      };
    }
  };


  // calculating booking status
  const checkEdit = (d, t, e, o) => {
    const date = moment(d).tz(o).format();
    const diff = moment(date)?.diff(moment()?.tz(o)?.format());
    if (diff < 0 && moment(e).tz(o).diff(moment().tz(o).format()) > 0) {
      return "In Progress";
    } else if (diff > 0) {
      return "Upcoming";
    } else if (diff < 0 && moment(e).tz(o).diff(moment().tz(o).format()) < 0) {
      return "Completed";
    }
  };


  // share invoice
  const share = (selectedBookingId, type) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/booking/${selectedBookingId}/invoice?invoice_type=customer`,
        {
          email: email.trim()
        }
      )
      .then((res) => {
        setShareActive({
          status: false,
          id: null
        });
        setEmail();
        toast(
          `Invoice Shared Successfully ${type === "owner" ? "to Owner" : "to Customer"
          }`,
          ToastStyle
        );
      })
      .catch((err) => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast(err.response.data.message, ToastStyle);
        } else {
          toast("Technical Error, Please Try Again", ToastStyle);
        }
      });
  };

  // redirect to user/customer profile
  const openProfile = (id, type) => {
    axios.get(`${process.env.REACT_APP_API_URL}/booking/${id}`).then((res) => {
      if (res.data) {
        if (type === "owner-profile") {
          navigate(`/users/${res.data?.owner_uid}`);
        } else if (type === "customer-profile") {
          navigate(`/users/${res.data?.customer_uid}`);
        } else if (type === "owner-stripe") {
          if (isProd) {
            window.open(
              `https://dashboard.stripe.com/applications/users/${res.data?.owner_stripe}`,
              "_blank"
            );
          } else {
            window.open(
              `https://dashboard.stripe.com/test/applications/users/${res.data?.owner_stripe}`,
              "_blank"
            );
          }
        } else if (type === "customer-stripe") {
          if (isProd) {
            window.open(
              `https://dashboard.stripe.com/customers/${res.data?.customer_stripe}`,
              "_blank"
            );
          } else {
            window.open(
              `https://dashboard.stripe.com/test/customers/${res.data?.customer_stripe}`,
              "_blank"
            );
          }
        }
      } else {
        toast("Technical Error, Please Try Again", ToastStyle);
      }
    }).catch((err) => {
      if (err && err.response && err.response?.data && err.response?.data?.message) {
        toast(err.response.data.message, ToastStyle);
      } else {
        toast("Technical Error, Please Try Again", ToastStyle);
      }
    });
  };


  const bookingById = (id) => {
    axios.get(`${process.env.REACT_APP_API_URL}/booking/${id}`).then((res) => {
      let tempData = listings.find((item) => item.booking_id === res.data.booking_id);
      if (tempData) {
        setBookingDetails({
          ...tempData,
          ...res.data,
        });
      } else {
        setBookingDetails(res.data);
      }
      setListings(listings.map((item) => {
        if (item.booking_id === res.data.booking_id) {
          return {
            ...item,
            status: res.data.status,
            promo_discount: res.data.promo_discount,
            payment_id_sub: res.data.payment_id,
            subscription_id: res?.data?.subscription_id
          };
        } else {
          return item;
        }
      }));
    });
  };

  const retryPayment = (booking) => {
    axios.post(`${process.env.REACT_APP_API_URL}/booking/${booking?.booking_id}/retry`).then(() => {
      toast(
        `Payment Successful for #${booking?.reservation_code} Reservation Number`, ToastStyle);
      fetchListing();
      bookingById(booking?.booking_id);
    }).catch((err) => {
      if (err && err.response && err.response?.data && err.response?.data?.message) {
        toast(err.response.data.message, ToastStyle);
      } else {
        toast("Technical Error, Please Try Again", ToastStyle);
      }
    })
  }

  const checkCardDetails = (booking) => {
    axios.get(`${process.env.REACT_APP_API_URL}/booking/${booking?.booking_id}/failure-details`).then((res) => {
      setCardDetails(res.data);
    }).catch((err) => {
      if (err && err.response && err.response?.data && err.response?.data?.message) {
        toast(err.response.data.message, ToastStyle);
      } else {
        toast("Technical Error, Please Try Again", ToastStyle);
      }
    })
  }


  useEffect(() => {
    // if (params.res_code) {
    //   setSearchType("reservation_code");
    //   setSearch(params.res_code);
    // }

    axios.get(`${process.env.REACT_APP_API_URL}/booking/count-by-status`).then((res) => {
      setBookingsCount(res.data)
    })
  }, []);


  // const { state } = useLocation();
  // useEffect(() => {
  //   if (state && state.reservationNumber) {
  //     setSearch(state.reservationNumber);
  //   }
  // }, [state]);

  useEffect(() => {
    if (showDeleteModal.status) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [showDeleteModal]);

  return (
    (haveAccess(currentUser, 'booking_list')) ?
      <>
        <Elements stripe={stripePromise}>
          <KTCard>
            <Header
              search={search}
              setSearch={setSearch}
              searchType={searchType}
              setSearchType={setSearchType}
              setFilter={setFilter}
              filter={filter}
            />
            <KTCardBody className="py-0">
              <h6
                style={{
                  color: "#7e8299",
                  textAlign: "left"
                }}>
                {isSearchAndFilterApplied(search, filter, total)}
              </h6>
            </KTCardBody>
            <KTCardBody className="py-5">
              {currentUser.access_level !== 3 && <div className="d-flex justify-content-end align-items-center"
                style={{ gap: '10px' }}
              >
                <h6
                  style={{
                    color: "#7e8299",
                    textAlign: "right"
                  }}
                >
                  Upcoming Bookings: {bookingsCount?.upcoming || 0}
                </h6>
                <h6
                  style={{
                    color: "#7e8299",
                    textAlign: "right"
                  }}
                >
                  Ongoing Bookings: {bookingsCount?.ongoing || 0}
                </h6>
                <h6
                  style={{
                    color: "#7e8299",
                    textAlign: "right"
                  }}
                >
                  Completed Bookings: {bookingsCount[6] || 0}
                </h6>
                <h6
                  style={{
                    color: "#7e8299",
                    textAlign: "right"
                  }}
                >
                  Total Successful Bookings: {bookingsCount[1] + bookingsCount[6] + bookingsCount[8] || 0}
                </h6>
              </div>
              }
              <div
                className="row g-7 gx-5"
                style={{ justifyContent: "space-between" }}
              >
                {listings && listings.length > 0 ? (
                  listings.map((listing, index) => {
                    return (
                      <div
                        className="col-12 col-md-12 col-lg-12 px-6 py-4 mr-5 "
                        style={{
                          background: "#f5f5f5",
                          borderRadius: "10px",
                          border: listing?.is_referral ? "2px solid #2ecc71" : "1px solid #a3a3a3"
                        }}
                        key={index}
                      >
                      {listing?.is_referral &&  <span className="badge badge-success fs-6 float-end">Marketing</span>}
                        <div
                          className="card card-custom gutter-10"
                          style={{ background: "transparent", clear: "both" }}
                        >
                          <div className="row g-4">
                            <div className="col-12 col-md-4 col-lg-4">
                              <div>
                                <h6 style={{ color: "#a3a3a3" }}>LISTING NAME</h6>
                                <h5 style={{ color: "#4a4a4a" }}>
                                  <Link
                                    to={(haveAccess(currentUser, 'listing_list')) && `/listings/${listing.listing_id}`}
                                    style={{
                                      color: "#2ecc71",
                                      fontWeight: "600",
                                      cursor: (haveAccess(currentUser, 'listing_list')) ? "pointer" : "default"
                                    }}>  {listing.listing_name}
                                  </Link>
                                </h5>
                              </div>
                              <div>
                                <h6 style={{ color: "#a3a3a3" }}>START DATE</h6>
                                <h5 style={{ color: "#4a4a4a" }}>
                                  {/* {moment(listing.start_date).format(
                                "MMMM Do, YYYY dddd"
                              )} */}
                                  {convertUTCTimeToListingTime(listing.start_date, listing.timezone_id)}
                                </h5>
                              </div>
                              <div>
                                <h6 style={{ color: "#a3a3a3" }}>CREATED AT</h6>
                                <h5 style={{ color: "#4a4a4a" }}>
                                  {/* {moment(listing.created_at).format(
                                "MMMM Do, YYYY dddd"
                              )} */}
                                  {convertUTCTimeToListingTime(listing.created_at, listing.timezone_id)}
                                </h5>
                              </div>
                              {expandMore.status && expandMore.id === listing?.booking_id && <>
                                <div>
                                  <h6 style={{ color: "#a3a3a3" }}>PAYMENT STATUS</h6>
                                  <h5
                                    style={{
                                      color:
                                        listing.status !== 7
                                          ? "#ffa459"
                                          : "#ed2626"
                                    }}
                                  >
                                    {listing.status !== 7 && listing.status !== 0
                                      ? "ACCEPTED"
                                      : listing.status == 7 ? "PAYMENT FAILED" : "PENDING PAYMENT"}
                                  </h5>
                                </div>
                                <div>
                                  <h6 style={{ color: "#a3a3a3" }}>DURATION</h6>
                                  <h5 style={{ color: "#4a4a4a" }}>
                                    {getDuration(listing, listing.timezone_id).amount +
                                      " " +
                                      getDuration(listing, listing.timezone_id).type}
                                  </h5>
                                </div>
                                <div>
                                  <h6 style={{ color: "#a3a3a3" }}>SPACES</h6>
                                  <h5 style={{ color: "#4a4a4a" }}>
                                    {bookingDetails?.seats || "-"}
                                  </h5>
                                </div>
                                <div>
                                  <h6 style={{ color: "#a3a3a3" }}>TPC</h6>
                                  <h5
                                    className="text-primary "
                                    style={{ fontWeight: 900 }}
                                  >
                                    {currentUser?.can_view_finance ? bookingDetails?.tpc_share || "-" : <i className="fa fa-lock"></i>}
                                  </h5>
                                </div>

                                {(listing.booking_type == 4) && (
                                  <div>
                                    <h6 style={{ color: "#a3a3a3" }}>UPCOMING CHARGE</h6>
                                    <h5
                                      style={{
                                        color: "#2ecc71",
                                        textTransform: "uppercase"
                                      }}
                                    >
                                      <>
                                        {currentUser?.can_view_finance ? bookingDetails?.upcoming ? `$${(bookingDetails?.upcoming?.amount_due / 100)?.toFixed(2)}` : "-" : <i className="fa fa-lock"></i>}
                                      </>
                                    </h5>
                                  </div>
                                )}
                              </>
                              }
                            </div>
                            <div className="col-12 col-md-4 col-lg-4">
                              <div>
                                <h6 style={{ color: "#a3a3a3" }}>
                                  RESERVATION NUMBER
                                </h6>
                                <h5
                                  style={{
                                    color: "#4a4a4a",
                                    textTransform: "uppercase"
                                  }}
                                >
                                  {listing?.reservation_code || "-"}
                                </h5>
                              </div>
                              <div>
                                <h6 style={{ color: "#a3a3a3" }}>CLOCK IN</h6>
                                <h5 style={{ color: "#4a4a4a" }}>
                                  {moment(listing?.clock_in, "hh:mm").format("LT")}
                                </h5>
                              </div>
                              <div>
                                <h6 style={{ color: "#a3a3a3" }}>FREQUENCY</h6>
                                <h5 style={{ color: "#4a4a4a" }}>
                                  {duration(listing?.booking_type)}
                                </h5>
                              </div>
                              {expandMore.status && expandMore.id === listing?.booking_id &&
                                <>
                                  <div>
                                    <h6 style={{ color: "#a3a3a3" }}>PROPERTY MEMBER</h6>
                                    <h5
                                      style={{ cursor: (haveAccess(currentUser, 'user_details')) ? "pointer" : "default" }}
                                      className="text-primary">
                                      <span onClick={() => {
                                        (haveAccess(currentUser, 'user_details')) &&
                                          openProfile(listing?.booking_id, "owner-profile");
                                      }}>{bookingDetails?.owner_name}{" "}
                                      </span> {" "}
                                      {currentUser.role !== 6 && <span className="text-danger" onClick={() => {
                                        (haveAccess(currentUser, 'user_details')) && openProfile(listing?.booking_id, "owner-stripe");
                                      }}>(Stripe Account)</span>
                                      }
                                    </h5>
                                  </div>
                                  <div>
                                    <h6 style={{ color: "#a3a3a3" }}>BOOKING ID</h6>
                                    <h5 style={{ color: "#4a4a4a" }}>
                                      {listing.booking_id}
                                    </h5>
                                  </div>
                                  {currentUser.role !== 6 && <div>
                                    <h6
                                      style={{ color: "#a3a3a3" }}>{listing.booking_type == 2 ? "SUB TOTAL" : "PRICE"}</h6>
                                    <h5
                                      style={{
                                        color: "#2ecc71",
                                        textTransform: "uppercase"
                                      }}
                                    >
                                      {currentUser?.can_view_finance ? bookingDetails?.bills[0]?.total ? `$${(bookingDetails?.bills[0]?.total / 100).toFixed(2)}` : '-' : <i className="fas fa-lock"></i>}
                                    </h5>
                                  </div>
                                  }
                                  {currentUser.role !== 6 && (
                                    <div>
                                      <h6 style={{ color: "#a3a3a3" }}>DISCOUNT</h6>
                                      <h5
                                        style={{
                                          color: "#2ecc71",
                                          textTransform: "uppercase"
                                        }}
                                      >
                                        {currentUser?.can_view_finance ? bookingDetails?.bills[0]?.promo_details?.discounted_amount
                                          ? `$${(bookingDetails?.bills[0]?.promo_details?.discounted_amount / 100)?.toFixed(2)}`
                                          : bookingDetails?.bills[0]?.discount_amount ? `$${(bookingDetails?.bills[0]?.discount_amount / 100)?.toFixed(2)}` : '-'
                                          : <i className="fas fa-lock"></i>
                                        }
                                        {/*{listing.booking_type == 2 && (*/}
                                        {/*  <>*/}
                                        {/*    {listing?.promo_discount ? `$${(listing?.promo_discount/100)?.toFixed(2)}` : '$0.00'}*/}
                                        {/*  </>*/}
                                        {/*)}*/}
                                        {/*{listing.booking_type == 4 && (*/}
                                        {/*  <>*/}
                                        {/*    {listing?.discounted_total < listing?.total*/}
                                        {/*      ? `$${((listing?.total - listing?.discounted_total) / 100)?.toFixed(2)}`*/}
                                        {/*      : '$0.00'*/}
                                        {/*    }*/}
                                        {/*  </>*/}
                                        {/*)}*/}
                                      </h5>
                                    </div>
                                  )}
                                  {(listing.booking_type == 4 && currentUser.role !== 6) && (
                                    <div>
                                      <h6 style={{ color: "#a3a3a3" }}>UPCOMING CHARGE DISCOUNT</h6>
                                      <h5
                                        style={{
                                          color: "#2ecc71",
                                          textTransform: "uppercase"
                                        }}
                                      >
                                        <>
                                          {currentUser?.can_view_finance ? bookingDetails?.upcoming ? bookingDetails?.upcoming?.discount ? `$${(bookingDetails?.upcoming?.discount / 100)?.toFixed(2)}` : "-" : "-" : <i className="fa fa-lock"></i>}
                                        </>
                                      </h5>
                                    </div>
                                  )}
                                </>
                              }
                            </div>
                            <div className="col-12 col-md-4 col-lg-4">
                              <div>
                                <h6 style={{ color: "#a3a3a3" }}>TRUCKER MEMBER</h6>
                                <h5
                                  style={{ cursor: (haveAccess(currentUser, 'user_details')) ? "pointer" : "default" }}
                                  className="text-primary">
                                  <span onClick={() => {
                                    (haveAccess(currentUser, 'user_details')) &&
                                      openProfile(listing?.booking_id, "customer-profile");
                                  }}>{listing?.customer_name}{" "}
                                    {(haveAccess(currentUser, 'user_details')) && listing?.customer_last_name}</span> {" "}
                                  {currentUser.role !== 6 && <span className="text-danger" onClick={() => {
                                    (haveAccess(currentUser, 'user_details')) && openProfile(listing?.booking_id, "customer-stripe");
                                  }}>(Stripe Account)</span>
                                  }
                                </h5>
                              </div>
                              <div>
                                <h6 style={{ color: "#a3a3a3" }}>END DATE</h6>
                                <h5 style={{ color: "#4a4a4a" }}>
                                  {/* {moment(listing.end_date).format(
                                "MMMM Do, YYYY dddd"
                              ) || "-"} */}
                                  {convertUTCTimeToListingTime(listing.end_date, listing.timezone_id, true)}
                                </h5>
                              </div>
                              <div>
                                <h6 style={{ color: "#a3a3a3" }}>BOOKING STATUS</h6>
                                <h5
                                  style={{
                                    color:
                                      listing.status == 4 || listing.status == 5 || listing.status == 7 || listing.status == 8 || listing.status == 11 || listing.status == 12
                                        ? "#ed2626" :
                                        listing.status == 6 ? "#2ecc71"
                                          : "#ffa459"
                                  }}
                                >
                                  {" "}
                                  {getBookingStatusText(listing)}
                                </h5>
                              </div>
                              {expandMore.status && expandMore.id === listing?.booking_id &&
                                <>
                                  <div>
                                    <h6 style={{ color: "#a3a3a3" }}>MEMBER NUMBER</h6>
                                    <h5 style={{ color: "#4a4a4a" }}>
                                      {bookingDetails?.customer_public_id || "-"}
                                    </h5>
                                  </div>
                                  {currentUser.role !== 6 && <div>
                                    <h6 style={{ color: "#a3a3a3" }}>PLATFORM FEES</h6>
                                    <h5 style={{ color: bookingDetails?.is_referral ? "#2ecc71" : "#4a4a4a" }}>
                                      {currentUser?.can_view_finance ? bookingDetails?.fees ? `${bookingDetails?.fees}%` : "-" : <i className="fa fa-lock"></i>} {currentUser?.can_view_finance && bookingDetails?.is_referral && <i class="fas fa-sack-dollar" style={{ color: '#2ecc71' }}></i>}
                                    </h5>
                                  </div>
                                  }
                                  {currentUser.role !== 6 && <div>
                                    <h6 style={{ color: "#a3a3a3" }}>PAYMENT ID</h6>
                                    <h5 className="text-danger" style={{
                                      color: "#2ecc71",
                                      cursor: currentUser?.can_view_finance ? !listing?.reservation_code?.includes("ARR") && bookingDetails?.bills[0]?.payment_id && bookingDetails?.bills[0]?.payment_id?.indexOf("ch_free") !== 0 ? "pointer" : 
                                      bookingDetails?.arrear_charge && bookingDetails?.arrear_charge?.indexOf("ch_free") !== 0 ? "pointer" : "default" : "default"
                                    }} onClick={() => {
                                        if(currentUser?.can_view_finance){
                                          if(!listing?.reservation_code?.includes("ARR") && bookingDetails?.bills[0]?.payment_id && bookingDetails?.bills[0]?.payment_id?.indexOf("ch_free") !== 0){
                                            if(isProd){
                                              window.open(
                                                `https://dashboard.stripe.com/payments/${bookingDetails?.bills[0]?.payment_id}`
                                              );
                                            }else{
                                              window.open(
                                                `https://dashboard.stripe.com/test/payments/${bookingDetails?.bills[0]?.payment_id}`
                                              );
                                            }
                                          }else if (bookingDetails?.arrear_charge && bookingDetails?.arrear_charge?.indexOf("ch_free") !== 0){
                                            if(isProd){
                                              window.open(
                                                `https://dashboard.stripe.com/payments/${bookingDetails?.arrear_charge}`
                                              );
                                            }else{
                                              window.open(
                                                `https://dashboard.stripe.com/test/payments/${bookingDetails?.arrear_charge}`
                                              );
                                            }
                                          }
                                        }
                                    }}>
                                      {currentUser?.can_view_finance ? !listing?.reservation_code?.includes("ARR") ? bookingDetails?.bills[0]?.payment_id ? bookingDetails?.bills[0]?.payment_id 
                                        : "-"
                                        : bookingDetails?.arrear_charge ? bookingDetails?.arrear_charge :
                                          "-"
                                        : <i className="fa fa-lock"></i>
                                      }{" "} <br />
                                      {currentUser?.can_view_finance && bookingDetails?.bills[0]?.payment_id &&
                                        <span className="text-dark">(Charge) <br /></span>}
                                    </h5>
                                    {listing?.booking_type == 4 &&
                                      <h5 className="text-danger" style={{ color: "#2ecc71", cursor: currentUser?.can_view_finance && bookingDetails?.subscription_id ? "pointer" : "default" }}
                                        onClick={() => {
                                          if (currentUser?.can_view_finance && bookingDetails?.subscription_id) {
                                            if (isProd) {
                                              window.open(
                                                `https://dashboard.stripe.com/subscriptions/${bookingDetails?.subscription_id}`,
                                                "_blank"
                                              );
                                            } else {
                                              window.open(
                                                `https://dashboard.stripe.com/test/subscriptions/${bookingDetails?.subscription_id}`,
                                                "_blank"
                                              );
                                            }
                                          }
                                        }}>
                                        {currentUser?.can_view_finance ? bookingDetails?.subscription_id
                                          ? bookingDetails?.subscription_id
                                          : "-"
                                          : <i className="fa fa-lock"></i>
                                        }{" "}<br />
                                        {currentUser?.can_view_finance && bookingDetails?.subscription_id && <span className="text-dark">(Subscription)</span>}
                                      </h5>
                                    }
                                  </div>
                                  }
                                  {currentUser.role == 6 && <div>
                                    <h6 style={{ color: "#a3a3a3" }}>TPC</h6>
                                    <h5
                                      className="text-primary "
                                      style={{ fontWeight: 900 }}
                                    >
                                      {currentUser?.can_view_finance ? bookingDetails?.tpc || "-" : <i className="fas fa-lock" />}
                                    </h5>
                                  </div>
                                  }

                                  {currentUser.role !== 6 && (
                                    <div>
                                      <h6 style={{ color: "#a3a3a3", cursor: 'pointer' }} onClick={() => {
                                        if (showPriceBreakup.status && showPriceBreakup.id === bookingDetails?.booking_id) {
                                          setShowPriceBreakup({
                                            id: null,
                                            status: false
                                          })
                                        } else {
                                          setShowPriceBreakup({
                                            id: bookingDetails?.booking_id,
                                            status: true
                                          })
                                        }
                                      }}
                                      >TOTAL PAID {currentUser?.can_view_finance && `(Click to See Price Breakup)`}</h6>
                                      {currentUser?.can_view_finance && showPriceBreakup.status && showPriceBreakup.id === bookingDetails?.booking_id ? (
                                        <div className="d-flex flex-column gap-1">
                                          <div className="d-flex gap-2 fw-bold fs-5">
                                            <span>Sub Total:</span>
                                            <span style={{ color: "#2ecc71" }}>
                                              {bookingDetails?.bills[0]?.total ? `$${(bookingDetails?.bills[0]?.total / 100).toFixed(2)}` : '-'}
                                            </span>
                                          </div>
                                          {(bookingDetails?.bills[0]?.tax && bookingDetails?.bills[0]?.tax > 0) ? <div className="d-flex gap-2 fw-bold fs-5">
                                            <span>State Sales Tax:</span>
                                            <span style={{ color: "#2ecc71" }}>
                                              {bookingDetails?.bills[0]?.tax ? `+$${(bookingDetails?.bills[0]?.tax / 100).toFixed(2)}` : '$0.00'}
                                            </span>
                                          </div>
                                          : null}
                                          {(bookingDetails?.bills[0]?.promo_details && bookingDetails?.bills[0]?.promo_details?.discounted_amount > 0) ? (
                                              <div className="d-flex gap-2 fw-bold fs-5">
                                                <span>Promo Discount:</span>
                                                <span style={{ color: "#ed2626" }}>
                                                  {`-$${((bookingDetails?.bills[0]?.promo_details?.discounted_amount - (bookingDetails?.bills[0]?.points_value ? bookingDetails?.bills[0]?.points_value : 0)) / 100).toFixed(2)}`}
                                                </span>
                                              </div>
                                          ) : null}
                                          {(walletsEnabled && bookingDetails?.bills[0]?.redeemed && bookingDetails?.bills[0]?.redeemed > 0) ? (
                                            <div className="d-flex gap-2 fw-bold fs-5">
                                              <span>Paid via Wallet:</span>
                                              <span style={{ color: "#ed2626" }}>
                                                {bookingDetails?.bills[0]?.redeemed ? `-$${(bookingDetails?.bills[0]?.redeemed / 100).toFixed(2)}` : '$0.00'}
                                              </span>
                                            </div>
                                          ) : null}
                                          {(bookingDetails?.bills[0]?.paid && bookingDetails?.bills[0]?.paid > 0) ? (
                                              <div className="d-flex gap-2 fw-bold fs-5">
                                                <span>Paid via {paymentMode[bookingDetails?.bills[0]?.payment_mode]}:</span>
                                                <span style={{ color: "#ed2626" }}>
                                                {bookingDetails?.bills[0]?.paid ? `-$${(bookingDetails?.bills[0]?.paid / 100).toFixed(2)}` : '$0.00'}
                                              </span>
                                              </div>
                                          ) : null}
                                        </div>
                                      ) : (
                                          <h5
                                            style={{
                                              color: "#2ecc71",
                                              textTransform: "uppercase"
                                            }}
                                          >
                                            {currentUser?.can_view_finance ? `$${(((bookingDetails?.bills[0]?.paid || 0) + (bookingDetails?.bills[0]?.redeemed || 0)) / 100).toFixed(2)}` : <i className="fas fa-lock" />}
                                          </h5>
                                      )}
                                    </div>
                                  )}

                                </>
                              }
                            </div>
                          </div>
                          {(haveAccess(currentUser, 'booking_details')) && <button
                            type="button"
                            className="btn btn-primary mt-2"
                            onClick={() => {
                              if (expandMore.status && expandMore.id === listing?.booking_id) {
                                setExpandMore({
                                  status: false,
                                  id: ""
                                });
                                setCardDetails(null)
                              } else {
                                setExpandMore({
                                  status: true,
                                  id: listing?.booking_id
                                });
                                setCardDetails(null)
                                bookingById(listing?.booking_id);
                              }
                            }
                            }
                            style={{
                              // background: "#ed2626",
                              color: "#fff",
                              height: "46px",
                              fontWeight: "700",
                              width: "110px"
                            }}
                          >
                            {expandMore.status && expandMore.id === listing?.booking_id ? "See Less" : "See More"}
                          </button>
                          }
                          {expandMore.status && expandMore.id === listing.booking_id && <>
                            {bookingDetails?.vehicles && bookingDetails?.vehicles.length > 0 && (
                              <h3 className="my-3" style={{ color: "#ffa459" }}>
                                ASSIGNED VEHICLES
                              </h3>
                            )}

                            {bookingDetails?.vehicles?.length > 0 && bookingDetails?.vehicles.map((item) => (
                              <div
                                className="vehicle-card"
                                style={{
                                  borderRadius: "10px",
                                  border: "1px solid #b2b2b2",
                                  padding: "10px",
                                  background: "#f5f5f5",
                                  marginBottom: "10px",
                                  display: "flex",
                                  gap: "10px",
                                  flexWrap: "wrap",
                                  position: "relative",
                                }}
                              >
                                <div className="vehicle-price">
                                  Price: {formatPrice(bookingDetails?.prices?.find((x) => x?.vehicle_type === item?.vehicle_type ? x?.vehicle_type === item?.vehicle_type : x.vehicle_type == 0)?.price * getDuration(bookingDetails, listing.timezone_id).amount)}
                                </div>
                                <div className="vehicle-label">
                                  <h6 style={{ color: "#a3a3a3" }}>
                                    VEHICLE ID
                                  </h6>
                                  <h5 style={{ color: "#4a4a4a" }}>
                                    {item?.vehicle_id || "-"}
                                  </h5>
                                </div>
                                <div className="vehicle-label">
                                  <h6 style={{ color: "#a3a3a3" }}>
                                    VEHICLE TYPE
                                  </h6>
                                  <h5 style={{ color: "#4a4a4a" }}>
                                    {+item?.vehicle_type === 1
                                      ? "TRUCK"
                                      : +item?.vehicle_type === 2
                                        ? "TRAILER"
                                        : "TRUCK + TRAILER"
                                    }
                                  </h5>
                                </div>
                                {+item.vehicle_type !== 2 && (
                                  <div className="vehicle-label">
                                    <h6 style={{ color: "#a3a3a3" }}>
                                      COMPANY NAME
                                    </h6>
                                    <h5 style={{ color: "#4a4a4a" }}>
                                      {item?.name || "-"}
                                    </h5>
                                  </div>
                                )}
                                {+item.vehicle_type !== 2 && (
                                  <div className="vehicle-label">
                                    <h6 style={{ color: "#a3a3a3" }}>
                                      TRUCK #
                                    </h6>
                                    <h5 style={{ color: "#4a4a4a" }}>
                                      {item?.truck_number || "-"}
                                    </h5>
                                  </div>
                                )}
                                {+item.vehicle_type !== 2 && (
                                  <div className="vehicle-label">
                                    <h6 style={{ color: "#a3a3a3" }}>
                                      TRUCK MAKE
                                    </h6>
                                    <h5 style={{ color: "#4a4a4a" }}>
                                      {item?.make || "-"}
                                    </h5>
                                  </div>
                                )}
                                {+item.vehicle_type !== 2 && (
                                  <div className="vehicle-label">
                                    <h6
                                      style={{
                                        color: "#a3a3a3",
                                        textTransform: "uppercase"
                                      }}
                                    >
                                      TRUCK PLATE #
                                    </h6>
                                    <h5 style={{ color: "#4a4a4a" }}>
                                      {item?.plate_number || "-"}
                                    </h5>
                                  </div>
                                )}
                                {+item.vehicle_type !== 1 && (
                                  <div className="vehicle-label">
                                    <h6 style={{ color: "#a3a3a3" }}>
                                      TRAILER TYPE
                                    </h6>
                                    <h5 style={{ color: "#4a4a4a" }}>
                                      {item.vehicle_type !== 1
                                        ? item?.model || "-"
                                        : "Not Available"
                                      }
                                    </h5>
                                  </div>
                                )}
                                {+item.vehicle_type !== 1 && (
                                  <div className="vehicle-label">
                                    <h6 style={{ color: "#a3a3a3" }}>
                                      TRAILER #
                                    </h6>
                                    <h5
                                      style={{
                                        color: "#4a4a4a",
                                      }}
                                    >
                                      {item?.trailer_number || "-"}
                                    </h5>
                                  </div>
                                )}
                                {+item.vehicle_type == 2 && (
                                  <div className="vehicle-label">
                                    <h6 style={{ color: "#a3a3a3" }}>
                                      COMPANY NAME
                                    </h6>
                                    <h5
                                      style={{
                                        color: "#4a4a4a",
                                      }}
                                    >
                                      {item?.name || "-"}
                                    </h5>
                                  </div>
                                )}
                                {+item.vehicle_type !== 1 && (
                                  <div className="vehicle-label">
                                    <h6 style={{ color: "#a3a3a3" }}>
                                      TRAILER PLATE #
                                    </h6>
                                    <h5
                                      style={{
                                        color: "#4a4a4a",
                                      }}
                                    >
                                      {item?.trailer_plate || "-"}
                                    </h5>
                                  </div>
                                )}
                              </div>
                            ))}
                            <div className="booking-edit-status-wrapper">
                              <h2 className={`mt-4 mb-0 ${(listing.status == 7 || listing?.status == 12 || listing?.status == 11) ? "pointer" : "default"}`}
                              onClick={() => {
                                if(listing.status == 7 || listing?.status == 12 || listing?.status == 11) {
                                  checkCardDetails(listing)
                                }
                              }}
                              >
                                {BookingStatus(listing, checkEdit)} {(listing.status == 7 || listing?.status == 12 || listing?.status == 11) && <span className="fs-5" style={{ color: "#ed2626" }}>(See Why)</span>}
                              </h2>
                              <div className="booking-edit-btn-wrapper">
                                {(listing.status !== 7 || !listing?.status !== 5) && <div
                                  className="share-wrapper"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "auto",
                                    gap: "10px"
                                  }}
                                >
                                  {shareActive.status &&
                                    shareActive.id === listing.booking_id && (
                                      <div style={{ position: "relative" }}>
                                        <input
                                          placeholder="Enter Email Address"
                                          type="email"
                                          name="email"
                                          className={clsx(
                                            "form-control form-control-solid mb-lg-0 share-email"
                                          )}
                                          style={{
                                            border: "1px solid #ffa459",
                                            width: "250px"
                                          }}
                                          required
                                          defaultValue={email}
                                          autoComplete="off"
                                          onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <button
                                          className="remove-share"
                                          style={{
                                            color: "#fff",
                                            background: "transparent",
                                            border: "none"
                                          }}
                                          onClick={() => {
                                            setShareActive({
                                              status: false,
                                              id: null
                                            });
                                            setEmail("");
                                          }}
                                        >
                                          <KTIcon iconName="cross" />
                                        </button>
                                      </div>
                                    )}

                                  {(listing.booking_type == 4 && !bookingDetails?.upcoming?.discount && bookingDetails?.status == 1
                                    && bookingDetails?.status != 4) && (haveAccess(currentUser, "booking_discount")) && (
                                      <button
                                        type="button"
                                        className="btn"
                                        onClick={() => {
                                          setBookingToDiscount(listing.booking_id);
                                          setOpenDiscountModal(true);
                                        }}
                                        style={{
                                          background: "#ffa459",
                                          color: "#fff",
                                          height: "46px",
                                          fontWeight: "700"
                                        }}
                                      >
                                        Discount next charge
                                      </button>
                                    )}

                                  {(haveAccess(currentUser, "booking_invoice")) && <button
                                    type="button"
                                    className="btn bg-info share-btn"
                                    style={{
                                      color: "#fff",
                                      height: "46px",
                                      fontWeight: "700"
                                    }}
                                    disabled={shareActive.status && shareActive.id === listing.booking_id && !email ? true : false}
                                    onClick={() => {
                                      if (
                                        shareActive.status &&
                                        shareActive.id === listing.booking_id
                                      ) {
                                        share(listing.booking_id);
                                      } else {
                                        setShareActive({
                                          status: true,
                                          id: listing.booking_id
                                        });
                                        setEmail();
                                      }
                                    }}
                                  >
                                    {shareActive.id !== listing.booking_id &&
                                      "Share To"}
                                    {shareActive.status &&
                                      shareActive.id === listing.booking_id && (
                                        <KTIcon iconName="send" />
                                      )}
                                  </button>
                                  }
                                </div>
                                }
                                {(((listing.status == 1 || listing.status == 2) &&
                                  listing?.booking_type !== 4 &&
                                  checkEdit(
                                    listing.start_date,
                                    listing.clock_in,
                                    listing.end_date,
                                    listing?.timezone_id
                                  ) === "Upcoming") || listing.status == 0) &&
                                  (haveAccess(currentUser, "booking_edit")) &&
                                  (
                                    <button
                                      type="button"
                                      className="btn "
                                      onClick={() => {
                                        setSelectedBookingId(bookingDetails);
                                        setOpenEditBookingModal(true);
                                      }}
                                      style={{
                                        background: "#ffa459",
                                        color: "#fff",
                                        height: "46px",
                                        fontWeight: "700"
                                      }}
                                    // disabled={
                                    //   checkEdit(
                                    //     moment(listing.start_date).tz(listing?.timezone_id).format(
                                    //       "DD-MM-YYYY"
                                    //     ),
                                    //     listing.clock_in,
                                    //     listing.end_date,
                                    //     listing?.timezone_id
                                    //   ) === "Upcoming"
                                    //     ? false
                                    //     : true
                                    // }
                                    >
                                      {/* {checkEdit(
                                  moment(listing.start_date).tz(listing?.timezone_id).format(
                                    "DD-MM-YYYY"
                                  ),
                                  listing.clock_in,
                                  listing.end_date,
                                  listing?.timezone_id
                                ) === "Upcoming" && "Edit Booking"} */}
                                      Edit Booking
                                    </button>
                                  )}
                                {(listing.status == 1 || listing.status == 2 || listing.status == 8) &&
                                  (checkEdit(
                                    listing.start_date,
                                    listing.clock_in,
                                    listing.end_date,
                                    listing?.timezone_id
                                  ) === "Upcoming"
                                    ||
                                    checkEdit(
                                      listing.start_date,
                                      listing.clock_in,
                                      listing.end_date,
                                      listing?.timezone_id
                                    ) === "In Progress") &&
                                  (haveAccess(currentUser, "vehicle_edit")) &&
                                  (haveAccess(currentUser, "vehicle_list"))
                                  &&
                                  (
                                    <button
                                      type="button"
                                      className="btn  bt-success"
                                      onClick={() =>
                                        openEditUserVehicleModal(listing.booking_id, bookingDetails)
                                      }
                                      style={{
                                        background: "#2ce771",
                                        color: "#fff",
                                        height: "46px",
                                        fontWeight: "900"
                                      }}
                                    >
                                      Update Vehicle
                                    </button>
                                  )}
                                {((+listing.status === 0 || +listing.status === 1 || +listing.status === 2) && (+listing.booking_type === 1 || +listing.booking_type === 2 || +listing.booking_type === 3)) &&
                                  checkEdit(
                                    listing.start_date,
                                    listing.clock_in,
                                    listing.end_date,
                                    listing?.timezone_id
                                  ) === "In Progress" &&
                                  (haveAccess(currentUser, "booking_extend"))
                                  && (
                                    <button
                                      type="button"
                                      className="btn  bt-success"
                                      onClick={() => {
                                        setSelectedBookingId(bookingDetails);
                                        setOpenExtendModal(true);
                                        setVehicles(bookingDetails?.vehicles);
                                      }
                                      }
                                      style={{
                                        background: "#ffa459",
                                        color: "#fff",
                                        height: "46px",
                                        fontWeight: "900"
                                      }}
                                    >
                                      Extend Booking
                                    </button>
                                  )}
                                {listing.status == 11 &&
                                  (
                                    <button
                                      type="button"
                                      className="btn  bt-success"
                                      onClick={() =>
                                        retryPayment(listing)
                                      }
                                      style={{
                                        background: "#2ce771",
                                        color: "#fff",
                                        height: "46px",
                                        fontWeight: "900"
                                      }}
                                    >
                                      Retry Payment
                                    </button>
                                  )
                                }
                                {((listing.status == 0 || listing.status == 1 || listing.status == 2 || listing.status == 6) && !listing?.reservation_code?.includes("ARR") &&
                                  (checkEdit(
                                    listing.start_date,
                                    listing.clock_in,
                                    listing.end_date,
                                    listing?.timezone_id
                                  ) === "Upcoming" ||
                                    checkEdit(
                                      listing.start_date,
                                      listing.clock_in,
                                      listing.end_date,
                                      listing?.timezone_id
                                    ) === "In Progress")  ||
                                    listing.status == 11
                                  ) &&
                                  (haveAccess(currentUser, "booking_cancel")) &&
                                  (
                                    <button
                                      type="button"
                                      className="btn "
                                      onClick={() =>
                                        setShowDeleteModal({
                                          status: true,
                                          id: listing.booking_id,
                                          reservation_number: listing.reservation_code,
                                          type: listing.booking_type,
                                          refund: false
                                        })
                                      }
                                      style={{
                                        background: "#ed2626",
                                        color: "#fff",
                                        height: "46px",
                                        fontWeight: "700"
                                      }}
                                    >
                                      Cancel Booking
                                    </button>
                                  )}
                                {(listing.status == 8 && haveAccess(currentUser, "booking_cancel")) &&
                                  (
                                    <button
                                      type="button"
                                      className="btn "
                                      onClick={() =>
                                        setShowDeleteModal({
                                          status: true,
                                          id: listing.booking_id,
                                          reservation_number: listing.reservation_code,
                                          type: listing.booking_type,
                                          refund: true
                                        })
                                      }
                                      style={{
                                        background: "#ffa459",
                                        color: "#fff",
                                        height: "46px",
                                        fontWeight: "700"
                                      }}
                                    >
                                      Refund Booking
                                    </button>
                                  )}
                              </div>

                            </div>
                            {cardDetails && <div className="d-flex flex-column gap-1">
                              <div className="d-flex gap-2 fw-bold fs-5"><span>Card Last4:</span> <span style={{ color: "#2ecc71" }}>{cardDetails.card_last4 ? cardDetails.card_last4 : '-'}</span></div>
                                <div className="d-flex gap-2 fw-bold fs-5"><span>Failure Message:</span> <span style={{ color: "#ed2626" }}>{cardDetails.failure_message ? cardDetails.failure_message : '-'}</span></div>
                                <div className="d-flex gap-2 fw-bold fs-5"><span>Failure Code:</span> <span style={{ color: "#ed2626" }}>{cardDetails.failure_code ? cardDetails?.failure_code : '-'}</span></div>
                                <div className="d-flex gap-2 fw-bold fs-5"><span>Address Check:</span> <span style={{ color: cardDetails?.card_checks?.address_line1_check === 'pass' ? "#2ecc71" : "#ed2626" }}>{cardDetails?.card_checks?.address_line1_check ? cardDetails?.card_checks?.address_line1_check === 'pass' ? 'Pass' : 'Fail' : '-'}</span></div>
                                <div className="d-flex gap-2 fw-bold fs-5"><span>Postal Code Check:</span> <span style={{ color: cardDetails?.card_checks?.address_postal_code_check === 'pass' ? "#2ecc71" : "#ed2626" }}>{cardDetails?.card_checks?.address_postal_code_check ? cardDetails?.card_checks?.address_postal_code_check === 'pass' ? 'Pass' : 'Fail' : '-'}</span></div>
                                <div className="d-flex gap-2 fw-bold fs-5"><span>CVC Check:</span><span style={{ color: cardDetails?.card_checks?.cvc_check === 'pass' ? "#2ecc71" : "#ed2626" }}>{cardDetails?.card_checks?.cvc_check ? cardDetails?.card_checks?.cvc_check === 'pass' ? 'Pass' : 'Fail' : '-'}</span></div>
                              </div>
                              }
                          </>
                          }
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="d-flex text-center w-100 align-content-center justify-content-center">
                    No matching records found
                  </div>
                )}
              </div>
              {/* pagination container */}
              <div className="row mt-10">
                <div
                  className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                  <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                    <select
                      className="form-select form-select-solid fw-bolder"
                      data-kt-select2="true"
                      data-placeholder="Select Status"
                      data-allow-clear="true"
                      data-kt-user-table-filter="two-step"
                      data-hide-search="true"
                      onChange={(e) => setPageSize(e.target.value)}
                      value={pageSize}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
                    </select>
                  </div>
                </div>
                <div
                  className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                  <div id="kt_table_users_paginate">
                    <ul className="pagination">
                      {bookingPage > 1 && (
                        <li className={clsx("page-item", "previous")}>
                          <a
                            className={clsx("page-link", "next", "page-text")}
                            onClick={() => updatePage("dec")}
                            style={{ cursor: "pointer" }}
                            disabled={bookingPage === 1}
                          >
                            Previous Page
                          </a>
                        </li>
                      )}
                      <div style={{ display: "flex", gap: "5px", fontWeight: 700, fontSize: "14px" }}>
                        <span style={{ color: "#ffa459" }}>{bookingPage}</span>/ {Math.ceil((total || 1) / pageSize)}
                      </div>
                      <li className={clsx("page-item", "previous", bookingPage === Math.ceil((total || 1) / pageSize) && "disabled")}>
                        <a
                          className={clsx("page-link")}
                          onClick={() => updatePage("inc")}
                          style={{
                            cursor: bookingPage !== Math.ceil((total || 1) / pageSize) ? "pointer" : "default",
                            background: bookingPage !== Math.ceil((total || 1) / pageSize) ? "transparent" : "#e6e6e6",
                            color: bookingPage !== Math.ceil((total || 1) / pageSize) ? "#5e6278" : "#b2b2b2",
                            marginLeft: bookingPage === Math.ceil((total || 1) / pageSize) && "10px"
                          }}
                        >
                          Next Page
                        </a>
                      </li>
                      {Math.ceil(total / pageSize) > 5 && <div style={{ display: "flex", gap: "10px", alignItems: "center", marginLeft: '10px' }}>
                        <h6 style={{ marginBottom: '0' }}>Go To Page: </h6>
                        <select
                          style={{ width: "max-content" }}
                          className="form-select form-select-solid fw-bolder"
                          data-kt-select2="true"
                          data-placeholder="Select Status"
                          data-allow-clear="true"
                          data-kt-user-table-filter="two-step"
                          data-hide-search="true"
                          onChange={(e) => updatePage(+e.target.value)}
                          value={bookingPage}
                        >
                          {
                            [...Array(Math.ceil(total / pageSize))].map((_, i) => {
                              return (
                                <option value={i + 1}>{i + 1}</option>
                              )
                            }
                            )}
                        </select>
                      </div>
                      }
                    </ul>
                  </div>
                </div>
              </div>

              {showDeleteModal.status && !showDeleteModal?.refund ? (
                <div className="modal_wrapper_booking">
                  <div
                    className="modal_container"
                    style={{ width: showDeleteModal.type == 4 ? "700px" : "", maxHeight: "90vh", overflowY: "auto" }}
                  >
                    {step === 1 && (
                    <div>
                      <h3 className="modalListingName">Are you sure you want to
                        <span style={{ color: "#ed2626" }}> Cancel</span> this Booking
                        ID <span>#{showDeleteModal.id}</span> with Reservation
                        Number <span>{showDeleteModal.reservation_number}</span> ?</h3>
                      {showDeleteModal.type == 4 && bookingDetails.status !== 11 && (
                        <div className="fv-row mb-6 mt-4">
                          <label className="required fw-bold fs-6 mb-2">Cancellation type</label>
                          <select
                            className="form-select form-select-sm form-select-solid"
                            data-hide-search="true"
                            value={cancellationType}
                            onChange={(e) => {
                              setCancellationType(e.target.value);
                              if (e.target.value === "current") {
                                setGiveRefund(true);
                              }
                              if (e.target.value === "upcoming") {
                                setGiveRefund(false);
                              }
                            }}
                            style={{ fontSize: "14.3px" }}
                          >
                            <option value="" disabled selected>Select Cancellation Type</option>
                            <option value="upcoming">Upcoming Cycle</option>
                            <option value="current">Current Cycle</option>
                          </select>
                        </div>
                      )}

                      {(showDeleteModal.type == 1 || showDeleteModal.type == 2 || showDeleteModal.type == 3 || (showDeleteModal.type == 4 && cancellationType === "current") && bookingDetails.booking_id) && (
                        <>
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                              <div style={{ display: "flex", flexDirection: "column", fontWeight: 600 }}>
                                Starting time:
                                <span style={{ fontWeight: 400 }}>
                                  {convertUTCTimeToListingTime(bookingDetails.start_date, bookingDetails.timezone_id, 0)}
                                </span>
                              </div>
                              <div style={{ display: "flex", flexDirection: "column", fontWeight: 600 }}>
                                Current time:
                                <span style={{ fontWeight: 400 }}>
                                  {convertUTCTimeToListingTime(new Date(), bookingDetails.timezone_id)}
                                </span>
                              </div>
                              <div style={{ display: "flex", flexDirection: "column", fontWeight: 600 }} className="mb-5">
                                End time:
                                <span style={{ fontWeight: 400 }}>
                                  {convertUTCTimeToListingTime(bookingDetails.end_date, bookingDetails.timezone_id, 1)}
                                </span>
                              </div>
                            </div>
                            <div style={{ textAlign: "right" }}>
                              <div style={{ display: "flex", flexDirection: "column", fontWeight: 600 }}>
                                Money Paid by via Card/ACH
                                <span style={{ fontWeight: 400 }}>
                                  ${bookingDetails?.bills[0]?.paid ? (bookingDetails?.bills[0]?.paid / 100).toFixed(2) : '0.00'}
                                </span>
                              </div>
                              <div style={{ display: "flex", flexDirection: "column", fontWeight: 600 }}>
                                Money Paid by via Wallet
                                <span style={{ fontWeight: 400 }}>
                                  ${bookingDetails?.bills[0]?.redeemed ? (bookingDetails?.bills[0]?.redeemed / 100).toFixed(2) : '0.00'}
                                </span>
                              </div>
                              <div style={{ display: "flex", flexDirection: "column", fontWeight: 600 }}>
                                Money Transferred to PM
                                <span style={{ fontWeight: 400 }}>
                                ${(bookingDetails?.bills[0]?.payout_amount ? (bookingDetails?.bills[0]?.payout_amount / 100).toFixed(2) : '0.00')}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="fv-row mb-5 mt-5">
                          <label className="required fw-bold fs-6 mb-2">Category</label>
                          <select
                            className="form-select form-select-sm form-select-solid"
                            data-hide-search="true"
                            value={refundReasonType}
                            onChange={(e) => {
                              setRefundReasonType(e.target.value);
                            }}
                            style={{ fontSize: "14.3px" }}
                          >
                            <option value="" disabled selected>Select Category</option>
                            <option value="TPC Error">TPC Error</option>
                            <option value="TPC Admin">TPC Admin</option>
                            <option value="PM Error">PM Error</option>
                            <option value="Driver Error">Driver Error</option>
                            <option value="Cancel Subscription Only (No Refund)">Cancel Subscription Only (No Refund)</option>
                          </select>
                        </div>
                          <div className="fv-row mb-5">
                            <label className="required fw-bold fs-6 mb-2">Reason</label>
                            <input
                              placeholder="Enter Reason"
                              className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                              name="reason"
                              value={reason}
                              onChange={(e) => setReason(e.target.value)}
                            />
                          </div>
                          {!(showDeleteModal.type == 4 && cancellationType === "current") && (
                            <div className="form-check form-check-custom form-check-solid mb-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value="1"
                                id="flexCheckChecked"
                                checked={giveRefund}
                                onChange={(e) => setGiveRefund(prev => !prev)}
                              />
                              <label
                                className="form-check-label fs-5 fw-bold text-danger"
                                htmlFor="flexCheckChecked"
                              >
                                Request Refund
                              </label>
                            </div>
                          )}
                          {giveRefund && (
                            <>
                              <div className="fv-row mb-5">
                                <label className="required fw-bold fs-6 mb-2">Total Refund Amount (max ${(bookingDetails?.bills[0]?.paid + bookingDetails?.bills[0]?.redeemed - bookingDetails?.bills[0]?.tax) / 100})</label>
                                <div className='mb-3'><i><u>If Tax has been paid then it will be calculated and refund accordingly</u></i></div>
                                <input
                                  placeholder="Enter Refund Amount"
                                  className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                                  name="refundAmount"
                                  value={refundAmount}
                                  onChange={(e) => {
                                    const re = /^[1-9]\d*(\.)?(\d{1,2})?$/
                                    if ((!re.test(e.target.value) && e.target.value !== '') || (+e.target.value > (bookingDetails?.bills[0]?.paid + bookingDetails?.bills[0]?.redeemed - bookingDetails?.bills[0]?.tax) / 100)) {
                                      return;
                                    } else {
                                      setRefundAmount(e.target.value);
                                    }
                                  }}
                                />
                              </div>
                              <div className="fv-row mb-5">
                                <label className="required fw-bold fs-6 mb-2">Refund to Card/ACH {refundAmount !== '' ? `(max $${Math.min(+refundAmount, bookingDetails?.bills[0]?.paid / 100)})` : ''}</label>
                                <input
                                    placeholder="Enter Refund to Card/ACH Amount"
                                    className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                                    name="cardRefundAmount"
                                    value={cardRefundAmount}
                                    disabled={!refundAmount}
                                    onChange={(e) => {
                                      const re = /^[1-9]\d*(\.)?(\d{1,2})?$/
                                      if ((!re.test(e.target.value) && e.target.value !== '') || (+e.target.value > Math.min(+refundAmount, bookingDetails?.bills[0]?.paid / 100))) {
                                        return;
                                      } else {
                                        setCardRefundAmount(e.target.value);
                                        setWalletRefundAmount((refundAmount - e.target.value).toFixed(2))
                                      }
                                    }}
                                />
                              </div>
                              <div className="fv-row mb-5">
                                <label className="fw-bold fs-6 mb-2">Refund to Wallet</label>
                                <input
                                    placeholder="Enter Refund to Wallet Amount"
                                    className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                                    name="walletRefundAmount"
                                    disabled
                                    value={walletRefundAmount}
                                    onChange={(e) => {
                                      const re = /^[0-9.]*$/;
                                      if (!re.test(e.target.value)) {
                                        return;
                                      } else {
                                        setWalletRefundAmount(e.target.value);
                                      }
                                    }}
                                    style={{ backgroundColor: '#eee', cursor: 'not-allowed' }}
                                />
                              </div>
                              <div className="form-check form-check-custom form-check-solid fv-row mb-5 align-items-center">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="reverseCharge"
                                  data-kt-check={isReversal}
                                  data-kt-check-target="#kt_table_users .form-check-input"
                                  checked={isReversal}
                                  onChange={(e) => setReversal(e.target.checked)}
                                  style={{ marginRight: "12px" }}
                                />
                                <label className="fw-bold fs-5 text-danger" htmlFor="reverseCharge">Reverse Charge PM?</label>
                              </div>
                            </>
                          )}
                          <div className="fv-row mb-5">
                            <label className="fw-bold fs-6 mb-2">Notes</label>
                            <input
                              placeholder="Enter Notes (goes on the receipt)"
                              className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                              name="notes"
                              value={notes}
                              onChange={(e) => setNotes(e.target.value)}
                            />
                          </div>
                        </>
                      )}
                      {showDeleteModal.type == 4 && (bookingDetails?.status !== 11 ? cancellationType === "upcoming" : bookingDetails?.status === 11) &&
                        <div className="fv-row mb-5 mt-5">
                          <label className="required fw-bold fs-6 mb-2">Category</label>
                          <select
                            className="form-select form-select-sm form-select-solid"
                            data-hide-search="true"
                            value={refundReasonType}
                            onChange={(e) => {
                              setRefundReasonType(e.target.value);
                            }}
                            style={{ fontSize: "14.3px" }}
                          >
                            <option value="" disabled selected>Select Category</option>
                            <option value="TPC Error">TPC Error</option>
                            <option value="TPC Admin">TPC Admin</option>
                            <option value="PM Error">PM Error</option>
                            <option value="Driver Error">Driver Error</option>
                            <option value="Cancel Subscription Only (No Refund)">Cancel Subscription Only (No Refund)</option>
                          </select>
                        </div>
                      }
                      {bookingDetails?.status === 11 && showDeleteModal.type == 4 && <div className="fv-row mb-5">
                          <label className="required fw-bold fs-6 mb-2">Reason</label>
                          <input
                            placeholder="Enter Reason"
                            className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                            name="reason"
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                          />
                        </div>
                      }
                      {showDeleteModal.type == 4 &&
                        <span style={{ color: "#ed2626" }}>*Please note, cancel this reservation will cancel the upcoming reservation cycle and will never be charged.</span>}
                      {/* {showDeleteModal.type == 4 &&
                        <span style={{ color: "#ed2626" }}>*Please note, cancel & refund this reservation will cancel the reservation for current and upcoming cycle and also refund will be provided.</span>} */}
                      <div className="modal_btn_container">
                        <button
                          className="btn"
                          onClick={() => {
                            setShowDeleteModal({
                              status: false,
                              id: null,
                              reservation_number: null,
                              type: null
                            });
                            setGiveRefund(false);
                            setReason("");
                            setNotes("");
                            setReversal(false);
                            setRefundAmount('');
                            setCancellationType("");
                            setRefundReasonType("");
                          }
                          }
                          style={{ background: "#fff", color: "#ffa459" }}
                        >
                          Discard
                        </button>
                        {showDeleteModal.type !== 4 &&
                          <button className="btn" style={{ background: "#ffa459", color: "#fff" }}
                            onClick={() => {
                              // cancelBooking(showDeleteModal.id, showDeleteModal.type)
                              if (giveRefund) {
                                axios.post(`${process.env.REACT_APP_API_V2_URL}/booking/cancellation/preview?booking_id=${bookingDetails?.booking_id}&refund_amount=${refundAmount * 100}&stripe_amount=${cardRefundAmount * 100}`)
                                  .then(res => {
                                    setRefundData(res.data)
                                    setStep(2)
                                  })
                              } else {
                                requestCancellation();
                              }
                            }}
                            disabled={(giveRefund && (isNaN(+refundAmount) || +refundAmount <= 0 || +refundAmount > bookingDetails.discount_amount / 100 || cardRefundAmount === '')) || !reason || !refundReasonType || loading}
                          >
                            Confirm
                          </button>
                        }

                        {(showDeleteModal.type == 4 && (bookingDetails?.status !== 11 ? cancellationType === "upcoming" : bookingDetails?.status === 11)) && (
                          <button className="btn" style={{ background: "#ed2626", color: "#fff" }}
                          disabled={bookingDetails?.status !== 11 ? (!refundReasonType || loading) : (!refundReasonType || !reason || loading)}
                            onClick={() => {
                              // cancelBooking(showDeleteModal.id, showDeleteModal.type)
                              requestCancellation();
                            }}
                          >
                            Cancel <br />
                            {bookingDetails?.status !== 11 && `(Upcoming Cycle)`}
                          </button>
                        )}

                        {(showDeleteModal.type == 4 && cancellationType === "current") && (
                          <button className="btn" style={{ background: "#2ecc71", color: "#fff" }}
                            onClick={() => {
                              if (cancellationType === 'current') {
                                axios.post(`${process.env.REACT_APP_API_V2_URL}/booking/cancellation/preview?booking_id=${bookingDetails?.booking_id}&refund_amount=${refundAmount * 100}&stripe_amount=${cardRefundAmount * 100}`)
                                    .then(res => {
                                      setRefundData(res.data)
                                      setStep(2)
                                    })
                              } else {
                                requestCancellation();
                              }
                            }}
                            disabled={(giveRefund && (isNaN(+refundAmount) || +refundAmount <= 0 || +refundAmount > bookingDetails.discount_amount / 100)) || !reason || !refundReasonType || loading}
                          >
                            Confirm
                          </button>
                        )}

                      </div>
                    </div>
                    )}
                    {step === 2 && (
                      <div>
                        <div>
                          <h3 className="modalListingName">You are going to refund <span>${refundData.total_refund / 100}</span> for the Booking
                            ID <span>#{showDeleteModal.id}</span> with Reservation
                            Number <span>{showDeleteModal.reservation_number}</span>
                          </h3>
                          <div style={{ fontSize: 16, marginTop: 14 }}>Amount refund to the payment source: <span style={{ fontWeight: 600, color: 'red' }}>${refundData.breakdown.base_refund_stripe / 100}</span></div>
                          <div style={{ fontSize: 16 }}>Tax refund to the payment source: <span style={{ fontWeight: 600, color: 'red' }}>${refundData.breakdown.tax_refund_stripe / 100}</span></div>
                          <div style={{ fontSize: 16 }}>Amount refund to the wallet: <span style={{ fontWeight: 600, color: 'red' }}>${refundData.breakdown.base_refund_cash / 100}</span></div>
                          <div style={{ fontSize: 16 }}>Tax refund to the wallet: <span style={{ fontWeight: 600, color: 'red' }}>${refundData.breakdown.tax_refund_cash / 100}</span></div>
                        </div>
                        <div className="modal_btn_container">
                          <button
                            className="btn"
                            onClick={() => {
                              setStep(1)
                              setRefundData(null)
                            }}
                            style={{ background: "#fff", color: "#ffa459" }}
                          >
                            Discard
                          </button>
                          <button
                            className="btn"
                            style={{ background: "#ffa459", color: "#fff" }}
                            onClick={() => {
                              requestCancellation();
                            }}
                          >
                            Confirm
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )
                : showDeleteModal.status && showDeleteModal?.refund && (
                  <div className="modal_wrapper_booking">
                    <div className="modal_container" style={{ maxHeight: "90vh", overflowY: "auto" }}>
                      <h3 className="modalListingName">Are you sure you want to give <span
                        style={{ color: "#2ecc71" }}>Refund</span> to
                        this Booking ID <span>#{showDeleteModal.id}</span> with Reservation
                        Number <span>{showDeleteModal.reservation_number}</span> ?</h3>
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                          <div style={{ display: "flex", flexDirection: "column", fontWeight: 600 }}>
                            Starting time:
                            <span style={{ fontWeight: 400 }}>
                              {convertUTCTimeToListingTime(bookingDetails.start_date, bookingDetails.timezone_id, 0)}
                            </span>
                          </div>
                          <div style={{ display: "flex", flexDirection: "column", fontWeight: 600 }}>
                            Current time:
                            <span style={{ fontWeight: 400 }}>
                              {convertUTCTimeToListingTime(new Date(), bookingDetails.timezone_id)}
                            </span>
                          </div>
                          <div style={{ display: "flex", flexDirection: "column", fontWeight: 600 }} className="mb-5">
                            End time:
                            <span style={{ fontWeight: 400 }}>
                              {convertUTCTimeToListingTime(bookingDetails.end_date, bookingDetails.timezone_id, 1)}
                            </span>
                          </div>
                        </div>
                        <div style={{ textAlign: "right" }}>
                          <div style={{ display: "flex", flexDirection: "column", fontWeight: 600 }}>
                            Money Paid by TM
                            <span style={{ fontWeight: 400 }}>
                              ${bookingDetails?.bills[0]?.paid ? (bookingDetails?.bills[0]?.paid / 100).toFixed(2) : '0.00'}
                            </span>
                          </div>
                          <div style={{ display: "flex", flexDirection: "column", fontWeight: 600 }}>
                            Money Transferred to PM
                            <span style={{ fontWeight: 400 }}>
                              ${(bookingDetails?.bills[0]?.payout_amount ? (bookingDetails?.bills[0]?.payout_amount / 100).toFixed(2) : '0.00')}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="fv-row mt-5">
                        <label className="required fw-bold fs-6 mb-2">Category</label>
                        <select
                          className="form-select form-select-sm form-select-solid"
                          data-hide-search="true"
                          value={refundReasonType}
                          onChange={(e) => {
                            setRefundReasonType(e.target.value);
                          }}
                          style={{ fontSize: "14.3px" }}
                        >
                          <option value="" disabled selected>Select Category</option>
                          <option value="TPC Error">TPC Error</option>
                          <option value="TPC Admin">TPC Admin</option>
                          <option value="PM Error">PM Error</option>
                          <option value="Driver Error">Driver Error</option>
                          <option value="Cancel Subscription Only (No Refund)">Cancel Subscription Only (No Refund)</option>
                        </select>
                      </div>
                      <div className="fv-row mb-5 mt-5">
                        <label className="required fw-bold fs-6 mb-2">Reason</label>
                        <input
                          placeholder="Enter Reason"
                          className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                          name="reason"
                          value={reason}
                          onChange={(e) => setReason(e.target.value)}
                        />
                      </div>
                      <div className="fv-row mb-5">
                        <label className="required fw-bold fs-6 mb-2">Refund Amount</label>
                        <input
                          placeholder="Enter Refund Amount"
                          className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                          name="refundAmount"
                          value={refundAmount}
                          onChange={(e) => {
                            const re = /^[0-9.]*$/;
                            if (!re.test(e.target.value)) {
                              return;
                            } else {
                              setRefundAmount(e.target.value);
                            }
                          }}
                        />
                      </div>
                      <div className="form-check form-check-custom form-check-solid fv-row mb-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="reverseCharge"
                          data-kt-check={isReversal}
                          data-kt-check-target="#kt_table_users .form-check-input"
                          checked={isReversal}
                          onChange={(e) => setReversal(e.target.checked)}
                          style={{ marginRight: "12px" }}
                        />
                        <label className="fw-bold fs-6 mb-2" htmlFor="reverseCharge">Reverse Charge PM?</label>
                      </div>
                      <div className="fv-row mb-5">
                        <label className="fw-bold fs-6 mb-2">Notes</label>
                        <input
                          placeholder="Enter Notes"
                          className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                          name="notes"
                          value={notes}
                          onChange={(e) => setNotes(e.target.value)}
                        />
                      </div>
                      <div className="modal_btn_container">
                        <button
                          className="btn"
                          onClick={() => {
                            setShowDeleteModal({
                              status: false,
                              id: null,
                              reservation_number: null,
                              type: null,
                              refund: false
                            });
                            setReason("");
                            setNotes("");
                            setReversal(false);
                            setRefundAmount('');
                            setCancellationType("");
                            setRefundReasonType('');
                          }
                          }
                          style={{ background: "#fff", color: "#ffa459" }}
                        >
                          Cancel
                        </button>
                        <button className="btn" style={{ background: "#ffa459", color: "#fff" }}
                          disabled={!reason || !refundAmount || !refundReasonType || loading}
                          onClick={() => {
                            requestCancellation(true)
                          }
                          }
                        >
                          Give Refund
                        </button>
                      </div>
                    </div>
                  </div>
                )
              }
              {showConfirmDeleteModal.status && showConfirmDeleteModal.error_type === "clock_in" ? (
                <div className="modal_wrapper_booking">
                  <div className="modal_container" style={{ maxHeight: "90vh", overflowY: "auto" }}>
                    <h3 className="modalListingName">Are you sure you want to <span
                      style={{ color: "#ed2626" }}> Cancel</span> this
                      Booking ID <span>#{showConfirmDeleteModal.id}</span> with Reservation
                      Number <span>{showConfirmDeleteModal.reservation_number}</span> after Clock In ?</h3>
                    <span style={{ color: "#ed2626" }}>Please note, cancelling this reservation for a future date (that hasn't occurred) will cancel the entire reservation, and will never be charged.
                    </span>
                    <div className="modal_btn_container">
                      <button
                        className="btn"
                        onClick={() => {
                          setShowConfirmDeleteModal({
                            status: false,
                            id: null,
                            reservation_number: null,
                            type: null,
                            error_type: ""
                          });
                          setGiveRefund(false);
                        }
                        }
                        style={{ background: "#fff", color: "#ffa459" }}
                      >
                        Cancel
                      </button>
                      <button className="btn" style={{ background: "#ed2626", color: "#fff" }}
                        onClick={() => {
                          cancelForceBooking(showConfirmDeleteModal.id, showConfirmDeleteModal.type);
                        }
                        }
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              )
                : showConfirmDeleteModal.status && showConfirmDeleteModal.error_type === "" && (
                  <div className="modal_wrapper_booking">
                    <div className="modal_container" style={{ maxHeight: "90vh", overflowY: "auto" }}>
                      <h3 className="modalListingName">Please Confirm Again. You want to
                        <span style={{ color: "#ed2626" }}> Cancel</span> this Booking
                        ID <span>#{showDeleteModal.id}</span> with Reservation
                        Number <span>{showDeleteModal.reservation_number}</span> after Clock In ?</h3>
                      <span style={{ color: "#ed2626" }}>Please note, cancelling this reservation for a future date (that hasn't occurred) will cancel the entire reservation, and will never be charged.
                      </span>
                      <div className="modal_btn_container">
                        <button
                          className="btn"
                          onClick={() => {
                            setShowConfirmDeleteModal({
                              status: false,
                              id: null,
                              reservation_number: null,
                              type: null
                            });
                          }
                          }
                          style={{ background: "#fff", color: "#ffa459" }}
                        >
                          Cancel
                        </button>
                        <button className="btn" style={{ background: "#ed2626", color: "#fff" }}
                          onClick={() => {
                            cancelBookingLocal(showConfirmDeleteModal.id);
                          }
                          }
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  </div>
                )
              }
            </KTCardBody>
            {selectedBookingId && !openEditBookingModal && !openExtendModal && (
              <VehicleEditModal
                vehicles={vehicles}
                selectedBookingId={selectedBookingId}
                setSelectedBookingId={setSelectedBookingId}
                setVehicles={setVehicles}
                fetchBooking={fetchListing}
                fetchBookingId={bookingById}
                bookingDetails={bookingDetails}
              />
            )}
            {(bookingToDiscount && openDiscountModal) && (
              <DiscountModal
                bookingId={bookingToDiscount}
                setBookingToDiscount={setBookingToDiscount}
                setOpenDiscountModal={setOpenDiscountModal}
                bookingDetails={bookingDetails}
                fetchBooking={bookingById}
              />
            )}
            {selectedBookingId && openEditBookingModal && (
              <BookingEditModal
                selectedBookingId={selectedBookingId}
                setSelectedBookingId={setSelectedBookingId}
                fetchBooking={fetchListing}
                fetchBookingId={bookingById}
                setOpenEditBookingModal={setOpenEditBookingModal}
              />
            )}
            {selectedBookingId && openExtendModal && (
              <ExtendModal
                vehicles={vehicles}
                selectedBookingId={selectedBookingId}
                setSelectedBookingId={setSelectedBookingId}
                bookingDetails={bookingDetails}
                setVehicles={setVehicles}
                fetchBooking={fetchListing}
                setOpenExtendModal={setOpenExtendModal}
              />
            )}
          </KTCard>
        </Elements>
      </>
      :
      <KTCard>
        <div className='d-flex text-center w-100 align-content-center justify-content-center' style={{ height: '70vh', alignItems: 'center', fontSize: '42px', color: '#ffa049', background: 'rgba(0,0,0,0.1)' }}>
          You do not have permission to view
        </div>
      </KTCard>
  );
};

export default Index;
