import { PointsModalForm } from "./PointsModalForm"

const PointsModalFormWrapper = ({
  user,
  setOpenPointsModal,
  pointsHistory,
  haveAccess,
  currentUser
}) => {

  if (user) {
    return (
      <PointsModalForm
        user={user}
        setOpenPointsModal={setOpenPointsModal}
        pointsHistory={pointsHistory}
        haveAccess={haveAccess}
        currentUser={currentUser}
      />
    );
  }
  return null;
};

export { PointsModalFormWrapper };
