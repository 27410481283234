import { KTIcon } from "../../_metronic/helpers";
import moment from "moment";
import { useEffect, useState } from "react";
import './style.css'
import clsx from "clsx";

const Reviews = ({ allReviews, isPaginated = false, reviewsTotal, reviewsPage, reviewsPageSize, setReviewsPageSize, setReviewsPage }) => {
  const [selectedReview, setSelectedReview] = useState("guest");
  const [reviews, setReviews] = useState([]);
  const [reviewsCount, setReviewsCount] = useState({
    guest: 0,
    host: 0
  });

  useEffect(() => {
    setReviewsCount({
      guest: allReviews?.filter((review) => +review.review_type === 2).length,
      host: allReviews?.filter((review) => +review.review_type === 1).length
    });
    if (selectedReview === "guest") {
      setReviews(allReviews?.filter((review) => +review.review_type === 2));
    } else if (selectedReview === "host") {
      setReviews(allReviews?.filter((review) => +review.review_type === 1));
    }
  }, [selectedReview, allReviews]);


  const updatePage = (type) => {
    if (type === "dec") {
      setReviewsPage(reviewsPage - 1);
    } else if (type === "inc") {
      if (reviewsPage !== Math.ceil(reviewsTotal / reviewsPageSize)) {
        setReviewsPage(reviewsPage + 1);
      }
    }
  };


  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h2>Reviews {isPaginated && `(${reviewsTotal})`}</h2>
      </div>
      <div
        style={{
          display: "flex",
          gap: "50px",
          marginTop: "5px"
        }}
      >
        <button
          style={{
            border: "none",
            background: "transparent",
            fontSize: "16px",
            borderBottom:
              selectedReview === "guest"
                ? "3px solid #ffa459"
                : "none",
            padding: "5px 2px",
            fontWeight: 600,
            color: "#4a4a4a"
          }}
          onClick={() => {
            setSelectedReview("guest");
          }}
        >
          Reviews from Trucker Member ({reviewsCount.guest})
        </button>
        <button
          style={{
            border: "none",
            background: "transparent",
            fontSize: "16px",

            borderBottom:
              selectedReview === "host"
                ? "3px solid #ffa459"
                : "none",
            padding: "5px 2px",
            fontWeight: 600,
            color: "#4a4a4a"
          }}
          onClick={() => {
            setSelectedReview("host");
          }}
        >
          Reviews from Property Member ({reviewsCount.host})
        </button>
      </div>
      <div className="row mt-10" style={{ maxHeight: '400px', height: 'max-content', overflowY: 'auto' }}>
        {reviews && reviews.length > 0 ? (
          reviews.map((item) => (
            <div className="col-12">
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "center"
                }}
              >
                <div style={{ width: "80%" }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between"
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "rgb(74, 74, 74)",
                          marginBottom: "8px"
                        }}
                      >
                        {item.review_type === 1 ? (
                          <>
                            <span
                              style={{
                                fontWeight: 400,
                                fontSize: 14,
                                margin: "0 4px 0 0"
                              }}
                            >
                              Review for
                            </span>
                            {item.customer_name}
                            <span
                              style={{
                                fontWeight: 400,
                                fontSize: 14,
                                margin: "0 4px"
                              }}
                            >by
                            </span>
                            {item.owner_name}
                          </>
                        ) : (
                          <>
                            {item.customer_name}
                            <span
                              style={{
                                fontWeight: 400,
                                fontSize: 14,
                                margin: "0 4px"
                              }}
                            >
                              for
                            </span>
                            {item.listing_name}
                          </>
                        )}
                      </div>
                      <div style={{ display: "flex", gap: "5px" }}>
                        {[1, 2, 3, 4, 5].map((elem, index) => (
                          <KTIcon
                            key={index}
                            iconName="star"
                            className={
                              elem <= item.ratings
                                ? "active-rating"
                                : "inactive-rating"
                            }
                          />
                        ))}
                      </div>
                    </div>
                    <span
                      style={{
                        color: "#4a4a4a",
                        fontWeight: 600
                      }}
                    >
                      {moment(item.createdAt).tz(item.timezone_id).format("DD MMMM, YYYY, hh:mm a")}
                    </span>
                  </div>
                  <p
                    style={{
                      fontSize: "16px",
                      color: "#4a4a4a",
                      marginTop: "10px"
                    }}
                  >
                    {item.comment}
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <h5> No Reviews Yet</h5>
        )}
      </div>
     {isPaginated && <div className="row mt-5">
        <div
          className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <select
              className="form-select form-select-solid fw-bolder"
              data-kt-select2="true"
              data-placeholder="Select Status"
              data-allow-clear="true"
              data-kt-user-table-filter="two-step"
              data-hide-search="true"
              onChange={(e) => setReviewsPageSize(e.target.value)}
              value={reviewsPageSize}
            >
              <option value="30">30</option>
              <option value="60">60</option>
              <option value="90">90</option>
              <option value="120">120</option>
            </select>
          </div>
        </div>
        <div
          className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-lg-end">
          <div id="kt_table_users_paginate">
            <ul className="pagination">
              {reviewsPage > 1 && (
                <li className={clsx("page-item", "previous")}>
                  <button
                    className={clsx("page-link", "next", "page-text")}
                    onClick={() => updatePage("dec")}
                    style={{ cursor: "pointer" }}
                    disabled={reviewsPage === 1}
                  >
                    Previous Page
                  </button>
                </li>
              )}
              <div style={{ display: "flex", gap: "5px", fontWeight: 700, fontSize: "14px" }}>
                <span style={{ color: "#ffa459" }}>{reviewsPage}</span>/ {Math.ceil(reviewsTotal / reviewsPageSize) || 1}
              </div>
              <li className={clsx("page-item", "previous")}>
                <button
                  className={clsx("page-link")}
                  onClick={() => updatePage("inc")}
                  style={{
                    cursor: reviewsPage !== (Math.ceil(reviewsTotal / reviewsPageSize) || 1) ? "pointer" : "default",
                    background: reviewsPage !== (Math.ceil(reviewsTotal / reviewsPageSize) || 1) ? "transparent" : "#e6e6e6",
                    color: reviewsPage !== (Math.ceil(reviewsTotal / reviewsPageSize) || 1) ? "#5e6278" : "#b2b2b2",
                    marginLeft: reviewsPage === (Math.ceil(reviewsTotal / reviewsPageSize) || 1) && "10px"
                  }}
                >
                  Next Page
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      }
    </div>
  );
};

export default Reviews;
