import { useState } from "react";
import { UsersListLoading } from "../../Reviews/Review/users-list/components/loading/UsersListLoading";
import { KTCardBody } from "../../../../_metronic/helpers";
import { Pagination } from "../../../../components/Pagination";
import clsx from "clsx";
import { DeleteOrgModal } from "../deleteOrgModal/DeleteOrgModal";
import { MembersModal } from "../membersModal/MembersModal";
import { toast } from "react-toastify";
import axios from "axios";

const headers = [
  { title: 'Organization Name', width: '240px' },
  { title: 'Owner Name', width: '180px' },
  { title: 'Members', width: '180px' },
  { title: 'Description', width: '200px' },
  { title: 'Action', width: '120px', className: 'text-center' },
]

export const OrgTable = ({ organizations, isLoading, setLoading, haveAccess, currentUser, fetchOrganizations, page, setPage, pageSize, setPageSize, total }) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [openMembersModal, setOpenMembersModal] = useState(false);
  const [orgDetails, setOrgDetails] = useState(null);


const getOrgDetails = (id) => {
  setLoading(true)
  axios.get(`${process.env.REACT_APP_API_V3_URL}/org/${id}`)
  .then((res) => {
    setOrgDetails(res.data);
    setOpenMembersModal(true);
    setLoading(false)
  }).catch((err) => {
    setLoading(false)
    toast.error(err.response.data.message || 'Something went wrong');
  })
}



  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map(column => (
                <th
                  key={column.title}
                  className={column.className}
                  style={{ minWidth: column.width }}
                >
                  {column.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold'>
            {organizations && organizations.length > 0 && (
              organizations.map(row => (
                <tr key={row.role_id}>
                  <td style={{ minWidth: headers[1].width }}>
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-circle symbol-50px overflow-hidden me-3" style={{width:'50px', height:'50px'}}>
                        {row.org_logo && row.org_logo !== null ? (
                          <div className='w-100 h-100'>
                           <img src={process.env.REACT_APP_IMAGE_URL + '200:200:' + row?.org_logo.slice(1)} alt={row.org_name} className='w-100 h-100' />
                          </div>
                          ) :
                          <div
                            className={clsx(
                              "symbol-label fs-3",
                              `bg-light-primary`,
                              `text-primary`
                            )}
                          >
                            {row?.org_name?.slice(0, 1)}
                          </div>
                      }
                      </div>
                      <div className="d-flex flex-column mx-2">
                        <span className="text-gray-800 mb-1">
                          {row.org_name}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td style={{ minWidth: headers[0].width }}>{row.owner_name}</td>
                  <td style={{ minWidth: headers[2].width}}
                    className="pointer"
                    onClick={() => getOrgDetails(row.org_id)}
                  > 
                  <div className="d-flex flex-column gap-1 text-center" style={{width:'max-content'}}>
                  <span className="badge badge-light-primary fw-bolder fs-5" style={{width:'max-content'}}>{row.members} Members</span>
                  <span>[View All]</span>
                  </div>
                  </td>
                  <td style={{ minWidth: headers[3].width, display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                    {row.description || '-'}
                  </td>
                  <td style={{ minWidth: headers[4].width, textAlign:'center' }}>
                    <button
                      className="btn btn-danger btn-sm"
                      disabled={(haveAccess(currentUser, 'org_delete') ? false : true) || isLoading}
                      style={{
                        backgroundColor: '#ed2626',
                      }}
                      onClick={() => {
                        setOpenDeleteModal(true);
                        setIdToDelete(row.org_id);
                      }}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      {organizations && !organizations?.length && 
        <div className='d-flex text-center text-gray-600 fw-bolder fs-6 w-100 align-content-center justify-content-center mt-10'>
         No matching records found
        </div>
      }
      </div>
      <Pagination
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        total={total}
      />
      {isLoading && <UsersListLoading />}
      {openDeleteModal && idToDelete && (
        <DeleteOrgModal setOpenDeleteModal={setOpenDeleteModal} idToDelete={idToDelete} setIdToDelete={setIdToDelete} fetchOrganizations={fetchOrganizations} />
      )}
      {
        openMembersModal && orgDetails &&
        <MembersModal orgName={orgDetails?.org_name} members={orgDetails?.members} onClose={() => {
          setOpenMembersModal(false);
          setOrgDetails(null);
        }} />
      }
    </KTCardBody>
  );
};
