import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { ToastStyle } from "../../../../../_metronic/helpers/utils";
import { useAuth } from "../../../auth";
import PhotoUploader from "./PhotoUploader";
import "./styles.scss";

const PhotoUploaderWrapper = ({
  listing,
  openPhotoUploaderModal
}) => {
  const { currentUser, haveAccess } = useAuth();
  const [pageSize, setPageSize] = useState(50);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [images, setImages] = useState([])
  const [isLoading, setLoading] = useState(false)

  const DeleteImage = (id) => {
    setLoading(true)
    axios.delete(`${process.env.REACT_APP_API_V3_URL}/listing/images?asset_id=${id}`).then(res => {
      toast("Image Deleted Successfully", ToastStyle);
      getAllImages()
      setLoading(false)
    }).catch(err => {
      if (err && err.response && err.response?.data && err.response?.data?.message) {
        toast.error(err.response.data.message)
        setLoading(false)
      }
    })
  }
  const ApproveImage = (id) => {
    setLoading(true)
    axios.patch(`${process.env.REACT_APP_API_V3_URL}/listing/images/approve?asset_id=${id}`).then(res => {
      toast("Image Approved Successfully", ToastStyle);
      getAllImages()
      setLoading(false)
    }).catch(err => {
      if (err && err.response && err.response?.data && err.response?.data?.message) {
        toast.error(err.response.data.message)
        setLoading(false)
      }
    })
  }

  const getAllImages = () => {
    setLoading(true)
    axios.get(`${process.env.REACT_APP_API_V3_URL}/listing/images?listing_id=${listing.listing_id}&page=${page}&page_size=${pageSize}&status=0,1`)
      .then(res => {
        setImages(res.data.data)
        setTotal(res.data.total)
        setLoading(false)
      }).catch(err => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message)
          setLoading(false)
        }
      })
  }


  useEffect(() => {
    getAllImages()
  }, [])


  return (
    <>
      <div className={`kt_body w-100 ${openPhotoUploaderModal ? 'uploader-wrapper' : 'hide-uploader-wrapper'}`}>
       {(haveAccess(currentUser, 'listing_photo_upload')) && <PhotoUploader getAllImages={getAllImages} listing={listing} />}
        {(haveAccess(currentUser, 'listing_photo_list')) && images && !!images.length && <div>
          <h2 className="my-5">Uploaded Images</h2>
          <div  className="d-flex flex-wrap gap-4 justify-content-start">
            {images &&
              images.map((el) => (
                <div
                  key={el.asset_id}
                  className="rounded-sm position-relative" 
                  style={{ width: '205px', height:'180px'}}
                  >
                {el.status == 0 &&  <>
                 {(haveAccess(currentUser, 'listing_photo_approve')) && <button
                    className="approve-img-btn"
                    disabled={isLoading}
                    onClick={() => ApproveImage(el.asset_id)}
                  >
                    <i
                      className="fas fa-check"
                      style={{ color: "#fff" }}
                    ></i> Approve
                  </button>
                  }
                  {(haveAccess(currentUser, 'listing_photo_delete')) && <button
                    className="remove-img-btn"
                    disabled={isLoading}
                    onClick={() => DeleteImage(el.asset_id)}
                  >
                    <i
                      className="fas fa-trash"
                      style={{ color: "#fff" }}
                    ></i> Delete
                  </button>
                  }
                  </>
                  }
                  <img
                    src={process.env.REACT_APP_IMAGE_URL + '300:200:' + el.latest_file.slice(1)}
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius:'6px',
                      border: el.status == 1 && '5px solid #10B981'
                    }}
                    alt="images"
                  />
                </div>
              ))}
          </div>
        </div>
        }
      </div >
    </>
  );
};

export { PhotoUploaderWrapper};
