import React, { useEffect, useCallback } from "react";
import { useState } from "react";
import { KTCard } from "../../../_metronic/helpers";
import "./style.css";
import axios from "axios";
import { RefundsTable } from "./table/RefundsTable";
import { toast } from "react-toastify";
import { RefundsHeader } from "./header/RefundsHeader";
import { RefundsModal } from "./refundModal/RefundsModal";
import { useAuth } from "../auth";

const debounce = (func, wait) => {
  let timeout;

  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  }
}

const Index = () => {
  const { currentUser, haveAccess } = useAuth();
  const [refunds, setRefunds] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setLoading] = useState(0);
  const [isOpenRefundsModal, setOpenRefundsModal] = useState(false);
  const [trigger, setTrigger] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchType, setSearchType] = useState('');
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setSearchValue('')
  }, [searchType])

  const fetchRefunds = async (searchTypeVal, inputVal, pageVal, pageSizeVal) => {
    setLoading(true)
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_V2_URL}/booking/cancellation?page=${pageVal}&page_size=${pageSizeVal}&${searchTypeVal}=${inputVal}`);
      setRefunds(data.data);
      setTotal(data.total);
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Could not fetch refunds');
    } finally {
      setLoading(false)
    }
  }

  const handleSearch = useCallback(
    debounce((searchTypeVal, searchValue, page, pageSize) => {
      fetchRefunds(searchTypeVal, searchValue, page, pageSize);
    }, 500), []);

  useEffect(() => {
    handleSearch(searchType, searchValue, page, pageSize)
  }, [page, pageSize, searchValue, trigger])

      useEffect(() => {
      if(searchValue){
        setPage(1);
      }
      if(pageSize !== 10){
       setPage(1);
      }
    }, [searchValue, pageSize]);

  return (
    ((haveAccess(currentUser,  'booking_list') && haveAccess(currentUser,  'booking_cancel'))) ?
      <KTCard>
        <RefundsHeader
          searchType={searchType}
          setSearchType={setSearchType}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        <RefundsTable
          refunds={refunds}
          total={total}
          isLoading={isLoading}
          setTrigger={setTrigger}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
        {isOpenRefundsModal && (
          <RefundsModal
            setOpenRefundsModal={setOpenRefundsModal}
          />
        )}
      </KTCard>
      :
      <KTCard>
        <div className='d-flex text-center w-100 align-content-center justify-content-center' style={{ height: '70vh', alignItems: 'center', fontSize: '42px', color: '#ffa049', background: 'rgba(0,0,0,0.1)' }}>
          You do not have permission to view
        </div>
      </KTCard>

  );
};

export default Index;
