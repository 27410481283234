import { ListingModalForm } from "./ListingModalForm"

const ListingModalFormWrapper = ({
  selectedReport,
  setSelectedReport,
  setOpenListingModal
}) => {

  if (selectedReport) {
    return (
      <ListingModalForm
        selectedReport={selectedReport}
        setSelectedReport={setSelectedReport}
        setOpenListingModal={setOpenListingModal}
      />
    );
  }
  return null;
};

export { ListingModalFormWrapper };
