import {useListView} from '../../core/ListViewProvider'
import {UsersListToolbar} from './UserListToolbar'
import {UsersListGrouping} from './UsersListGrouping'
import {UsersListSearchComponent} from './UsersListSearchComponent'
import { UsersListFilterSearch } from './UsersListFilterSearch'
import {initialQueryState} from '../../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
const UsersListHeader = () => {
  const {selected, setItemIdForUpdate, setIsModalType} = useListView()
  const { updateState, state } = useQueryRequest()
  return (
    <div className='card-header border-0 pt-6'>
      <UsersListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        <select
          className="form-select form-select-solid fw-bolder w-200px"
          value={state.sort}
          onChange={(e) => updateState({ sort: e.target.value })}
        >
          <option value="1">
            Latest First
          </option>
          <option value="2" >
            Oldest First
          </option>
          <option value="3" >
            Highest First
          </option>
          <option value="4" >
            Lowest First
          </option>
        </select>
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {UsersListHeader}
