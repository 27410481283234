import {KTIcon} from '../../../../_metronic/helpers'

const WalletFreezeModalHeader = ({setOpenFreezeWalletModal, user}) => {
  return (
    <div className='modal-header'>
      <h2 className='fw-bolder'>{user?.is_wallet_active ? 'Freeze' : 'Unfreeze'} Wallet For {user?.first_name} {user?.last_name}</h2>
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() =>  {
          setOpenFreezeWalletModal(false)
        }}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>

    </div>
  )
}

export {WalletFreezeModalHeader}
