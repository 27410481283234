const ConfirmationModalForm = ({
  isListing,
  setOpenConfirmationModal,
  onClick
}) => {


  return (
    <>
    <div>
      <h4>
        Are you sure you want to{" "}
        <span style={{ color: "#ed2626", fontWeight: 700 }}>
          Reset Stripe
        </span>{" "}
        for this {isListing ? 'listing' : 'user'}?
      </h4>
      <div className="text-center pt-5">
        <button
          type="reset"
          onClick={() => {
            setOpenConfirmationModal(false)
          }}
          className="btn btn-light me-3"
          data-kt-users-modal-action="cancel"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="btn"
          style={{ backgroundColor: "#ed2626", color: "#fff" }}
          data-kt-users-modal-action="submit"
          onClick={onClick}
        >
          <span className="indicator-label">Reset Stripe</span>
        </button>
      </div>
    </div>
  </>
  );
};

export { ConfirmationModalForm };
