import React, { useEffect } from "react";
import { useState } from "react";
import { KTCard, useDebounce } from "../../../_metronic/helpers";
import "./style.css";
import axios from "axios";
import { ListingChangesTable } from "./table/ListingChangesTable";
import { ListingChangesHeader } from "./header/ListingChangesHeader";
import { toast } from "react-toastify";
import { useAuth } from "../auth";
import moment from "moment";
import { removeHtmlTags } from "../../../_metronic/helpers/utils";
import * as XLSX from "xlsx";

const headers = [
  { label: "Listing", key: "Listing" },
  { label: "Date", key: "date" },
  { label: "Change", key: "change" },
  { label: "Old Value", key: "old_value" },
  { label: "New Value", key: "new_value" },
  { label: "Changed By", key: "changed_by" },
  { label: "Reference", key: "reference" },
];

const ListingChanges = () => {
  const { currentUser, haveAccess } = useAuth();
  const [changes, setChanges] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setLoading] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('1');
  const [filter, setFilter] = useState('');



  const debouncedSearchTerm = useDebounce(search, 550);

  useEffect(() => {
    setLoading(true);
    axios.get(`${process.env.REACT_APP_API_URL}/listing/tracker-logs?page=${page}&page_size=${pageSize}&listing_name=${debouncedSearchTerm}&sort=${sort}&field_id=${filter}`)
      .then(res => {
        setChanges(res.data.result);
        setTotal(res.data.total);
        setLoading(false);
      })
      .catch(err => {
        toast.error(err?.response?.data?.message || 'Could not fetch listings changes');
        setLoading(false);
      })
  }, [page, pageSize, debouncedSearchTerm, sort, filter])

  const fetchAllLogs = async () => {
    setLoading(true)
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/listing/tracker-logs?page=${page}&page_size=${pageSize}&sort=${sort}&field_id=${filter}`);
      return data?.result?.map((log) => {
        return {
          listing: log.listing_name ? String(log.listing_name) : 'N/A',
          date: log.log_date ? moment(log.log_date).format('YYYY-MM-DD') : 'N/A',
          change: log.field_name ? String(log.field_name) : 'N/A',
          old_value: log.current_value ? String(removeHtmlTags(log.current_value)) : 'N/A',
          new_value: log.new_value ? String(removeHtmlTags(log.new_value)) : 'N/A',
          changed_by: log.admin_id ? String(log.admin_name) : (log.owner_name ? String(log.owner_name) : 'N/A'),
          reference: log.reference ? String(log.reference) : 'N/A',
        };
      });  
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Could not fetch');
    } finally {
      setLoading(false)
    }
  }

  const handleExport = async () => {
    const allLogs = await fetchAllLogs() || [];
    if (allLogs?.length === 0) {
      toast.error("No data to export");
      return
    }

    const customHeaders = {
      listing: "Listing",
      date: "Date",
      change: "Change",
      old_value: "Old Value",
      new_value: "New Value",
      changed_by: "Changed By",
      reference: "Reference",  
    };

    const customColumnWidths = {
      listing: 40,
      date: 15,
      change: 35,
      old_value: 100,
      new_value: 100,
      changed_by: 20,
      reference: 40,
    };

    const formattedData = allLogs?.map((row) => {
      const newRow = {};
      Object.keys(customHeaders).forEach((key) => {
        if (key in row) {   
            newRow[key] = row[key];
        }
      });
      return newRow;
    });

    const ws = XLSX.utils.json_to_sheet(formattedData, {
      header: Object.keys(customHeaders),
    });

    ws["!cols"] = Object.keys(customHeaders).map((key) => ({
      width: customColumnWidths[key] || 15
    }));

    Object.keys(customHeaders).forEach((key, index) => {
      const headerCell = ws[XLSX.utils.encode_cell({ r: 0, c: index })];
      if (headerCell) {
        headerCell.v = customHeaders[key];
      }
    });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Listing_Changes.xlsx");
  };
  
      useEffect(() => {
      if(search && debouncedSearchTerm){
        setPage(1);
      }
      if(pageSize !== 10){
       setPage(1);
      }
    }, [debouncedSearchTerm, pageSize]);

  return (
    (haveAccess(currentUser,  "listing_tracker_logs_list")) ?
      <KTCard>
        <ListingChangesHeader search={search} setSearch={setSearch} sort={sort} setSort={setSort} filter={filter} setFilter={setFilter} handleExport={handleExport} isLoading={isLoading} />
        <ListingChangesTable
          changes={changes}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          total={total}
          isLoading={isLoading}
        />
      </KTCard>
      :
      <KTCard>
        <div className='d-flex text-center w-100 align-content-center justify-content-center' style={{ height: '70vh', alignItems: 'center', fontSize: '42px', color: '#ffa049', background: 'rgba(0,0,0,0.1)' }}>
          You do not have permission to view
        </div>
      </KTCard>
  );
};

export default ListingChanges;
