import { PointsTable } from "./table/PointsTable";

const PointsModalForm = ({
setOpenPointsModal,
pointsHistory,
currentUser,
haveAccess
}) => {
  

  return (
    <>
        <div className="kt_body">
          <PointsTable points={pointsHistory} haveAccess={haveAccess} currentUser={currentUser} />
            <div className="d-flex gap-2 justify-content-center">
              <button
                type="button"
                className="btn mt-4 w-auto btn-primary"
                onClick={() => setOpenPointsModal(false)}
                style={{
                  color: "#fff",
                  height: "46px",
                  fontWeight: "700",
                }}
              >
               Go Back
              </button>
            </div>
        </div>
    </>
  );
};

export { PointsModalForm };
