import { useEffect, useState } from "react";
import { useDebounce, KTIcon } from "../../../../_metronic/helpers";
import clsx from "clsx";
import axios from "axios";
import { toast } from "react-toastify";
import "./style.css";
import { ToastStyle } from "../../../../_metronic/helpers/utils";

const AddNewReportModalForm = ({
  setOpenNewReportModal,
  getScheduleReports,
  selectedReport,
  setSelectedReport,
  daysOfWeek
}) => {
  const [status, setStatus] = useState('1')
  const [listingIds, setListingIds] = useState([]);
  const [listings, setListings] = useState([]);
  const [adminEmails, setAdminEmails] = useState([])
  const [admins, setAdmins] = useState([])
  const [cycle, setCycle] = useState("");
  const [dayOfWeek, setDayOfWeek] = useState("");
  const [day, setDay] = useState("");
  const [emails, setEmails] = useState(['']);
  const [type, setType] = useState('listing');
  const [searchUserType, setSearchUserType] = useState(1);
  const [searchUser, setSearchUser] = useState('');
  const [search, setSearch] = useState('');
  const [adminUsersSearch, setAdminUsersSearch] = useState([]);
  const [isManualFormVisible, setIsManualFormVisible] = useState(true);
  const [isManualAdminFormVisible, setIsManualAdminFormVisible] = useState(true);
  

  const reset = () => {
    setAdmins([])
    setListings([]);
    setSearch('');
    setSearchUser('');
  }

  const cancel = () => {
    setSelectedReport(null);
    setOpenNewReportModal(false);
    setListingIds([]);
    setAdminEmails([]);
  };

  const validEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const fetchListing = () => {
    debouncedSearchTerm &&
      axios.get(`${process.env.REACT_APP_API_URL}/listing?page=1&page_size=10&name=${debouncedSearchTerm}`)
        .then((res) => {
          setListings(res.data.data);
        })
        .catch((err) => {
          console.error(err);
        });
  };

  const fetchAdmins = () => {
    debouncedAdminSearchTerm &&
      axios.get(`${process.env.REACT_APP_API_URL}/admin?page=1&page_size=10&name=${debouncedAdminSearchTerm}`)
        .then((res) => {
          setAdmins(res.data.admins);
        })
        .catch((err) => {
          console.error(err);
        });
  };

  const debouncedSearchTerm = useDebounce(search.name, 250);
  const debouncedAdminSearchTerm = useDebounce(adminUsersSearch?.name, 250);

  const getUser = () => {
    axios
      .get(
        searchUserType == 1
          ? `${process.env.REACT_APP_API_URL
          }/user/email?email=${encodeURIComponent(searchUser?.trim())}`
          : `${process.env.REACT_APP_API_URL
          }/listing/list?public_id=${searchUser?.trim()}`
      )
      .then((res) => {
        if (res.status === 200) {
          const responseUser = searchUserType == 1 ? res.data : res.data;
            const listing = searchUserType == 1 ? responseUser?.listings?.data?.filter((item) => item.status === 1) : responseUser;
            if (listing && listing.length > 0) {
              const newListings = listing?.filter((item) => !listingIds.some(existing => existing.id === item.listing_id))
                .map((item) => ({
                  id: searchUserType == 1 ? item.listing_id : item.id,
                  name: searchUserType == 1 ? item.title : item.listing_name
                }));
              setListingIds([...listingIds, ...newListings]);
            }
            toast.success("Listing Added Successfully")
            setSearchUser(responseUser.first_name + " " + responseUser.last_name);
            setSearchUser('')
        } else {
          setSearchUser("");
        }
      })
      .catch((err) => {
        setSearchUser("");
        toast(searchUserType == 1 ? "Email Not Found/Incorrect Email" : "Member Number Not Found", ToastStyle);
      });
  };

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && search !== undefined) {
      fetchListing();
    }
    if (debouncedAdminSearchTerm !== undefined && adminUsersSearch !== undefined) {
      fetchAdmins();
    }
  }, [debouncedSearchTerm, debouncedAdminSearchTerm]);

  useEffect(() => {
    if (selectedReport) {
      setStatus(selectedReport?.is_active ? '1' : '2')
      setListingIds(selectedReport.listings);
      setEmails(selectedReport?.emails);
      setAdminEmails(selectedReport?.member_emails);
      setCycle(selectedReport?.cycle);
      setDayOfWeek(selectedReport?.cycle === 1 ? selectedReport?.day : "");
      setDay(selectedReport?.cycle === 2 ? selectedReport?.day : "");
    }
  }, [selectedReport])

  const createScheduler = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_V2_URL}/reports/excel/schedule`,
        {
          listings: listingIds.map((item) => +item.id),
          ...(emails.length && emails.length > 0 && { emails: emails?.join(",") }),
          ...(adminEmails.length && adminEmails.length > 0 && { member_emails: adminEmails.join(",") }),
          cycle: cycle,
          day: cycle === 1 ? dayOfWeek : day,
        }
      )
      .then((res) => {
        setOpenNewReportModal(false);
        getScheduleReports();
        cancel()
        toast("New Scheduler Created Successfully", ToastStyle);
      })
      .catch((err) => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast(err.response.data.message, ToastStyle);
        } else {
          toast("Technical Error", ToastStyle);
        }
      });
  }

  const updateScheduler = () => {
    axios
      .put(
        `${process.env.REACT_APP_API_V2_URL}/reports/excel/${selectedReport?.job_id}`,
        {
          is_active: status == 1 ? true : false,
          listings: listingIds.map((item) => +item.id),
          ...(emails.length && emails.length > 0 && { emails: emails?.join(",") }),
          ...(adminEmails.length && adminEmails.length > 0 && { member_emails: adminEmails.join(",") }),
          cycle: cycle,
          day: cycle === 1 ? dayOfWeek : day,
        }
      )
      .then((res) => {
        setOpenNewReportModal(false);
        getScheduleReports();
        cancel()
        toast("Report Edited Successfully", ToastStyle);
      })
      .catch((err) => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast(err.response.data.message, ToastStyle);
        } else {
          toast("Technical Error", ToastStyle);
        }
      });
  }

  const isDisabled = () => {
    let result = false

    if (!listingIds?.length) {
      result = true
    }
    
    if (emails?.length) {
      const hasInvalidEmail = emails.some((email) => {
        if (!email || email === "") {
          return true
        }
        const re =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !re.test(String(email).toLowerCase());
      });
      if (hasInvalidEmail) {
        result = true
      }
    }else if (!emails?.length) {
      result = true
    }

    if (!cycle) {
      result = true
    }
    if (cycle === 1 && dayOfWeek === "") {
      result = true
    }
    if (cycle === 2 && !day) {
      result = true
    }

    return result
  }

  return (
    <>
      <div
        className="d-flex flex-column scroll-y me-n7 pe-7"
        id="kt_modal_add_user_scroll"
        data-kt-scroll="true"
        data-kt-scroll-activate="{default: false, lg: true}"
        data-kt-scroll-max-height="auto"
        data-kt-scroll-dependencies="#kt_modal_add_user_header"
        data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
        data-kt-scroll-offset="300px"
      >
        {selectedReport && selectedReport?.job_id && (
          <div className="fv-row mb-3">
            <label className="required fw-bold fs-6 mb-2">Set Status</label>
            <select
              className="form-select form-select-solid fw-bolder"
              data-kt-select2="true"
              data-placeholder="Select option"
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            >
              <option value='1'>Set Active</option>
              <option value='2'>Set Inactive</option>
            </select>
          </div>
        )}
        <div className="nav-group nav-group-fluid mb-5">
          <label>
            <input
              type="radio"
              className="btn-check"
              name="type"
              checked={type === "listing"}
              onChange={() => {
                reset();
                setType("listing");
              }}
            />
            <span className="btn btn-sm btn-color-muted btn-active btn-active-primary">
              By Listing Name
            </span>
          </label>
          <label>
            <input
              type="radio"
              className="btn-check"
              name="type"
              checked={type === "user"}
              onChange={() => {
                reset();
                setType("user");
              }}
            />
            <span className="btn btn-sm btn-color-muted btn-active btn-active-primary px-4">
              By Property Member
            </span>
          </label>
        </div>
        <>
          {(listingIds.length > 0) && (
            <div className="fv-row mb-3">
              <label className="fw-bold fs-6 mb-2">Selected Listings {listingIds?.length && <span className="badge badge-yellow">{listingIds?.length}</span>}</label>
              <div style={{ maxHeight: "150px", overflowY: "auto", height: 'auto' }}>
                {listingIds.map((el, i) => (
                  <div
                    key={i}
                    className="selected col-12"
                    style={{ position: "relative", marginBottom: "5px" }}
                  >
                    <input
                      placeholder="Last name"
                      type="text"
                      name="name"
                      className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                      disabled
                      value={el.name}
                      autoComplete="off"
                    />
                    <button
                      className="remove"
                      style={{ right: "10px", top: "12px" }}
                      onClick={() => {
                        if (listingIds.length === 1) {
                          setIsManualFormVisible(true)
                        } else {
                          setIsManualFormVisible(false)
                        }
                        setListingIds((prev) =>
                          prev.filter((elem) => elem.id !== el.id)
                        )
                      }}
                    >
                      <KTIcon iconName="cross" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
          {isManualFormVisible && type === "listing" && (
            <div className="manualForm fv-row mb-3">
              <label className="form-label fw-bolder text-dark fs-6 mb-0">
                Select Listing
              </label>
              <span className="mx-2" style={{ color: search.id ? "#2ecc71" : "#ed2626" }}>
              </span>
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  data-kt-user-table-filter="search"
                  className="form-control form-control-solid w-100 "
                  placeholder="Enter Listing Name"
                  value={search.name}
                  onChange={(e) => setSearch({
                    id: "",
                    name: e.target.value
                  })}
                />
                {search.name && <button
                  className="remove"
                  style={{ right: "10px", top: "12px" }}
                  onClick={() => {
                    setIsManualFormVisible(true);
                    setSearch({
                      id: "",
                      name: ""
                    });
                    setListings([]);
                  }}
                >
                  <KTIcon iconName="cross" />
                </button>
                }
              </div>
              {(listings && listings.length > 0 && !search.id && type === "listing") && (
                <div
                  className="form-control form-control-solid w-100"
                  style={{
                    maxHeight: "150px",
                    overflowY: "auto",
                    minHeight: "auto"
                  }}
                >
                  {listings?.filter((el) => el.status === 1)?.map((item, index) => (
                    <div
                      key={index}
                      style={{ padding: "10px 5px", cursor: "pointer" }}
                      onClick={() => {
                        if (listingIds.find((el) => el.id == item.listing_id)) {
                          toast.error("Listing already added");
                        } else {
                          setListingIds([...listingIds, {
                            id: item.listing_id,
                            name: item.title
                          }]);
                          toast.success("Listing Added Successfully")
                          setListings([]);
                          setSearch({
                            id: "",
                            name: ""
                          });
                          setIsManualFormVisible(false);
                        }
                      }}
                    >
                      {item.title}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          {(!isManualFormVisible && type === "listing") && (
            <button
              onClick={() => setIsManualFormVisible(true)}
              style={{ backgroundColor: "#ffa459", color: "#fff" }}
              className="btn col-12 mb-5"
            >
              Select Another Listing
            </button>
          )}
        </>
        {type === "user" &&
          <>
            <div className="input-search mb-5" style={{ position: "relative" }}>
              <label className="form-label fs-6 fw-bold">
                Select Search Property Member By:
              </label>
              <select
                className="form-select form-select-solid fw-bolder"
                data-kt-select2="true"
                data-placeholder="Select option"
                data-allow-clear="true"
                data-kt-user-table-filter="two-step"
                data-hide-search="true"
                value={searchUserType}
                onChange={(e) => {
                  setSearchUserType(e.target.value);
                }}
              >
                <option value="1">Search By Email</option>
                <option value="2">Search By Member Number</option>
              </select>
            </div>
            <div className="input-search mb-5" style={{ position: "relative" }}>
              <label className="form-label fs-6 fw-bold">
                {searchUserType == 1 ? "Enter Email" : "Enter Member Number"}
              </label>
              <input
                type="text"
                data-kt-user-table-filter="search"
                className="form-control form-control-solid w-100 "
                placeholder={
                  searchUserType == 1 ? "Enter Email" : "Enter Member Number"
                }
                value={searchUser}
                onChange={(e) => setSearchUser(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    getUser();
                  }
                }}
              />
              <span
                className="search-icon"
                style={{
                  position: "absolute",
                  bottom: "11px",
                  right: "10px",
                  cursor: "pointer"
                }}
                onClick={() => getUser()}
              >
                <i className="fas fa-search" style={{ color: "#ffa459" }}></i>
              </span>
            </div>
          </>
        }
        <div className="fv-row mb-3">
          <label className="required fw-bold fs-6 mb-2">Frequency Cycle</label>
          <div className="d-flex gap-2" style={{ position: "relative" }}>
            <button className={`btn bg-light w-100 frequency-btn ${cycle === 1 && "frequency-btn-active"}`}
              onClick={() => setCycle(1)}
            >Weekly</button>
            <button className={`btn bg-light w-100 frequency-btn ${cycle === 2 && "frequency-btn-active"}`}
              onClick={() => setCycle(2)}
            >Monthly</button>
          </div>
        </div>
        {cycle === 1 ?
          <div className="fv-row mb-3">
            <label className="required fw-bold fs-6 mb-2">Day of the Week</label>
            <div className="d-flex gap-2" style={{ position: "relative" }}>
              {
                daysOfWeek.map((el, i) => (
                  <button
                    key={i}
                    className={`btn bg-light w-100 frequency-btn ${dayOfWeek === el.id && "frequency-btn-active"}`}
                    onClick={() => setDayOfWeek(el.id)}
                  >
                    {el.name?.substring(0, 3)}
                  </button>
                ))
              }
            </div>
          </div>
          : cycle === 2 &&
          <div className="fv-row mb-3">
            <label className="required fw-bold fs-6 mb-2">Date of the Month</label>
            <div style={{ position: "relative" }}>
              <input type="number" min="1" max="30" className="form-control form-control-solid" value={day}
                placeholder="1-30"
                onChange={(e) => {
                  const newValue = Math.max(1, Math.min(30, e.target.value));
                  setDay(newValue);
                }}
              />
            </div>
          </div>
        }
        <div className="fv-row mb-3">
          <label className="required fw-bold fs-6 mb-2">Add Notifier Email</label>
          <div className="d-flex gap-2 flex-column" style={{ position: "relative" }}>
            {
              emails.map((el, i) => (
                <div style={{ position: "relative" }}>
                  <input className="form-control form-control-solid" value={emails[i]}
                    placeholder="Enter Notifier Email"
                    onChange={(e) => {
                      const newEmails = [...emails];
                      newEmails[i] = e.target.value;
                      setEmails(newEmails);
                    }}
                    onBlur={(e) => {
                      if (e.target.value && !validEmail(e.target.value)) {
                        toast.error('Invalid notifier email');
                      }
                    }
                    }
                  />
                    {i !== 0  && <button
                    className="remove"
                    style={{ right: "10px", top: "12px" }}
                    onClick={() => {
                      if(emails.length === 1){
                        setEmails(['']);
                      }else{
                        setEmails(emails.filter((_, index) => index !== i));
                      }
                    }}
                  >
                    <KTIcon iconName="cross" />
                  </button>
                  }
                  {selectedReport && validEmail(emails[0]) && <button
                    className="remove"
                    style={{ right: "10px", top: "12px" }}
                    onClick={() => {
                      if(emails.length === 1){
                        setEmails(['']);
                      }else{
                        setEmails(emails.filter((_, index) => index !== i));
                      }
                    }}
                  >
                    <KTIcon iconName="cross" />
                  </button>
                  }
                </div>
              ))
            }
          </div>
          {(emails[emails.length - 1] && validEmail(emails[emails.length - 1])) && <button
            type="reset"
            onClick={() => {
              setEmails([...emails, '']);
            }}
            className="btn btn-dark w-100 mt-3"
            data-kt-users-modal-action="cancel"
          >
            + Add Another Notifier Email
          </button>
          }
        </div>
        <>
          {(adminEmails.length > 0) && (
            <div className="fv-row mb-3">
              <label className="fw-bold fs-6 mb-2">Selected Admins {adminEmails?.length && <span className="badge badge-yellow">{adminEmails?.length}</span>}</label>
              <div style={{ maxHeight: "150px", overflowY: "auto", height: 'auto' }}>
                {adminEmails.map((el, i) => (
                  <div
                    key={i}
                    className="selected col-12"
                    style={{ position: "relative", marginBottom: "5px" }}
                  >
                    <input
                      placeholder="Name"
                      type="text"
                      name="name"
                      className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                      disabled
                      value={el}
                      autoComplete="off"
                    />
                    <button
                      className="remove"
                      style={{ right: "10px" }}
                      onClick={() => {
                        if (adminEmails.length === 1) {
                          setIsManualAdminFormVisible(true)
                        } else {
                          setIsManualAdminFormVisible(false)
                        }
                        setAdminEmails((prev) =>
                          prev.filter((elem) => elem !== el)
                        )
                      }}
                    >
                      <KTIcon iconName="cross" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
          {isManualAdminFormVisible && (
            <div className="manualForm fv-row mb-3">
              <label className="form-label fw-bolder text-dark fs-6 mb-0">
                Select Admin Users
              </label>
              <span className="mx-2" style={{ color: search.id ? "#2ecc71" : "#ed2626" }}>
              </span>
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  data-kt-user-table-filter="search"
                  className="form-control form-control-solid w-100 "
                  placeholder="Enter Admin Name"
                  value={adminUsersSearch.name}
                  onChange={(e) => setAdminUsersSearch({
                    id: "",
                    name: e.target.value,
                    email:""
                  })}
                />
                {adminUsersSearch.name && <button
                  className="remove"
                  style={{ right: "10px", top: "12px" }}
                  onClick={() => {
                    setIsManualAdminFormVisible(true);
                    setAdminUsersSearch({
                      id: "",
                      name: "",
                      email: ""
                    });
                    setAdmins([]);
                  }}
                >
                  <KTIcon iconName="cross" />
                </button>
                }
              </div>
              {(admins && admins.length > 0 && !adminUsersSearch.id) && (
                <div
                  className="form-control form-control-solid w-100"
                  style={{
                    maxHeight: "150px",
                    overflowY: "auto",
                    minHeight: "auto"
                  }}
                >
                  {admins?.filter((i) => !emails.includes(i.email)).map((item, index) => (
                    <div
                      key={index}
                      style={{ padding: "10px 5px", cursor: "pointer" }}
                      onClick={() => {
                        if (adminEmails.find((el) => el.id == item.id)) {
                          toast.error("Admin already added");
                        }
                         else {
                          setAdminEmails([...adminEmails, item.email]);
                          toast.success("Admin Added Successfully")
                          setAdmins([]);
                          setAdminUsersSearch({
                            id: "",
                            name: "",
                            email: ""
                          });
                          setIsManualAdminFormVisible(false);
                        }
                      }}
                    >
                      {item?.first_name + " " + item?.last_name} - {item?.email}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          {(!isManualAdminFormVisible) && (
            <button
              onClick={() => setIsManualAdminFormVisible(true)}
              style={{ backgroundColor: "#ffa459", color: "#fff" }}
              className="btn col-12 mb-5"
            >
              + Add Another Admin
            </button>
          )}
        </>
      </div>
      {/* end::Scroll */}

      {/* begin::Actions */}
      <div className="text-center pt-15">
        <button
          type="reset"
          onClick={() => cancel()}
          className="btn btn-light mx-3"
          data-kt-users-modal-action="cancel"
        >
          Discard
        </button>
        {!selectedReport && !selectedReport?.job_id && (
          <button
            type="submit"
            className="btn"
            data-kt-users-modal-action="submit"
            style={{ background: "#ffa459", color: "#fff" }}
            onClick={() => createScheduler()}
            disabled={isDisabled()}
          >
            <span className="indicator-label">Submit</span>
          </button>
        )}

        {selectedReport && selectedReport?.job_id && (
          <button
            type="submit"
            className="btn"
            data-kt-users-modal-action="submit"
            style={{ background: "#ffa459", color: "#fff" }}
            disabled={isDisabled()}
            onClick={() => updateScheduler()}
          >
            <span className="indicator-label">Save Changes</span>
          </button>
        )}
      </div>
    </>
  );
};

export { AddNewReportModalForm };
