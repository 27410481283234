import { PendingListingModalForm } from "./PendingListingModalForm";

const PendingListingModalFormWrapper = ({
  selectedListingId,
  setSelectedListingId,
  setOpenPendingListingModal,
  listing,
  getListing,
}) => {

  if (selectedListingId) {
    return (
      <PendingListingModalForm
        setSelectedListingId={setSelectedListingId}
        setOpenPendingListingModal={setOpenPendingListingModal}
        selectedListingId={selectedListingId}
        listing={listing}
        getListing={getListing}
      />
    );
  }
  return null;
};

export { PendingListingModalFormWrapper };
