import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { formatPrice } from "../../../../_metronic/helpers/utils";

const WalletModalForm = ({
  openTopUpWalletModal,
  setOpenTopUpWalletModal,
  getUser,
  user,
  pointsHistoryList
}) => {
  const [amount, setAmount] = useState('');
  const [note, setNote] = useState('');

  const cancel = () => {
    setAmount('');
    setOpenTopUpWalletModal({
      status:false,
      type:'add'
    });
  };


  const topUp = () => {
    axios.patch(`${process.env.REACT_APP_API_URL}/user/${user?.uid}/wallet/balance/add`, {
      amount: +amount * 100,
      note: note
  }).then(() => {
          getUser();
          pointsHistoryList()
          cancel();
          toast.success('Cash wallet successfully reloaded')
      })
      .catch((err) => {
        if (err.response?.data?.message) {
          toast.error(err.response.data.message)
        } else {
          toast.error('Could not top up cash wallet balance')
        }
      })
  }

const removeCash = () => {
  axios.patch(`${process.env.REACT_APP_API_URL}/user/${user?.uid}/wallet/balance/deduct`, {
    amount: +amount * 100,
    note: note
  }).then(() => {
    getUser();
    pointsHistoryList()
    cancel();
    toast.success('Cash removed successfully from wallet')
  }).catch((err) => {
    if (err.response?.data?.message) {
      toast.error(err.response.data.message)
    } else {
      toast.error('Could not remove cash from wallet')
    }
  })
}


const getButtonClas = (amount, value, openTopUpWalletModal) => {
  if (amount === value) {
    return openTopUpWalletModal?.type === 'add' ? 'btn-success' : 'btn-danger';
  }
  return openTopUpWalletModal?.type === 'add' ? 'topup' : 'remove-cash';
}



  return (
    <>
        <div
          className="d-flex flex-column scroll-y me-n7 pe-7"
          id="kt_modal_add_user_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_add_user_header"
          data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
          data-kt-scroll-offset="300px"
        >
          <div className="d-flex justify-content-between align-items-start">
          <h3 className="mb-10 align-self-end">Available Funds: <span className={`fw-bolder ${openTopUpWalletModal?.type === 'add' ? 'text-success' : 'text-danger'}`}>{user?.points ? formatPrice(user?.points) : '$0'}</span></h3>
         {user?.points > 0 && <button type="button" className={`btn btn-sm align-self-start ${openTopUpWalletModal?.type === 'add' ? 'btn-danger' : 'btn-success'}`}
          onClick={() => setOpenTopUpWalletModal({
            status:true,
            type: openTopUpWalletModal?.type === 'add' ? 'remove' : 'add'
          })}
          >
            {openTopUpWalletModal?.type === 'add' ? 'Remove Funds' : 'Add Funds'}
            </button>
            }
          </div>
          <div className="d-flex gap-2 topup-container">
          {[100, 200, 500].map(value => (
          <button type="button" className={`btn rounded text-white ${getButtonClas(amount, value, openTopUpWalletModal)}`} onClick={() => setAmount(value)}>{value}</button>
          ))}
           <input
                type="text"
                className="form-control form-control-solid w-100 "
                placeholder="Enter Custom Amount"
                value={amount}
                onChange={(e) => {
                  const newValue = e.target.value
                  const regex = /^[1-9]\d*(\.)?(\d{1,2})?$/
                  const isValidValue = regex.test(newValue)

                  if (isValidValue || e.target.value === '') {
                      setAmount(newValue);
                  }
                }}
              />
          </div>
          <textarea
            className="form-control form-control-solid w-100 mt-4"
            style={{ height: '100px' }}
            placeholder="Add Note..."
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </div>
        <div className="text-center pt-10">
          <button
            type="reset"
            onClick={() => cancel()}
            className="btn btn-light mx-3"
            data-kt-users-modal-action="cancel"
          >
            Discard
          </button>

          <button
            type="submit"
            className={`btn ${openTopUpWalletModal?.type == 'add' ? 'btn-success' : 'btn-danger'}`}
            data-kt-users-modal-action="submit"
            disabled={!amount || !note  || (openTopUpWalletModal?.type === 'remove' && +amount > +user?.points * 0.01)}
            onClick={openTopUpWalletModal?.type == 'add' ? topUp : removeCash}
          >
            <span className="indicator-label">{openTopUpWalletModal?.type === 'add' ? 'Add' : 'Remove'} Cash</span>
          </button>
        </div>
    </>
  );
};

export { WalletModalForm };
