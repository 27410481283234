import { useEffect, useState } from 'react'
import { initialQueryState, KTIcon, useDebounce } from '../../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { generateURL } from "../../../../../../../_metronic/helpers/utils";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../../../../auth";
import "./style.css"

const UsersListSearchComponent = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setReviewPage, reviewPage } = useAuth();
  const { updateState } = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState(undefined)
  const [searchBy, setSearchBy] = useState('1')
  const searchParamType = searchParams.get('search_type')
  const searchParamSearch = searchParams.get('search')
  const [lastLocation, setLastLocation] = useState(null);

  useEffect(() => {
    if(searchParamType || searchParamSearch){
      setSearchBy(searchParamType ? searchParamType : '1')
      setSearchTerm(searchParamSearch ? searchParamSearch : '')
    }else{
      setSearchBy('1')
      setSearchTerm('')
    }
  }, [searchParams])

  const debouncedSearchTerm = useDebounce(searchTerm, 600)

  
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined && searchBy) {
        const newUrl = generateURL('/reviews', {
          search_type: searchBy,
          search: debouncedSearchTerm
        })
        if (lastLocation && lastLocation !== newUrl) {
          navigate(newUrl)
        }
        setLastLocation(newUrl)
        if (searchBy == 1) {
          updateState({ customer_name: debouncedSearchTerm,page:initialQueryState.page, page_size:initialQueryState.page_size, ...initialQueryState })
        } else if (searchBy == 2) {
          updateState({ customer_public_id: debouncedSearchTerm, page:initialQueryState.page, page_size:initialQueryState.page_size, ...initialQueryState })
        }
        else if (searchBy == 3) {
          updateState({ reservation_code: debouncedSearchTerm, page:initialQueryState.page, page_size:initialQueryState.page_size, ...initialQueryState })
        }
        else if (searchBy == 4) {
          updateState({ listing_name: debouncedSearchTerm, page:initialQueryState.page, page_size:initialQueryState.page_size, ...initialQueryState })
        }
      }

      // else if (searchBy === '') {
      //   setSearchTerm('')
      //   updateState({ customer_name: '', customer_public_id: '', reservation_code: '', listing_name: '', ...initialQueryState })
      // }
    },
    [debouncedSearchTerm]
  )


  // useEffect(() => {
  //   setSearchTerm('')
  //   updateState({ customer_name: '', customer_public_id: '', reservation_code: '', listing_name: '', ...initialQueryState })
  // }, [searchBy])


  return (
    <div className='card-title'>
      {/* begin::Search */}
      <div className='mx-5 dropdown-search'>
        <select
          className="form-select form-select-solid fw-bolder"
          data-kt-select2="true"
          data-placeholder="Select option"
          data-allow-clear="true"
          data-kt-user-table-filter="two-step"
          data-hide-search="true"
          value={searchBy}
          onChange={(e) => setSearchBy(e.target.value)}
        >
          <option value="1">
            Name
          </option>
          <option value="2">
            Member Number
          </option>
          <option value="3">
            Reservation Number
          </option>
          <option value="4">
            Listing Name
          </option>
        </select>
      </div>

      <div className='d-flex align-items-center position-relative my-1 search-container'>
        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
        <input
          type={'text'}
          data-kt-user-table-filter='search'
          className='form-control form-control-solid w-300px ps-14 user-search-input'
          placeholder={`Search by ${searchBy == 2 ? 'Member Number' : searchBy == 3 ? 'Reservation Number' : searchBy == 1 ? 'Name' : 'Listing Name'}`}
          value={searchTerm}

          onChange={(e) => {
            setSearchTerm(e.target.value)
            if(reviewPage != 1){
              setReviewPage(1)
            }
          }}
        />
      </div>
    </div>
  )
}

export { UsersListSearchComponent }
