import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { PointsModalHeader } from "./PointsModalHeader";
import { PointsModalFormWrapper } from "./PointsModalFormWrapper";
import { toast } from "react-toastify";
import { WalletModal } from "../WalletModal/WalletModal";
import { useAuth } from "../../auth";
const PointsModal = ({
  user,
  getUser,
  setOpenPointsModal,
  openTopUpWalletModal,
  setOpenTopUpWalletModal
}) => {
const { currentUser, haveAccess } = useAuth();
const [pointsHistory, setPointsHistory] = useState([]);
const pointsHistoryList = () => {
  axios.get(`${process.env.REACT_APP_API_URL}/user/${user?.uid}/wallet/history`).then((res) => {
    let data = res.data.sort((a, b) => moment(b.created_at).utc() - moment(a.created_at).utc())
    .filter(el => el.type === 'DR' ? el.balance_consumed > 0 : el)
  setPointsHistory(data);
  }).catch(err => {
    if (err && err.response && err.response?.data && err.response?.data?.message) {
      toast.error(err.response.data.message);
    } else {
      toast.error("Technical Error, Please Try Again");
    }
  })
}


useEffect(() => {
  if(haveAccess(currentUser, 'wallet_history')) {
    pointsHistoryList()
  } 
  document.body.classList.add("modal-open");
  return () => {
    document.body.classList.remove("modal-open");
  };
}, []);

  return (
    <>
      <div
        className="modal fade show d-block"
        id="kt_modal_add_user"
        role="dialog"
        tabIndex={-1}
        aria-modal="true"
      >
        {/* begin::Modal dialog */}
        <div className="modal-dialog modal-dialog-centered mw-950px">
          {/* begin::Modal content */}
          <div className="modal-content">
            <PointsModalHeader
              user={user}
              setOpenPointsModal={setOpenPointsModal}
              setOpenTopUpWalletModal={setOpenTopUpWalletModal}
              haveAccess={haveAccess}
              currentUser={currentUser}
            />
            {/* begin::Modal body */}
            <div className="modal-body mx-5 mx-xl-5 my-2 p-4">
              <PointsModalFormWrapper
                user={user}
                setOpenPointsModal={setOpenPointsModal}
                pointsHistory={pointsHistory}
                haveAccess={haveAccess}
                currentUser={currentUser}
              />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className="modal-backdrop fade show"></div>
      {/* end::Modal Backdrop */}
      {
        openTopUpWalletModal?.status && (
          <WalletModal
            user={user}
            getUser={getUser}
            pointsHistoryList={pointsHistoryList}
            openTopUpWalletModal={openTopUpWalletModal}
            setOpenTopUpWalletModal={setOpenTopUpWalletModal}
          />
        )
      }
    </>
  );
};

export { PointsModal };
