import { UsersListLoading } from "../../Reviews/Review/users-list/components/loading/UsersListLoading";
import { KTCardBody } from "../../../../_metronic/helpers";
import dayjs from "dayjs";
import { formatPrice } from "../../../../_metronic/helpers/utils";
import { useAuth } from "../../auth";
import axios from "axios";
import { toast } from "react-toastify";
import { Pagination } from "./Pagination";
import { useNavigate } from "react-router-dom";
import {useState} from "react";

const headers = [
  { title: 'Reservation Number' },
  { title: 'Listing Name'},
  { title: 'Trucker Name' },
  { title: 'Created by' },
  { title: 'Created at' },
  { title: 'Booking Amount' },
  { title: 'Refund Category' },
  { title: 'Reason' },
  { title: 'With Refund' },
  { title: 'Refund Amount' },
  { title: 'Revers Charge' },
  { title: 'Request Status' },
  { title: 'Notes' }
]

export const RefundsTable = ({ refunds, total, isLoading, setTrigger, page, setPage, pageSize, setPageSize }) => {
  const {currentUser, haveAccess} = useAuth()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [breakdown, setBreakdown] = useState(null)
  const [breakdownData, setBreakdownData] = useState(null)

  const handleApprove = (id) => {
    setLoading(true)
    axios.post(`${process.env.REACT_APP_API_V2_URL}/booking/cancellation/${id}/approve`)
      .then(res => {
        toast.success('Refund was approved successfully');
        setTrigger(prev => !prev)
      })
      .catch(err => {
        console.log(err);
        toast.error('Could not approve refund')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleDecline = (id) => {
    setLoading(true)
    axios.delete(`${process.env.REACT_APP_API_V2_URL}/booking/cancellation/${id}`)
      .then(res => {
        toast.success('Refund was declined successfully');
        setTrigger(prev => !prev)
      })
      .catch(err => {
        console.log(err);
        toast.error('Could not decline refund')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <KTCardBody className='py-4'>
      <h6 style={{color:'#7e8299', textAlign:'right'}}>Total: {total}</h6>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
          <thead>
          <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
            {headers.map(column => (
              <th
                key={column.title}
                className={column.className}
              >
                {column.title}
              </th>
            ))}
          </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold'>
          {refunds.length > 0 && (
            refunds.map((row, i) => (
              <tr key={row.request_id}>
                <td style={{ minWidth: '180px', cursor: 'pointer', color:'#ffa459' }} onClick={() => navigate(`/bookings?search_type=reservation_code&search=${row.reservation_code}`)}>{row.reservation_code || '-'}</td>
                <td style={{ minWidth: '180px', cursor: 'pointer', color:'#ffa459'  }} onClick={() => navigate(`/listings/${row.listing_id}`)}>{row.listing_name || '-'}</td>
                <td style={{ minWidth: '130px', cursor: 'pointer', color:'#ffa459'  }} onClick={() => navigate(`/users?search_type=1&search=${row.customer_name}`)}>{row.customer_name || '-'}</td>
                <td style={{ minWidth: '130px' }}>{row.creator_name}</td>
                <td style={{ minWidth: '110px' }}>{dayjs(row.created_at).format('YYYY-MM-DD')}</td>
                <td style={{ minWidth: '140px' }}>{formatPrice(row.og_amount) || '-'}</td>
                <td style={{ minWidth: '200px' }}>{row.refund_reason || '-'}</td>
                <td style={{ minWidth: '200px' }}>{row.reason ? row.reason : 'Cancel upcoming cycle'}</td>
                <td style={{ minWidth: '110px' }}>{row.is_refund ? 'Yes' : 'No'}</td>
                <td style={{ minWidth: '130px' }}>
                  {formatPrice(row.refund_amount) || '-'}
                  {!!row.refund_amount && (
                    <button
                      onClick={() => {
                        setBreakdown(row.reservation_code)
                        setBreakdownData(row.refund_breakdown.breakdown)
                      }}
                      style={{
                        fontSize: 11,
                        backgroundColor: '#FFA459',
                        margin: '0 0 0 6px',
                        padding: '2px 4px',
                        borderRadius: 2
                      }}
                    >
                      View
                    </button>
                  )}
                </td>
                <td style={{ minWidth: '130px' }}>{row.reversal_from_parking ? 'Yes' : 'No'}</td>
                <td style={{ minWidth: '220px', display: 'flex', columnGap: '12px' }}>
                  {row.status === 1 ? (
                    (haveAccess(currentUser,  'booking_cancel_list')) ? (
                      <>
                      {(haveAccess(currentUser,  'booking_cancel_approve')) &&  <button
                          className="btn btn-primary"
                          onClick={() => handleApprove(row.request_id)}
                          style={{
                            padding: '0',
                            // background: "#ed2626",
                            color: "#fff",
                            height: "36px",
                            fontWeight: "700",
                            width:'80px'
                          }}
                          disabled={loading}
                        >
                          Approve
                        </button>
                        }
                     {(haveAccess(currentUser,  'booking_cancel_decline')) &&     <button
                          className="btn"
                          onClick={() => handleDecline(row.request_id)}
                          style={{
                            padding: '0',
                            background: "#ed2626",
                            color: "#fff",
                            height: "36px",
                            fontWeight: "700",
                            width:'80px',
                          }}
                          disabled={loading}
                        >
                          Decline
                        </button>
                        }
                      </>
                    ) : (
                      <>Pending approval</>
                    )
                  ) : (
                    <>{row.status === 2 ? 'Approved' : 'Declined'} by {row.approver_name}</>
                  )}
                </td>
                <td style={{ minWidth: '200px' }}>{row.notes || '-'}</td>
              </tr>
            ))
          )}
          </tbody>
        </table>
        {refunds && !refunds.length > 0 && 
        <div className='d-flex text-center text-gray-600 fw-bolder fs-6 w-100 align-content-center justify-content-center mt-10'>
         No matching records found
        </div>
        }
      </div>
      <Pagination
        page={page}
        pageSize={pageSize}
        total={total}
        setPage={setPage}
        setPageSize={setPageSize}
      />
      {(breakdown && breakdownData) && (
        <div className="modal_wrapper_booking">
          <div className="modal_container">
            <h3 className="modalListingName">Refund breakdown for the booking <span>#{breakdown}</span></h3>
            <div style={{ fontSize: 16, marginTop: 14 }}>Amount refund to the payment source: <span style={{ fontWeight: 600, color: 'red' }}>${breakdownData.base_refund_stripe / 100}</span></div>
            <div style={{ fontSize: 16 }}>Tax refund to the payment source: <span style={{ fontWeight: 600, color: 'red' }}>${breakdownData.tax_refund_stripe / 100}</span></div>
            <div style={{ fontSize: 16 }}>Amount refund to the wallet: <span style={{ fontWeight: 600, color: 'red' }}>${breakdownData.base_refund_cash / 100}</span></div>
            <div style={{ fontSize: 16 }}>Tax refund to the wallet: <span style={{ fontWeight: 600, color: 'red' }}>${breakdownData.tax_refund_cash / 100}</span></div>
            <div className="modal_btn_container">
              <button
                  style={{ margin: '0 auto', borderRadius: 4 }}
                  onClick={() => {
                    setBreakdown(null)
                    setBreakdownData(null)
                  }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      {isLoading && <UsersListLoading />}
    </KTCardBody>
  );
};
