import { useEffect, useState } from 'react'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import { KTIcon } from '../../../../_metronic/helpers'

const Filter = ({ filter, setFilter }) => {
  const [status, setStatus] = useState('');
  const [rejctedReason, setRejectedReason] = useState('');


  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  useEffect(() => {
    if (filter) {
      setStatus(filter.status)
      setRejectedReason(filter.rejected_reason)
    }
  }, [filter])


  const resetData = () => {
    setStatus('')
    setFilter({
      status: '',
      rejected_reason: ''
    })
  }

  const filterData = () => {
    setFilter({
      status: status,
      reject_reason: rejctedReason
    })
  }

  return (
    <>
      {/* begin::Filter Button */}
      <button
        type='button'
        className='btn me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        style={{ backgroundColor: '#ffa459', color: 'white' }}
      >
        <KTIcon iconName='filter' className='fs-2 text-white' iconType={filter.status ? 'solid' : 'duotone'} />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>

          <div className='mb-5'>
            <label className='form-label fs-6 fw-bold'>Status:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select Status'
              data-allow-clear='true'
              data-kt-user-table-filter='two-step'
              data-hide-search='true'
              onChange={(e) => setStatus(e.target.value)}
              value={status}
            >
              <option value="" selected >Select Status</option>
              <option value='1'>Active Listing</option>
              <option value='0'>Draft Listing</option>
              <option value='4'>Pending Listing</option>
              <option value='2'>Disabled By User</option>
              <option value='3'>Disabled By Admin</option>
              <option value='5'>Rejected Listing</option>
              <option value='-1'>Deleted Listing</option>
            </select>
          </div>
       {status === '5' &&  <div className='mb-5'>
            <label className='form-label fs-6 fw-bold'>Rejected Reasons:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select Reject Reason'
              data-allow-clear='true'
              data-kt-user-table-filter='two-step'
              data-hide-search='true'
              onChange={(e) => setRejectedReason(e.target.value)}
              value={rejctedReason}
            >
              <option value="" selected>Select Reject Reason</option>
              <option value="1,2,3,4">All Reason</option>
              <option value='1'>Not Suitable</option>
              <option value='2'>Decided Against It</option>
              <option value='3'>Not Ready - Future Potential</option>
              <option value='4'>Unresponsive</option>
            </select>
          </div>
          }
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
            >
              Reset
            </button>
            <button
              disabled={!status}
              type='button'
              onClick={filterData}
              className='btn fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
              style={{ backgroundColor: '#ffa459', color: '#fff' }}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export { Filter }
