import { ConfirmationModalForm } from "./ConfirmationModalForm";

const ConfirmationModalFormWrapper = ({
  isListing,
  setOpenConfirmationModal,
  onClick,
}) => {
  return (
    <ConfirmationModalForm setOpenConfirmationModal={setOpenConfirmationModal} onClick={onClick} isListing={isListing} />
  );
};

export { ConfirmationModalFormWrapper };
