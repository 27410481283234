import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import axios from "axios";
import { toast } from "react-toastify";
import DatePicker from "react-multi-date-picker";
import dayjs from "dayjs";
import opacity from "react-element-popper/animations/opacity";
import moment from "moment";

const ArrearsModalForm = ({ setOpenArrearsModal, setTrigger }) => {
  const [type, setType] = useState("reservation");
  const [propertyName, setPropertyName] = useState("");
  const [truckerName, setTruckerName] = useState("");
  const [truckerMemberNumber, setTruckerMemberNumber] = useState("");
  const [reservationCode, setReservationCode] = useState("");
  const [notes, setNotes] = useState("");
  const [bookingAmount, setBookingAmount] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalArrears, setTotalArrears] = useState(0);
  const [lastArrears, setLastArrears] = useState([]);
  const [truckerId, setTruckerId] = useState("");
  const [ownerId, setOwnerId] = useState("");
  const [listingData, setListingData] = useState(null);
  const [listingId, setListingId] = useState("");
  const [penaltyMultiplier, setPenaltyMultiplier] = useState(1.5);
  const [wavePenalty, setWavePenalty] = useState(false);
  const [truckerMemberLoaded, setTruckerMemberLoaded] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const reservationRef = useRef(null);
  const truckerRef = useRef(null);

  const reset = () => {
    setPropertyName("");
    setTruckerMemberLoaded(false);
    setTruckerName("");
    setTruckerMemberNumber("");
    setReservationCode("");
    setNotes("");
    setBookingAmount("");
    setWavePenalty(false);
    setLoaded(false);
  };

  const cancel = () => {
    reset();
    setOpenArrearsModal(false);
  };

  useEffect(() => {
    reset();
  }, []);

  const getBookingDetails = () => {
    setLoading(true)

    if (type === "reservation") {
      axios.get(`${process.env.REACT_APP_API_URL}/booking?page=1&page_size=10&reservation_code=${reservationCode}`)
        .then((res) => {
          if (res.data.total > 0) {
            const booking = res.data.bookings[0];

            if (booking.arrears) {
              toast.error("This booking was already charged for overstaying");
              return;
            }

            getUser(booking?.customer_public_id);
            setReservationCode(booking.reservation_code);
            setPropertyName(booking.listing_name);
            setTruckerName(booking.customer_name);
            setTruckerMemberNumber(booking.customer_public_id);
            setTruckerId(booking.customer_id);
            setOwnerId(booking.owner_id);
            setListingId(booking.listing_id);
            setLoaded(true);
          } else {
            toast.error("Could not find such booking");
          }
        })
        .catch(() => {
          setLoaded(false);
        })
        .finally(() => {
          setLoading(false)
        })
    }

    if (type === "listing") {
      axios.get(`${process.env.REACT_APP_API_URL}/listing?page=1&page_size=10&listing_id=${propertyName.replace(/.*-/, "")}`)
        .then(res => {
          if (res.data.total > 0) {
            const listing = res.data.data[0];
            if(listing?.status !== -1){
              setPropertyName(listing.title);
              setListingId(listing.listing_id);
              setOwnerId(listing.owner_id);
              setListingData(listing);
              setLoaded(true);
            }else{
              toast.error("This listing is not available");
              setPropertyName("");
              setListingId("");
              setOwnerId("");
              setListingData(null);    
              setLoaded(false);         
            }
          } else {
            toast.error("Could not find such listing");
          }
        })
        .catch(() => {
          setLoaded(false);
        })
        .finally(() => {
          setLoading(false)
        });
    }
  };

  const getUser = (memberNumber) => {
    axios.get(`${process.env.REACT_APP_API_URL}/arrearages/user?public_id=${memberNumber}`)
      .then(res => {
        if (res.data) {
          const user = res.data;
          setTruckerName(user.first_name + " " + user.last_name);
          setTruckerId(user.id);
          setTotalArrears(user.total_arrears);
          setLastArrears(user.last5arrears);
          setPenaltyMultiplier(user.last5arrears && user.last5arrears[0] ? user.last5arrears[0] / 100 : 1.5);
          setTruckerMemberLoaded(true);
        } else {
          toast.error("Could not find such user");
        }
      })
      .catch(() => {
        toast.error("Could not find such user");
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if ((type === "reservation" || type === "listing") && !loaded) {
      reservationRef.current.click();
      return;
    }

    if (type === "listing" && !truckerMemberLoaded) {
      truckerRef.current.click();
      return;
    }

    setLoading(true);
    const start = dayjs(startDate).format("YYYY-MM-DD");
    const end = dayjs(endDate).format("YYYY-MM-DD");
    let utcStartDate = moment.tz(start + " " + '00:00:00', listingData?.timezone_id).utc().format("YYYY-MM-DD HH:mm");
    let utcEndDate = moment.tz(end + " " + '00:00:00', listingData?.timezone_id).utc().subtract(1, 'minutes').format("YYYY-MM-DD HH:mm");

    axios.post(`${process.env.REACT_APP_API_URL}/arrearages`, {
      customer_id: truckerId,
      owner_id: ownerId,
      ...(type === "reservation" && { reservation_code: reservationCode }),
      ...(type === "listing" && { start_date: utcStartDate }),
      ...(type === "listing" && { end_date: utcEndDate }),
      listing_id: listingId,
      amount: bookingAmount * 100,
      notes,
      is_penalty_waived_off: wavePenalty,
      penalty_multiplier: penaltyMultiplier * 100
    })
      .then(() => {
        toast.success("Arrears form successfully created");
        setOpenArrearsModal(false);
        setTrigger(prev => !prev);
      })
      .catch(err => {
        console.log(err.response.data.message);
        toast.error(err?.response?.data?.message || "Could not create arrears form");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onValueChange = (e, fn) => {
    const newValue = e.target.value
    const regex = /^[1-9]\d*(\.)?(\d{1,2})?$/
    const isValidPrice = regex.test(newValue)

    if (isValidPrice || e.target.value === '') {
      fn(e.target.value)
    }
  };

  return (
    <>
      <form className="form" id="kt_modal_add_user_form" autoComplete="off" onSubmit={handleSubmit} action="">
        <div
          className="d-flex flex-column scroll-y me-n7 pe-7"
          id="kt_modal_add_user_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_add_user_header"
          data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
          data-kt-scroll-offset="300px"
        >
          <div className="nav-group nav-group-fluid mb-5">
            <label>
              <input
                type="radio"
                className="btn-check"
                name="type"
                checked={type === "reservation"}
                onChange={() => {
                  reset();
                  setType("reservation");
                }}
              />
              <span className="btn btn-sm btn-color-muted btn-active btn-active-primary">
                By Reservation Number
              </span>
            </label>
            <label>
              <input
                type="radio"
                className="btn-check"
                name="type"
                checked={type === "listing"}
                onChange={() => {
                  reset();
                  setType("listing");
                }}
              />
              <span className="btn btn-sm btn-color-muted btn-active btn-active-primary px-4">
                By Listing Identifier
              </span>
            </label>
          </div>

          <div className="fv-row mb-5" style={{ position: "relative" }}>
            {type === "reservation" && (
              <>
                <label className="fw-bold fs-6 mb-2">Reservation #</label>
                <input
                  placeholder="Enter Reservation #"
                  className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                  name="reservationNumber"
                  value={reservationCode}
                  onChange={(e) => setReservationCode(e.target.value)}
                  disabled={loaded}
                />
              </>
            )}
            {type === "listing" && (
              <>
                <label className="fw-bold fs-6 mb-2">Listing Identifier</label>
                <input
                  placeholder="Enter Listing Identifier"
                  className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                  name="listingName"
                  value={propertyName}
                  onChange={(e) => setPropertyName(e.target.value.replace(/[^0-9-]/g, ''))}
                  disabled={loaded}
                />
              </>
            )}
            {loaded && (
              <div
                style={{
                  position: "absolute",
                  right: "14px",
                  bottom: "12px",
                  cursor: "pointer"
                }}
                onClick={() => {
                  setLoaded(false);
                  setPropertyName("");
                  setTruckerName("");
                  setTruckerMemberNumber("");
                }}
              >
                Change
              </div>
            )}
          </div>
          {!loaded && (
            <button
              type="submit"
              className="btn"
              style={{ backgroundColor: "#2ecc71", color: "white" }}
              onClick={getBookingDetails}
              disabled={loading || (type === "reservation" ? reservationCode.length < 3 : propertyName.length < 3)}
              ref={reservationRef}
            >
              Confirm
            </button>
          )}

          {loaded && (
            <div>
              <div>
                {type === "reservation" && (
                  <div className="fv-row mb-5" style={{ position: "relative" }}>
                    <label className="fw-bold fs-6 mb-2">Property Name</label>
                    <input
                      readOnly
                      placeholder="Enter Property Name"
                      className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                      name="propertyName"
                      value={propertyName}
                    />
                  </div>
                )}
                <div className="fv-row mb-5" style={{ position: "relative" }}>
                  <label className=" fw-bold fs-6 mb-2">Trucker Member #</label>
                  <input
                    readOnly={type === "reservation" || truckerMemberLoaded}
                    placeholder="Enter Trucker Member #"
                    className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                    name="memberNumber"
                    value={truckerMemberNumber}
                    onChange={e => setTruckerMemberNumber(e.target.value)}
                  />
                  {(type === "listing" && truckerMemberLoaded) && (
                    <div
                      style={{
                        position: "absolute",
                        right: "14px",
                        bottom: "12px",
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        setTruckerMemberLoaded(false)
                        setTruckerName("")
                        setTruckerMemberNumber("")
                      }}
                    >
                      Change
                    </div>
                  )}
                </div>
                {(type === "listing" && !truckerMemberLoaded) && (
                  <button
                    type="submit"
                    className="btn"
                    style={{ backgroundColor: "#2ecc71", color: "white", width: "100%" }}
                    onClick={() => getUser(truckerMemberNumber)}
                    disabled={truckerMemberNumber.length < 6}
                    ref={truckerRef}
                  >
                    Confirm
                  </button>
                )}
                {(type === "reservation" || truckerMemberLoaded) && (
                  <>
                    <div className="fv-row mb-5">
                      <div className="d-flex justify-content-between">
                      <label className=" fw-bold fs-6 mb-2">Trucker Name</label>
                      <span>Total Arrears Booked: {totalArrears}</span>
                      </div>
                      <input
                        readOnly
                        placeholder="Enter Trucker Name"
                        className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                        name="truckerName"
                        value={truckerName}
                      />
                    </div>
                    {type === "listing" && (
                      <div className="d-grid column-gap-4" style={{ gridTemplateColumns: "1fr 1fr" }}>
                        <div className="mb-3">
                          <label className="fw-bold fs-6 mb-2 required">Start Date</label>
                          <DatePicker
                            style={{
                              width: "100%",
                              boxSizing: "border-box",
                              borderRadius: "8px",
                              padding: "0.775rem 1rem",
                              fontSize: "1.1rem",
                              fontWeight: "500",
                              lineHeight: "1.5",
                              backgroundColor: "#f9f9f9",
                              border: "1px solid #f9f9f9",
                              color: "#5e6278",
                              transition: "color 0.2s ease",
                              height: "auto"
                            }}
                            containerStyle={{
                              width: "100%"
                            }}
                            className="rmdp-mobile custom-calendar"
                            arrow={false}
                            value={startDate}
                            onChange={(val) => {
                              setStartDate(val);
                            }}
                            maxDate={dayjs(endDate).toDate()}
                            format="dddd MMM DD,YYYY"
                            fixMainPosition={true}
                            animations={[opacity()]}
                            placeholder="Select Start Date"
                            weekDays={["S", "M", "T", "W", "T", "F", "S"]}
                            scrollSensitive={false}
                            hideOnScroll={false}
                          />
                        </div>
                        <div className="mb-3">
                          <label className="fw-bold fs-6 mb-2 required">End Date</label>
                          <DatePicker
                            style={{
                              width: "100%",
                              boxSizing: "border-box",
                              borderRadius: "8px",
                              padding: "0.775rem 1rem",
                              fontSize: "1.1rem",
                              fontWeight: "500",
                              lineHeight: "1.5",
                              backgroundColor: "#f9f9f9",
                              border: "1px solid #f9f9f9",
                              color: "#5e6278",
                              transition: "color 0.2s ease",
                              height: "auto"
                            }}
                            containerStyle={{
                              width: "100%"
                            }}
                            className="rmdp-mobile custom-calendar"
                            arrow={false}
                            value={endDate}
                            onChange={(val) => {
                              setEndDate(val);
                            }}
                            minDate={dayjs(startDate).toDate()}
                            format="dddd MMM DD,YYYY"
                            fixMainPosition={true}
                            animations={[opacity()]}
                            placeholder="Select End Date"
                            weekDays={["S", "M", "T", "W", "T", "F", "S"]}
                            scrollSensitive={false}
                            hideOnScroll={false}
                          />
                        </div>
                      </div>
                    )}
                    <div className="fv-row mb-5">
                      <label className="fw-bold fs-6 mb-2">Dates Covered/Notes</label>
                      <input
                        placeholder="Enter Notes"
                        className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                        name="notes"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                      />
                    </div>
                    <div className="fv-row mb-5">
                      <label className="fw-bold fs-6 mb-2 required">Booking Amount Due</label>
                      <input
                        placeholder="Enter Booking Amount Due"
                        className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                        name="bookingAmount"
                        value={bookingAmount}
                        onChange={e => onValueChange(e, setBookingAmount)}
                      />
                    </div>
                    <div className="fv-row mb-5">
                      <div className="d-flex justify-content-between">
                      <label className="fw-bold fs-6 mb-2">Penalty Multiplier</label>
                      {lastArrears && lastArrears.length > 0 &&
                      <div>
                      <span className="fw-bold fs-6 mb-2">Last Penalty Multiplier Applied: </span>
                      <span className="badge badge-light-info fs-6">{lastArrears[0] / 100}</span>
                      </div>
                      }
                      </div>
                      <input
                        placeholder="Enter Penalty Multiplier"
                        className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                        name="penalty"
                        value={penaltyMultiplier}
                        onChange={e => onValueChange(e, setPenaltyMultiplier)}         
                        readOnly={wavePenalty}
                        onBlur={(e) => {
                         if(!wavePenalty && e.target.value < 1.5) {
                          setPenaltyMultiplier(1.5)
                         }
                        }}
                      />
                    </div>
                    <div className="form-check form-check-custom form-check-solid mb-5">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="1"
                        checked={wavePenalty}
                        onChange={() => {
                          setWavePenalty(prev => !prev)
                          if(!wavePenalty){
                            setPenaltyMultiplier(1)
                          }else{
                            setPenaltyMultiplier(1.5)
                          }
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckChecked"
                        style={{ fontWeight: "700", color: "#3f4254" }}
                      >
                        Waive Penalty
                      </label>
                    </div>
                    <div className="fv-row mb-5">
                      <label className="fw-bold fs-6 mb-2">Penalty Amount</label>
                      <input
                        placeholder="Enter Penalty Amount"
                        className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                        name="penalty"
                        value={wavePenalty ? 0 : ((bookingAmount * (penaltyMultiplier ? penaltyMultiplier : 1)) - bookingAmount)?.toFixed(2)}
                        readOnly
                      />
                    </div>
                    <div className="fv-row mb-5">
                      <label className="fw-bold fs-6 mb-2">Total Charge</label>
                      <input
                        placeholder="Enter Total Charge"
                        className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                        name="total"
                        value={wavePenalty ? bookingAmount : (bookingAmount * penaltyMultiplier)?.toFixed(2)}
                        readOnly
                      />
                    </div>
                  </>
                )}
              </div>
              {(type === "reservation" || truckerMemberLoaded) && (
                <div className="text-center pt-15">
                  <button
                    type="reset"
                    onClick={cancel}
                    className="btn btn-light mx-3"
                    data-kt-users-modal-action="cancel"
                  >
                    Discard
                  </button>
                  <button
                    type="submit"
                    className="btn"
                    data-kt-users-modal-action="submit"
                    style={{ background: "#ffa459", color: "#fff" }}
                    disabled={!propertyName || loading || !loaded || !truckerMemberNumber || !bookingAmount || (type === "listing" && (!startDate || !endDate))}
                  >
                    <span className="indicator-label">Submit</span>
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </form>
    </>
  );
};

export { ArrearsModalForm };
