import { useEffect, useState, useRef } from "react";
import { KTIcon } from "../../../../_metronic/helpers";
import clsx from "clsx";
import axios from "axios";
import { toast } from "react-toastify";
import { Breakdown } from "./Breakdown";
import css from "../../Listings/ListingBookingmodal/checkout.module.scss";
import { getBrandIcon, parseAddressFromHTMLString } from "../../../../_metronic/helpers/utils";
import {
  CardElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import dayjs from "dayjs";
import { UsersListLoading } from '../../Reviews/Review/users-list/components/loading/UsersListLoading'
import GoogleMapReact from 'google-map-react'

const ExtendModalForm = ({ setSelectedBookingId, fetchBooking, selectedBookingId, bookingDetails, setOpenExtendModal }) => {
  const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  const stripe = useStripe();
  const elements = useElements();
  const [duration, setDuration] = useState("");
  const [discount, setDiscount] = useState({});
  const [promoCode, setPromoCode] = useState("");
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState();
  const [isFormVisible, setFormVisible] = useState(false);
  const [cardHolderName, setCardHolderName] = useState("");
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isMapLoaded, setIsMapLoaded] = useState(false)

  const cancel = () => {
    setSelectedBookingId(null);
    setOpenExtendModal(false);
    setDiscount({});
    setPromoCode("");
    setDuration("");
    setCards([]);
    setSelectedCard();
    setIsLoading(false);
  };

  const getCards = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/user/${selectedBookingId.customer_uid}/cards`)
      .then((res) => {
        setCards(res.data);
      });
  };

  useEffect(() => {
    getCards();
  }, [selectedBookingId]);

  useEffect(() => {
    if (cards && cards.length === 1) {
      setSelectedCard(cards[0].id);
    }
  }, [cards]);

  const autoCompleteRef = useRef(null);
  const inputRef = useRef(null);

  const options = {
    componentRestrictions: { country: "us" },
    requestedLanguage: "en",
    fields: ["adr_address", "address_components", "formatted_address", "geometry", "icon", "name"],
    types: ["address"]
  };
  let autocompleteService;
  const handleApiLoaded = async (map, maps) => {
    const { Autocomplete, AutocompleteService } = await maps.importLibrary('places')
    autoCompleteRef.current = new Autocomplete(
      inputRef.current,
      options
    )
    autocompleteService = new AutocompleteService()

    autoCompleteRef.current.addListener('place_changed', async function () {
      const place = await autoCompleteRef.current.getPlace()
      const p_state = place.address_components.find(el => el.types.indexOf('administrative_area_level_1') > -1)
      const p_city = parseAddressFromHTMLString(place.adr_address).city
      const p_zip = place.address_components.find(el => el.types.indexOf('postal_code') > -1)
      const p_address = place.formatted_address

      setAddress(p_address)
      setCity(p_city ? p_city : '')
      setState(p_state ? p_state.long_name : '')
      setZipCode(p_zip ? p_zip.long_name : '')
    })

    setIsMapLoaded(true)
  }

  useEffect(() => {
    if (window.google && inputRef.current && selectedBookingId) {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        options
      );

      inputRef.current.addEventListener("blur", () => {
        autoCompleteRef.current.getPlace();
        if (!autoCompleteRef.current.getPlace()?.address_components) {
          setAddress("");
        }
      });
      autoCompleteRef.current.addListener("place_changed", async function () {
        const place = await autoCompleteRef.current.getPlace();
        if (!place?.address_components) {
          setAddress("");
          toast.error("No address found");
        } else {
          const p_state = place.address_components.find(el => el.types.indexOf('administrative_area_level_1') > -1)
          const p_city = parseAddressFromHTMLString(place.adr_address).city
          const p_zip = place.address_components.find(el => el.types.indexOf('postal_code') > -1)
          const p_address = place.formatted_address

          if (p_address) {
            setAddress(p_address)
          }

          if (p_state) setState(p_state.long_name);
          if (p_city) setCity(p_city);
          if (p_zip) setZipCode(p_zip.long_name);
        }
      });
    }
  }, [window.google, inputRef.current]);

  const handleSave = () => {
    const card = elements.getElement(CardElement);
    stripe.createToken(card, {
      name: cardHolderName,
      address_line1: address,
      address_city: city,
      address_state: state,
      address_zip: zipCode,
      address_country: "US"
    })
      .then((res) => {
        axios.post(`${process.env.REACT_APP_API_URL}/user/${selectedBookingId?.customer_uid}/card`, {
          token: res.token.id
        })
          .then(() => {
            setFormVisible(false);
            toast.success(`Card Have Been Added to ${selectedBookingId.customer_name} Account `);
            getCards();
          })
          .catch(() => {
            toast.error("Card Cannot be Added, Please Try Again");
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const applyPromo = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/booking/promo/preview`, {
      listing_id: selectedBookingId?.listing_id,
      vehicles: selectedBookingId?.vehicles?.map((item) => item.vehicle_id),
      booking_type: selectedBookingId?.booking_type,
      duration: duration,
      promo_code: promoCode,
      user_id: selectedBookingId?.customer_id
    })
      .then(res => {
        toast.success("Promo Code Applied Successfully");
        setDiscount(res.data);
      })
      .catch(err => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Technical Error, Please Try Again");
        }
      });
  };

  const getQuote = (quoteId, duration, selectedBookingId) => {
    axios
      .get(`${process.env.REACT_APP_API_V3_URL}/quotes?quote_id=${quoteId}`)
      .then((res) => {
        if (res.data && res.data.booking_id) {
          toast.success(`Booking have been extended for ${duration} more ${selectedBookingId.booking_type === 1 ? 'hours' : selectedBookingId.booking_type === 2 ? 'days' : 'weeks'}`);
        } else {
          toast.error("Technical Error, Please Try Again");
        }
      })
      .catch((err) => {
        toast.error("Technical Error, Please Try Again");
        setIsLoading(false);
      });
  }

  const extendBooking = () => {
    setIsLoading(true);
    axios.post(`${process.env.REACT_APP_API_URL}/booking/checkout/preview?dr=false`, {
      payment_mode: 1,
      duration: duration,
      ...(selectedCard && { card_id: selectedCard }),
      customer_id: selectedBookingId?.customer_id,
      booking_id: selectedBookingId?.booking_id,
      promo_code: promoCode ? promoCode : null,
    })
      .then((res) => {
        setTimeout(() => {
          getQuote(res?.data?.quote_id, duration, selectedBookingId);
          fetchBooking()
          cancel();
        }, [4000])
      })
      .catch(err => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Technical Error, Please Try Again");
        }
        setIsLoading(false);
      });
  };

  return (
    <>
      <div
        className="d-flex flex-column scroll-y me-n7 pe-7"
        id="kt_modal_add_user_scroll"
        data-kt-scroll="true"
        data-kt-scroll-activate="{default: false, lg: true}"
        data-kt-scroll-max-height="auto"
        data-kt-scroll-dependencies="#kt_modal_add_user_header"
        data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
        data-kt-scroll-offset="300px"
      >
        <div className="mb-5">
          <label className="form-label fs-6 fw-bold">
            How Many {selectedBookingId.booking_type === 1 ? 'Hours' : selectedBookingId.booking_type === 2 ? 'Days' : 'Weeks'} to Extend?
          </label>
          <input
            placeholder={`Set ${selectedBookingId.booking_type === 1 ? 'Hours' : selectedBookingId.booking_type === 2 ? 'Days' : 'Weeks'} To Extend`}
            className={clsx(
              "form-control form-control-solid mb-3 mb-lg-0"
            )}
            type="number"
            onWheel={(e) => e.target.blur()}
            name="limit"
            value={duration}
            min={1}
            max={selectedBookingId.booking_type === 3 ? 3 : '10'}
            onChange={(e) => {
              const re = /^[0-9]*$/;
              if (!re.test(e.target.value)) {
                setDuration("");
                setPromoCode("");
                setDiscount({});
              } else {
                setDuration(selectedBookingId.booking_type === 3 ? /^[1-3]$/.test(e.target.value) && e.target.value : e.target.value);
                setPromoCode("");
                setDiscount({});
              }
            }}
          />
        </div>
        {duration && (
          <div className="mt-3 mb-3">
            <Breakdown
              type={selectedBookingId.booking_type}
              hours={duration}
              days={duration}
              start={selectedBookingId.end_date}
              end={dayjs(selectedBookingId.end_date).add(+duration, selectedBookingId.booking_type === 1 ? 'hours' : selectedBookingId.booking_type === 2 ? 'days' : 'weeks')}
              price={bookingDetails?.price}
              vehiclesAmount={1}
              weeks={duration}
              discount={discount}
              promoCode={promoCode}
              bookingDetails={selectedBookingId}
            />
            <div className="chargeNote mt-3">User won't be charged yet</div>
          </div>
        )}
        {duration && (
          <div className="fv-row">
            <label className="fw-bold fs-6 mb-2">
              Promo Code (Optional)
            </label>
            {(discount && discount.discounted_percent) && (
              <div
                className="form-check form-check-custom form-check-solid"
                style={{
                  background: "#ffa459",
                  padding: "5px",
                  display: "flex",
                  justifyContent: "flex-end",
                  borderRadius: "15px",
                  minWidth: "max-content",
                  maxWidth: "max-content"
                }}
              >
                <label
                  className="form-check-label"
                  htmlFor="flexCheckChecked"
                  style={{ fontWeight: "700", color: "#fff", fontSize: "14px" }}
                >
                  {promoCode}
                </label>
                <button
                  className="listing-remove"
                  onClick={() => {
                    setDiscount({});
                    setPromoCode("");
                  }}
                >
                  <KTIcon iconName="cross" />
                </button>
              </div>
            )}
            <div style={{ position: "relative" }}>
              {(discount && !discount.discounted_percent) && (
                <input
                  placeholder="Enter Promo Code"
                  type="text"
                  name="code"
                  className={clsx("form-control form-control-solid mb-3 mb-lg-0")}
                  value={promoCode}
                  onChange={(e) => {
                    setPromoCode(e.target.value);
                  }}
                  autoComplete="off"
                  disabled={discount && discount.discounted_percent}
                />
              )}
              {(discount && !discount.discounted_percent) && (
                <div
                  className="apply cursor-pointer"
                  onClick={applyPromo}
                >
                  Apply Promo Code
                </div>
              )}
            </div>
          </div>
        )}
        {duration && (
          <>
            <label
              className="fw-bold fs-1 mb-2 w-100 mt-7 mb-4"
              style={{ textAlign: "center", color: "#b2b2b2" }}
            >
              Pay With
            </label>
            <div className={css.list}>
              {!!cards.length && cards.map((el) => (
                <div
                  key={el.id}
                  className={`${css.cardItem} ${selectedCard === el.id ? css.active : ""
                    }`}
                  onClick={() => {
                    if (selectedCard === el.id) {
                      setSelectedCard("");
                    } else {
                      setSelectedCard(el.id);
                    }
                  }}
                >
                  <div className={css.icon}>
                    <img src={getBrandIcon(el.brand)} alt="" />
                  </div>
                  <div
                    className={css.number}
                  >{`•••• •••• •••• ${el.last4}`}</div>
                  <div>
                    {el.exp_month}/{el.exp_year.toString().slice(-2)}
                  </div>
                </div>
              ))}

              {cards.length < 1 && (
                <button
                  className={`btn`}
                  style={{
                    backgroundColor: "#ffa459",
                    color: "#fff",
                    marginBottom: "16px"
                  }}
                  onClick={() => {
                    setFormVisible((prev) => !prev);
                    setCardHolderName("");
                    setAddress("");
                    setZipCode("");
                    setCity("");
                    setState("");
                  }}
                >
                  {isFormVisible ? "Hide form" : "Add a new card"}
                </button>
              )}

              {isFormVisible && (
                <div>
                  <div style={{ height: '0' }}>
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: GOOGLE_MAPS_API_KEY,
                        language: 'en',
                        region: 'US'
                      }}
                      defaultCenter={{ lat: 38.6311966, lng: -98.1633745 }}
                      defaultZoom={4}
                      yesIWantToUseGoogleMapApiInternals
                      onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                    />
                  </div>
                  <div>
                    <label className="required fw-bold fs-6 mb-4">
                      Payment Card Details
                    </label>
                    <CardElement
                      className={clsx(
                        "form-control form-control-solid mb-3 mb-lg-0"
                      )}
                      options={{
                        hidePostalCode: true,
                        style: {
                          base: {
                            fontSize: "16px",
                            lineHeight: "24px",
                            fontWeight: "500",
                            color: "#4a4a4a",
                            "::placeholder": { color: "#B2B2B2" }
                          }
                        }
                      }}
                    />
                  </div>
                  <div className={css.billing}>
                    <label
                      className="fw-bold fs-2"
                      style={{ color: "#b2b2b2" }}
                    >
                      Billing Details
                    </label>
                    <div className={css.inputs}>
                      <div className="fv-row mb-7">
                        <label className="required fw-bold fs-6 mb-2">
                          Card Holder's Name
                        </label>
                        <input
                          placeholder="Card Holder's Name"
                          type="text"
                          name="card_holder_name"
                          className={clsx(
                            "form-control form-control-solid mb-3 mb-lg-0"
                          )}
                          value={cardHolderName}
                          onChange={(e) => setCardHolderName(e.target.value)}
                          autoComplete="off"
                        />
                      </div>

                      <div className="fv-row mb-7">
                        <label className="required fw-bold fs-6 mb-2">
                          Address
                        </label>
                        <input
                          placeholder="123 Example Street"
                          type="text"
                          name="address"
                          className={clsx(
                            "form-control form-control-solid mb-3 mb-lg-0"
                          )}
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          autoComplete="off"
                          ref={inputRef}
                          disabled={!isMapLoaded}
                        />
                      </div>

                      <div className="fv-row mb-7">
                        <label className="required fw-bold fs-6 mb-2">
                          Postal Code
                        </label>
                        <input
                          placeholder="Postal Code"
                          type="text"
                          name="postal_code"
                          className={clsx(
                            "form-control form-control-solid mb-3 mb-lg-0"
                          )}
                          value={zipCode}
                          onChange={(e) => setZipCode(e.target.value)}
                          autoComplete="off"
                        />
                      </div>

                      <div className="fv-row mb-7">
                        <label className="required fw-bold fs-6 mb-2">
                          City
                        </label>
                        <input
                          placeholder="City"
                          type="text"
                          name="city"
                          className={clsx(
                            "form-control form-control-solid mb-3 mb-lg-0"
                          )}
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          autoComplete="off"
                        />
                      </div>

                      <div className="fv-row mb-7">
                        <label className="required fw-bold fs-6 mb-2">
                          State
                        </label>
                        <input
                          placeholder="State"
                          type="text"
                          name="state"
                          className={clsx(
                            "form-control form-control-solid mb-3 mb-lg-0"
                          )}
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <button
                      className="btn mt-0"
                      style={{ backgroundColor: "#2ecc71", color: "#fff" }}
                      onClick={handleSave}
                      disabled={
                        !cardHolderName ||
                        !address ||
                        !zipCode ||
                        !city ||
                        !state
                      }
                    >
                      Save Payment Card
                    </button>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>

      <div className="text-center pt-15">
        <button
          type="reset"
          onClick={cancel}
          className="btn btn-light me-3"
          data-kt-users-modal-action="cancel"
          disabled={isLoading}
        >
          Discard
        </button>
        <button
          type="submit"
          className="btn"
          data-kt-users-modal-action="submit"
          style={{ background: "#ffa459", color: "#fff" }}
          disabled={!duration || !cards.length || isLoading}
          onClick={extendBooking}
        >
          <span className="indicator-label">Extend Booking</span>
        </button>
      </div>
      {isLoading && <UsersListLoading />}
    </>
  );
};

export { ExtendModalForm };
