import { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate, Link } from "react-router-dom";
import { UserEditModal } from "./userEditModal/UserEditModal";
import clsx from "clsx";
import "./style.css";
import { toast } from "react-toastify";
import { VehicleModal } from "./vehicleModal/VehicleModal";
import { AddListingModal } from "./addListingModal/AddListingModal";
import moment from "moment";
import { convertUTCTimeToListingTime } from "../../../_metronic/helpers/convertTime.js";
import { MessageModal } from "./messageModal/MessageModal";
import { KTCard, KTCardBody } from "../../../_metronic/helpers";
import { useAuth } from "../auth";
import { UsersListLoading } from "../Listings/SingleListing/loading/UsersListLoading";
import Reviews from "../../../components/Reviews";
import { getListingPrices, getColorByBookingStatus, getRejectReason, showStatusBadge, getStatusBadgeText, formatPrice } from "../../../_metronic/helpers/utils";
import { AdvocateModal } from "./advocateModal/advocateModal";
import { PointsModal } from "./PointsModal/PointsModal";
import { ApiModal } from "./ApiModal/ApiModal";
import { OrgModal } from "./OrgModal/OrgModal";
import { WalletFreezeModal } from "./WalletFreezeModal/WalletFreezeModal";
import { ConfirmationModal } from "./confirmationModal/ConfirmationModal"

const Profile = () => {
  const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
  const { currentUser, haveAccess, walletsEnabled } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showVehicleModal, setShowVehicleModal] = useState(false);
  const [vehicle, setVehicle] = useState(null);
  const [showListingModal, setShowListingModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [selectedBooking, setSelectedBooking] = useState("progress");
  const [bookings, setBookings] = useState([]);
  const [selectedBookingHosted, setSelectedBookingHosted] = useState("progress");
  const [bookingsHosted, setBookingsHosted] = useState([]);
  const [allReviews, setAllReviews] = useState([])
  const [bookingsPage, setBookingsPage] = useState(1);
  const [bookingsPageSize, setBookingsPageSize] = useState(30);
  const [bookingsHostedPage, setBookingsHostedPage] = useState(1);
  const [bookingsHostedPageSize, setBookingsHostedPageSize] = useState(30);
  const [totalBookings, setTotalBookings] = useState(1);
  const [totalHostedBookings, setTotalHostedBookings] = useState(1);
  const [bookingsLoading, setBookingsLoading] = useState(false);
  const [hostedBookingsLoading, setHostedBookingsLoading] = useState(true);
  const [bookingsCount, setBookingsCount] = useState({});
  const [hostedBookingsCount, setHostedBookingsCount] = useState({});
  const [openAdvocateModal, setOpenAdvocateModal] = useState(false)
  const [openPointsModal, setOpenPointsModal] = useState(false)
  const [openAPIModal, setOpenAPIModal] = useState(false)
  const [openOrgModal, setOpenOrgModal] = useState(false)
  const [openTopUpWalletModal, setOpenTopUpWalletModal] = useState({
    status:false,
    type:'add'
  })
  const [openFreezeWalletModal, setOpenFreezeWalletModal] = useState(false)
  const [openResetStripeConfirmationModal, setOpenResetStripeConfirmationModal] = useState(false)

  const isProd = STRIPE_PUBLIC_KEY.indexOf('pk_live') === 0

  const getUser = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/user/${id}`)
      .then((res) => {
        const responseUser = res.data;
        setUser(responseUser);
        getBookingsCount(responseUser?.id);
        getHostedBookingsCount(responseUser?.id);
       responseUser?.is_b2b && (responseUser?.org_role == 1 || responseUser?.org_role == 2) && walletsInfo(responseUser?.id, responseUser)
      })
      .catch((err) => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Technical Error, Please Try Again");
        }
      });
  };

  useEffect(() => {
    getUser();
  }, []);

  const getBookings = (id) => {
    setBookingsLoading(true);
    axios.get(`${process.env.REACT_APP_API_URL}/booking?&page=${bookingsPage}&page_size=${bookingsPageSize}&sort=4&` +
      `customer_id=${id}&send_ongoing=${selectedBooking === "progress" ? "true" : "false"}&` +
      `status=${selectedBooking === "cancelled" ? "4,9,10" : selectedBooking === "not-renewing" ? "8" : selectedBooking === "completed" ? "6,12" : selectedBooking === "past_due" ? "11" : ""}&` +
      `send_upcoming=${selectedBooking === "upcoming" ? "true" : "false"}`
    )
      .then((res) => {
        setBookingsLoading(false);
        const responseUser = res.data;
        setBookings(responseUser?.bookings);
        setTotalBookings(responseUser.total > 0 ? responseUser.total : 1);
      })
      .catch((err) => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Technical Error, Please Try Again");
        }
        setBookingsLoading(false);
      });
  };

  const getBookingsHosted = (id) => {
    setHostedBookingsLoading(true);
    axios.get(`${process.env.REACT_APP_API_URL}/booking?owner_id=${id}&page=${bookingsHostedPage}&page_size=${bookingsHostedPageSize}&status=${selectedBookingHosted === "cancelled" ? "4,9,10" : selectedBookingHosted === "not-renewing" ? "8" : selectedBookingHosted === "completed" ? "6,12" : selectedBookingHosted === 'past_due' ? '11' : ""}&send_upcoming=${selectedBookingHosted === "upcoming" ? "true" : "false"}&send_ongoing=${selectedBookingHosted === "progress" ? "true" : "false"}&sort=4`)
      .then((res) => {
        setHostedBookingsLoading(false);
        const responseUser = res.data;
        setBookingsHosted(responseUser?.bookings);
        setTotalHostedBookings(responseUser.total > 0 ? responseUser.total : 1);
      })
      .catch((err) => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Technical Error, Please Try Again");
        }
        setHostedBookingsLoading(false);
      });
  };

  const getBookingsCount = (id) => {
    axios.get(`${process.env.REACT_APP_API_URL}/booking/count-by-status?customer_id=${id}`)
      .then((res) => {
        let sum = 0;
        if (!isNaN(res.data?.[4])) {
          sum += res.data?.[4];
        }
        if (!isNaN(res.data?.[9])) {
          sum += res.data?.[9];
        }
        if (!isNaN(res.data?.[10])) {
          sum += res.data?.[10];
        }
        setBookingsCount({
          ...res.data,
          cancelled: sum,
        });
      });
  };

  const getHostedBookingsCount = (id) => {
    axios.get(`${process.env.REACT_APP_API_URL}/booking/count-by-status?owner_id=${id}`)
      .then((res) => {
        let sum = 0;
        if (!isNaN(res.data?.[4])) {
          sum += res.data?.[4];
        }
        if (!isNaN(res.data?.[9])) {
          sum += res.data?.[9];
        }
        if (!isNaN(res.data?.[10])) {
          sum += res.data?.[10];
        }
        setHostedBookingsCount({
          ...res.data,
          cancelled: sum
        });
      });
  };

  useEffect(() => {
    if (user) {
      getBookings(user?.id);
    }
  }, [bookingsPage, bookingsPageSize, selectedBooking, user]);

  useEffect(() => {
    if (user) {
      getBookingsHosted(user?.id);
    }
  }, [bookingsHostedPage, bookingsHostedPageSize, selectedBookingHosted, user]);

  const loginAsUser = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/user/${id}/login`)
      .then((res) => {
        if (res.data.token) {
          window.open(
            `${process.env.REACT_APP_API_CUSTOMER_URL}admin-customer-login?token=${res?.data?.token}`,
            "_blank"
          );
        }
      })
      .catch((err) => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Technical Error, Please Try Again");
        }
      });
  };
  const logOutUser = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/user/${id}/logout`)
      .then(() => {
        toast.success("User session have been logged out");
      })
      .catch((err) => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Technical Error, Please Try Again");
        }
      });
  };

  function formatPhone(phone) {
    phone = phone?.replace(/[^\d]/g, "");

    if (phone?.length === 11) {
      return phone?.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "+$1 ($2) $3-$4");
    } else if (phone?.length === 10) {
      return phone?.replace(/(\d{3})(\d{3})(\d{4})/, "+1 ($1) $2-$3");
    }

    return null;
  }

  const duration = (duration) => {
    switch (duration) {
      case 1:
        return "hour";
      case 2:
        return "day";
      case 3:
        return "week";
      case 4:
        return "month";
      default:
        return "";
    }
  };

  const updateBookingsPage = (type) => {
    if (type === "dec") {
      setBookingsPage(bookingsPage - 1);
    } else if (type === "inc") {
      if (bookingsPage !== Math.ceil(totalBookings / bookingsPageSize)) {
        setBookingsPage(bookingsPage + 1);
      }
    }
  };

  const updateHostedBookingsPage = (type) => {
    if (type === "dec") {
      setBookingsHostedPage(bookingsHostedPage - 1);
    } else if (type === "inc") {
      if (bookingsHostedPage !== Math.ceil(totalHostedBookings / bookingsHostedPageSize)) {
        setBookingsHostedPage(bookingsHostedPage + 1);
      }
    }
  };

  const sendMessage = (type) => {
    setShowMessageModal(true);
    setMessageType(type);
  };

  const enableApi = () => {
    setOpenAPIModal(true);
  }

  const createOrg = () => {
    setOpenOrgModal(true);
  }

  useEffect(() => {
    if (user?.id) {
      axios.get(`${process.env.REACT_APP_API_URL}/review?page=1&page_size=50&customer_id=${user?.id}`)
        .then(res => {
          setAllReviews(res?.data?.reviews)
        }).catch((err) => {
          if (err && err.response && err.response?.data && err.response?.data?.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error("Technical Error, Please Try Again");
          }
        })
    }
  }, [user])

  const orgRole = (roleId) => {
    switch (roleId) {
      case 1:
        return "Owner";
      case 2:
        return "Manager";
      case 3:
        return "Trucker";
      case 4:
        return "Property Guard"
    }
  }

  const resetStripe = () => {
    axios.patch(`${process.env.REACT_APP_API_URL}/user/${user?.uid}/stripe`).then(res => {
      toast.success(res?.data?.message)
      getUser();
      setOpenResetStripeConfirmationModal(false)
    }).catch((err) => {
      if (err && err.response && err.response?.data && err.response?.data?.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error("Technical Error, Please Try Again");
      }
    })
  } 

  const isAllPendingOrDraft = (listings) => {
    if (listings?.length > 0) {
      if (listings?.every(listing => [0, 4].includes(listing?.status))) {
        return true
      }else{
        return false
      }
    }
    return true
  }

  const walletsInfo = (id, userData) => {
    axios.get(`${process.env.REACT_APP_API_V3_URL}/wallets/details?user_id=${id}`)
    .then((response) => {
      setUser({
        ...userData,
        points: response?.data?.unallocated_balance,
        balance: response?.data?.balance
      })
    }).catch((error) => {
      toast.error(error.response.data.message);
    })
  }
  
  return (
    <KTCard>
      {(haveAccess(currentUser, 'user_details')) ?
        <KTCardBody>
          <div className="row mb-10">
            <div className="col-lg-2 pl-4">
              <div
                className="symbol symbol-circle symbol-50px overflow-hidden me-3"
                style={{
                  width: "100px",
                  height: "100px",
                  zIndex: "9"
                }}
              >
                {user?.avatar ? (
                  <div className="w-100 h-100">
                    <img
                      src={process.env.REACT_APP_IMAGE_URL + "300:200:" + user?.avatar?.slice(1)}
                      className="w-100 h-100"
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "30px",
                      backgroundColor: "#ffa459",
                      color: "#fff",
                      fontWeight: "bold"
                    }}
                  >
                    {user?.first_name?.charAt(0)}
                    {user?.last_name?.charAt(0)}
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-10 p-0 pt-4">
              <div className="row">
                <div className="col-lg-11">
                  <div className="row">
                    <div className="col-lg-9">
                      <h1 className="heading-title">
                        Hello, I'm {user?.first_name} {user?.last_name}
                      </h1>
                      <div className="my-2">
                        <h4>Member Since</h4>
                        <h3 style={{ color: "#ffa459" }}>{moment(user?.createdAt).format("MMMM DD, YYYY")}</h3>
                      </div>
                      <div className="my-2">
                        <h4>Member Number</h4>
                        <h3 style={{ color: "#ffa459" }}>{user?.public_id}</h3>
                      </div>
                      {user?.is_b2b && <>
                        <div className="my-2">
                          <h4>Organization Name</h4>
                          <h3 style={{ color: "#ffa459", cursor: "pointer", width: 'max-content' }}
                            className="hover-text"
                            onClick={() => haveAccess(currentUser, 'org_list') && navigate(`/organizations?search=${user?.org_name}`)}
                          >{user?.org_name}</h3>
                        </div>
                        <div className="my-2">
                          <h4>Role in Organization</h4>
                          <h3 style={{ color: "#ffa459" }}>{orgRole(user?.org_role)}</h3>
                        </div>
                      </>
                      }
                      {walletsEnabled && <>
                        <div className="my-2">
                          <h4>Cash Wallet Funds
                         {haveAccess(currentUser, 'wallet_restrict') && <span className={`btn btn-sm px-3 py-1 mx-2 ${user?.is_wallet_active ? 'btn-danger' : 'btn-success'}`} onClick={() => setOpenFreezeWalletModal(true)}>{user?.is_wallet_active ? 'Freeze' : 'Unfreeze'} Wallet</span>}
                          </h4>
                          <h3 style={{ color: "#ffa459" }}>{user?.points ? `${formatPrice(user?.points)}` : '$0'}
                          </h3>
                        </div>
                      </>
                      }
                      <div className="my-2">
                        <h4>Marketing Commission</h4>
                        <h3 style={{ color: "#ffa459" }}>{user?.advocate_fees ? `${user?.advocate_fees}%` : '-'}</h3>
                      </div>
                      <div className="my-2">
                        <h4>Stripe Account ID
                        {user?.stripe_connect && haveAccess(currentUser, 'user_reset_stripe') && isAllPendingOrDraft(user?.listings) && <span className="btn btn-sm btn-danger px-3 py-1 mx-2" onClick={() => setOpenResetStripeConfirmationModal(true)}>Reset Stripe</span>}
                        </h4>
                        <h3
                          style={{
                            color: "#2ecc71",
                            cursor: user?.stripe_connect ? "pointer" : "default",
                            pointerEvents: user?.stripe_connect ? "auto" : "none"
                          }}
                          onClick={() => {
                            if (isProd) {
                              window.open(
                                `https://dashboard.stripe.com/applications/users/${user?.stripe_connect}`,
                                "_blank"
                              );
                            } else {
                              window.open(
                                `https://dashboard.stripe.com/test/applications/users/${user?.stripe_connect}`,
                                "_blank"
                              );
                            }
                          }}
                        >
                          {user?.stripe_connect || "-"}
                        </h3>
                      </div>
                      <div className="my-2">
                        <h4>Stripe Customer ID</h4>
                        <h3
                          style={{
                            color: "#2ecc71",
                            cursor: user?.stripe_customer ? "pointer" : "default",
                            pointerEvents: user?.stripe_customer ? "auto" : "none"
                          }}
                          onClick={() => {
                            if (isProd) {
                              window.open(
                                `https://dashboard.stripe.com/customers/${user?.stripe_customer}`,
                                "_blank"
                              );
                            } else {
                              window.open(
                                `https://dashboard.stripe.com/test/customers/${user?.stripe_customer}`,
                                "_blank"
                              );
                            }
                          }}
                        >
                          {user?.stripe_customer || "-"}
                        </h3>
                      </div>
                      <div className="my-2">
                        <h4>User ID</h4>
                        <h3 style={{ color: "#ffa459" }}>{user?.uid}</h3>
                      </div>
                      <div className="my-2">
                        <h4>Email Address</h4>
                        <h3 style={{ color: "#ffa459" }}>
                          {" "}
                          {user?.email}{" "}
                        </h3>
                      </div>
                      <div className="my-2">
                        <h4>Status</h4>
                        <h3 style={{ color: user?.disabled ? "#3f4254" : "#50cd89" }}>
                          {" "}
                          {user?.disabled === false
                            ? "Active"
                            : user?.disabled === true && "Inactive"}
                        </h3>
                      </div>
                      <div className="my-2">
                        <h4>Phone Number</h4>
                        <h3 style={{ color: "#ffa459" }}>
                          {user?.phone ? formatPhone(user?.phone) : "-"}
                        </h3>
                      </div>
                      <div className="my-2">
                        <h4>Billing Phone Number</h4>
                        <h3 style={{ color: "#ffa459" }}>
                          {user?.billing_phone ? formatPhone(user?.billing_phone) : "-"}
                        </h3>
                      </div>
                      <div className="my-2">
                        <h4>Company</h4>
                        <h3 style={{ color: "#ffa459" }}>{user?.company || "-"}</h3>
                      </div>
                      <div className="my-2">
                        <h4>Bio</h4>
                        <h3 style={{ color: "#ffa459" }}>
                          {user?.description || "-"}
                        </h3>
                      </div>
                      <div className="my-2">
                        <h4>Mailing Address</h4>
                        <h3 style={{ color: "#ffa459" }}>{user?.mailing_address || "-"}</h3>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      {(haveAccess(currentUser, 'user_edit')) && <button
                        type="button"
                        className="btn w-100"
                        style={{
                          background: "#ffa459",
                          color: "#fff",
                        }}
                        onClick={() => setShowEditModal(true)}
                      >
                        Edit User Profile
                      </button>
                      }
                      {(haveAccess(currentUser, 'user_login_as')) && <button
                        type="button"
                        className="btn w-100 mt-4"
                        style={{
                          background: "#2ecc71",
                          color: "#fff",
                        }}
                        onClick={loginAsUser}
                      >
                        Login as User
                      </button>
                      }
                      {(haveAccess(currentUser, 'user_logout')) && <button
                        type="button"
                        className="btn w-100 mt-4"
                        style={{
                          background: "#ed2626",
                          color: "#fff",
                        }}
                        onClick={logOutUser}
                      >
                        Logout User Session
                      </button>
                      }
                      {(haveAccess(currentUser, 'user_edit')) && !user?.is_restricted && (user?.is_b2b ? user?.org_role < 3 : !user?.is_b2b) && <button
                        type="button"
                        className="btn w-100 mt-4"
                        style={{
                          background: "#ffa459",
                          color: "#fff",
                        }}
                        onClick={() => setOpenAdvocateModal(true)}
                      >
                        Change Marketing Commission
                      </button>
                      }
                      {walletsEnabled && haveAccess(currentUser, 'cash_wallet') && <button
                        type="button"
                        className="btn w-100 mt-4"
                        style={{
                          background: "#ffa459",
                          color: "#fff",
                        }}
                        onClick={() => setOpenPointsModal(true)}
                      >
                        Cash Wallet
                      </button>
                      }
                      {(haveAccess(currentUser, 'user_create')) && <a
                        href={`https://app.hubspot.com/contacts/23843438/objects/0-1/views/all/list?query=${user?.email}`}
                        target="_blank"
                        type="button"
                        className="btn w-100 mt-4"
                        style={{
                          background: "#2ecc71",
                          color: "#fff",
                        }}
                      >
                        Go to Hubspot
                      </a>
                      }
                      {(haveAccess(currentUser, 'user_email_create')) && <button
                        type="button"
                        className="btn w-100 mt-4"
                        style={{
                          background: "#2ecc71",
                          color: "#fff",
                        }}
                        onClick={() => sendMessage("email")}
                      >
                        Send Email
                      </button>
                      }
                      {(haveAccess(currentUser, 'user_sms_create')) && <button
                        type="button"
                        className="btn w-100 mt-4"
                        style={{
                          background: "#2ecc71",
                          color: "#fff",
                        }}
                        onClick={() => sendMessage("SMS")}
                      >
                        Send SMS
                      </button>
                      }
                      {(haveAccess(currentUser, 'user_ban_create')) && !user?.is_restricted && <button
                        type="button"
                        className="btn w-100 mt-4"
                        style={{
                          background: "#ed2626",
                          color: "#fff",
                        }}
                        onClick={() => sendMessage("ban")}
                      >
                        Ban User
                      </button>
                      }
                      {(user?.is_b2b ? user?.org_role < 3 : !user?.is_b2b) && <button
                        type="button"
                        className="btn w-100 mt-4 disable_api_btn"
                        style={{
                          background: "#ffa459",
                          color: "#fff",
                        }}
                        disabled={user?.has_apis}
                        onClick={() => enableApi()}
                      >
                        {user?.has_apis && <i className="fas fa-check" style={{ color: '#fff' }} />} {!user?.has_apis && 'Enable'} API Access
                      </button>
                      }
                      <button
                        type="button"
                        className="btn w-100 mt-4 disable_api_btn"
                        style={{
                          background: "#2ecc71",
                          color: "#fff",
                          padding: '12px'
                        }}
                        disabled={user?.is_b2b}
                        onClick={() => createOrg()}
                      >
                        {user?.is_b2b && <i className="fas fa-check" style={{ color: '#fff' }} />} {!user?.is_b2b ? 'Create Organization' : 'Organization Created'}
                      </button>
                    </div>
                  </div>

                  <div className="separator separator-dashed my-5" />

                  {(haveAccess(currentUser, 'listing_list')) && <div>
                    <div
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <h2>Spaces ({user?.listings?.filter((item) => item.status !== -1).length})</h2>
                      {(haveAccess(currentUser, 'listing_create')) &&
                        (user?.is_b2b ? user?.org_role < 3 : !user?.is_b2b) &&
                        (
                          <button
                            type="button"
                            className="btn"
                            style={{
                              background: "#2ecc71",
                              color: "#fff"
                            }}
                            onClick={() => setShowListingModal(true)}
                          >
                            Add New Listing
                          </button>
                        )}
                    </div>
                    {user && user?.listings?.filter((item) => item.status !== -1).length > 0 ? (
                      <div className="row g-10 mt-1 listing-wrapper">
                        {user?.listings?.filter((item) => item.status !== -1).map((listing) => (
                          <div className="col-12 col-md-6 col-lg-6 position-relative">
                            <div className="card card-custom position-relative">
                              {showStatusBadge(listing.status) && (
                                <span
                                  className={`badge badge-${getStatusBadgeText(listing.status)?.colorClass} fw-bolder position-absolute fs-6 rounded-0`}
                                  style={{
                                    width: "100%",
                                    zIndex: 2,
                                    height: "30px",
                                  }}
                                >
                                  {getStatusBadgeText(listing.status)?.text}
                                </span>
                              )}
                              {listing.avatar ? (
                                <div style={{ position: "relative", width: '100%' }}>
                                  <img
                                    src={
                                      (listing.avatar
                                        ? process.env.REACT_APP_IMAGE_URL + "300:200:" +
                                        listing?.avatar.slice(1)
                                        : listing.images &&
                                        listing.images?.length > 0 &&
                                        process.env.REACT_APP_IMAGE_URL + "300:200:" +
                                        listing?.images[0]?.file.slice(1))
                                    }
                                    style={{ cursor: "pointer" }}
                                    width={'100%'}
                                    height={250}
                                    alt=""
                                    onClick={() => {
                                      navigate(`/listings/${listing.listing_id}`);
                                    }}
                                  />
                                  {listing.status == 3 && listing.disable_reason && <div
                                    className="d-flex justify-content-center align-items-center fs-5 text-white position-absolute"
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      background: "rgba(0,0,0,0.75)",
                                      padding: "10px",
                                      top: 0,
                                      left: 0,
                                    }}
                                    onClick={() => {
                                      (haveAccess(currentUser, "listing_details")) &&
                                        navigate(`/listings/${listing.listing_id}`);
                                    }}
                                  >
                                    <p> Disabled Reason: {listing.disable_reason}
                                    </p>
                                  </div>
                                  }
                                  {listing.status == 5 && listing.reject_reason && <div
                                    className="d-flex justify-content-center align-items-center fs-6 text-white position-absolute"
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      background: "rgba(0,0,0,0.75)",
                                      padding: "10px",
                                      top: 0,
                                      left: 0,
                                    }}
                                    onClick={() => {
                                      (haveAccess(currentUser, "listing_details")) &&
                                        navigate(`/listings/${listing.listing_id}`);
                                    }}
                                  >
                                    <p> Rejected Reason: {getRejectReason(listing.reject_reason)}
                                    </p>
                                  </div>
                                  }
                                </div>
                              ) : (
                                <div
                                  style={{
                                    width: "100%",
                                    height: "250px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    background: "gray",
                                    color: "#fff",
                                    fontSize: "16px",
                                    cursor: "pointer"
                                  }}
                                  onClick={() => {
                                    navigate(`/listings/${listing.listing_id}`);
                                  }}
                                >
                                  No Images Available Yet
                                </div>
                              )}
                              <div
                                className="row pt-4"
                                style={{ height: "auto" }}
                              >
                                <h4
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    navigate(`/listings/${listing?.listing_id}`);
                                  }}
                                >
                                  {listing?.title}
                                </h4>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between"
                                  }}
                                >
                                  {listing.seats ? (
                                    <h6 style={{ color: "#ffa459" }}>
                                      {listing?.seats || 0} Spaces Available
                                    </h6>
                                  ) : (
                                    <h6 style={{ color: "#ffa459" }}>No Spaces added yet</h6>
                                  )}
                                  <h6 style={{ color: "#ffa459" }}>
                                    {listing?.signs || 0} Signs
                                  </h6>
                                </div>
                                {listing?.prices && listing?.prices.length > 0 ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      gap: "10px"
                                    }}
                                  >
                                    {getListingPrices(listing, listing?.prices?.find((price) => price.vehicle_type > 0)?.vehicle_type ? listing?.prices.find((price) => price.vehicle_type > 0)?.vehicle_type : 0)
                                      .sort((a, b) => a.plan_duration - b.plan_duration)
                                      .map((price) => price.price > 0 && price?.plan_duration !== 5 && (
                                        <div
                                          style={{
                                            fontSize: "16px",
                                            display: "flex",
                                            flexDirection: "column"
                                          }}
                                        >
                                          <span
                                            style={{
                                              color: "#ffa459",
                                              fontWeight: "700",
                                              fontSize: "18px"
                                            }}
                                          >
                                            ${(price.price / 100).toFixed(2)}
                                          </span>{" "}
                                          <span style={{ fontSize: "12px" }}>
                                            per {duration(price?.plan_duration)}
                                          </span>
                                        </div>
                                      ))}
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      color: "#ffa459",
                                      fontWeight: "700",
                                      fontSize: "15px"
                                    }}
                                  >
                                    No Prices Added Yet
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="row mt-10">
                        <h5>No Listings Yet</h5>
                      </div>
                    )}
                  </div>
                  }
                  <div className="separator separator-dashed my-7" />
                  {(haveAccess(currentUser, 'vehicle_list')) && <div>
                    <div
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <h2>Vehicles ({user?.vehicles?.length})</h2>
                      {(haveAccess(currentUser, 'vehicle_create')) &&
                        (user?.is_b2b ? user?.org_role < 4 : !user?.is_b2b) &&
                        (
                          <button
                            type="button"
                            className="btn"
                            style={{
                              background: "#2ecc71",
                              color: "#fff"
                            }}
                            onClick={() => setShowVehicleModal(true)}
                          >
                            Add New Vehicle
                          </button>
                        )}
                    </div>
                    {user?.vehicles && user?.vehicles?.length > 0 ? (
                      <div className="row g-5 mt-1 vehicle-wrapper">
                        {user?.vehicles &&
                          user?.vehicles?.map((vehicle) => {
                            return (
                              <div className="col-12 col-md-6 col-lg-6 pt-3">
                                <div
                                  className="card card-custom g-10"
                                  style={{
                                    border: "1px solid #b2b2b2",
                                    padding: "0 10px",
                                    background: "rgba(244,244,244,0.4)",
                                    borderRadius: "10px"
                                  }}
                                >
                                  {(haveAccess(currentUser, 'vehicle_edit')) && <span
                                    style={{
                                      position: "absolute",
                                      background: "#ffa459",
                                      width: "40px",
                                      height: "40px",
                                      borderRadius: "50%",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      right: "-10px",
                                      top: "-10px",
                                      cursor: "pointer"
                                    }}
                                    onClick={() => {
                                      setVehicle(vehicle);
                                      setShowVehicleModal(true);
                                    }}
                                  >
                                    <i
                                      className="fas fa-pencil"
                                      style={{ color: "#fff" }}
                                    />
                                  </span>
                                  }
                                  <div
                                    className="row pt-4"
                                    style={{ height: "auto" }}
                                  >
                                    <h4
                                      style={{
                                        textAlign: "center",
                                        fontSize: "22px",
                                        marginBottom: "20px"
                                      }}
                                    >
                                      {`
                                      ${vehicle.vehicle_type === 1 ? "Truck" : vehicle.vehicle_type === 2 ? "Trailer" : "Truck + trailer"}
                                      ${vehicle.vehicle_type === 1 ? vehicle?.truck_number : vehicle?.vehicle_type === 2 ? vehicle?.trailer_number : `${vehicle?.truck_number}/${vehicle?.trailer_number}`}
                                    `}
                                    </h4>
                                    <div className="row">
                                      <div className="fv-row mb-3 col-6">
                                        <h5 className="fw-bold fs-6 mb-2">
                                          Kind of Vehicle
                                        </h5>
                                        <h4 style={{ color: "#ffa459" }}>
                                          {vehicle.vehicle_type == 1
                                            ? "Truck"
                                            : vehicle.vehicle_type == 2
                                              ? "Trailer"
                                              : "Truck + Trailer"}
                                        </h4>
                                      </div>
                                      {vehicle.vehicle_type !== 2 && (
                                        <div className="fv-row mb-3 col-6">
                                          <h5 className="fw-bold fs-6 mb-2">
                                            Company Name
                                          </h5>
                                          <h3 style={{ color: "#ffa459" }}>
                                            {vehicle.name}
                                          </h3>
                                        </div>
                                      )}
                                      {vehicle.vehicle_type === 2 && (
                                        <div className="fv-row mb-3 col-6">
                                          <h5 className="fw-bold fs-6 mb-2">
                                            Trailer Type
                                          </h5>
                                          <h4 style={{ color: "#ffa459" }}>
                                            {vehicle.model || "Not Available"}
                                          </h4>
                                        </div>
                                      )}
                                    </div>

                                    {vehicle.vehicle_type !== 2 && (
                                      <div className="row">
                                        <div className="fv-row mb-3 col-6">
                                          <h5 className="fw-bold fs-6 mb-2">
                                            Truck #
                                          </h5>
                                          <h4 style={{ color: "#ffa459" }}>
                                            {vehicle.truck_number ||
                                              "Not Available"}
                                          </h4>
                                        </div>
                                        <div className="fv-row mb-3 col-6">
                                          <h5 className="fw-bold fs-6 mb-2">
                                            Truck Make
                                          </h5>
                                          <h4 style={{ color: "#ffa459" }}>
                                            {vehicle.make || "Not Available"}
                                          </h4>
                                        </div>
                                      </div>
                                    )}

                                    <div className="row">
                                      {vehicle.vehicle_type !== 2 && (
                                        <div className="fv-row mb-3 col-6">
                                          <h5 className="fw-bold fs-6 mb-2">
                                            Truck Plate #
                                          </h5>
                                          <h4 style={{ color: "#ffa459" }}>
                                            {vehicle.plate_number ||
                                              "Not Available"}
                                          </h4>
                                        </div>
                                      )}
                                      {vehicle.vehicle_type === 3 && (
                                        <div className="fv-row mb-3 col-6">
                                          <h5 className="fw-bold fs-6 mb-2">
                                            Trailer Type
                                          </h5>
                                          <h4 style={{ color: "#ffa459" }}>
                                            {vehicle.model || "Not Available"}
                                          </h4>
                                        </div>
                                      )}
                                    </div>

                                    <div className="row">
                                      {vehicle.vehicle_type !== 1 && (
                                        <div className="fv-row mb-3 col-6">
                                          <h5 className="fw-bold fs-6 mb-2">
                                            Trailer #
                                          </h5>
                                          <h4 style={{ color: "#ffa459" }}>
                                            {vehicle.trailer_number ||
                                              "Not Available"}
                                          </h4>
                                        </div>
                                      )}
                                      {vehicle.vehicle_type == 2 && (
                                        <div className="fv-row mb-3 col-6">
                                          <h5 className="fw-bold fs-6 mb-2">
                                            Company Name
                                          </h5>
                                          <h4 style={{ color: "#ffa459" }}>
                                            {vehicle.name ||
                                              "-"}
                                          </h4>
                                        </div>
                                      )}
                                      {vehicle.vehicle_type !== 1 && (
                                        <div className="fv-row mb-3 col-6">
                                          <h5 className="fw-bold fs-6 mb-2">
                                            Trailer Plate #
                                          </h5>
                                          <h4 style={{ color: "#ffa459" }}>
                                            {vehicle.trailer_plate ||
                                              "Not Available"}
                                          </h4>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    ) : (
                      <div className="row mt-10">
                        <h5>No Vehicles Yet</h5>
                      </div>
                    )}
                  </div>
                  }
                  {/* bookings */}
                  <div className="separator separator-dashed my-7" />
                  {(haveAccess(currentUser, 'booking_list')) && <div>
                    <div
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <h2 style={{ color: "#ffa459" }}>
                        Bookings
                      </h2>
                    </div>
                    <div
                      className="booking-type-header"
                      style={{
                        display: "flex",
                        marginTop: "5px"
                      }}
                    >
                      <button
                        style={{
                          border: "none",
                          background: "transparent",
                          borderBottom:
                            selectedBooking === "progress"
                              ? "3px solid #ffa459"
                              : "none",
                          padding: "5px 2px",
                          fontWeight: 600,
                          color: "#ffa459"
                        }}
                        onClick={() => {
                          setSelectedBooking("progress");
                          setBookingsPage(1);
                        }}
                      >
                        Active (
                        {bookingsCount['ongoing'] || 0}
                        )
                      </button>
                      <button
                        style={{
                          border: "none",
                          background: "transparent",
                          borderBottom:
                            selectedBooking === "not-renewing"
                              ? "3px solid #5e6278"
                              : "none",
                          padding: "5px 2px",
                          fontWeight: 600,
                          color: "#5e6278"
                        }}
                        onClick={() => {
                          setSelectedBooking("not-renewing");
                          setBookingsPage(1);
                        }}
                      >
                        Active & Not Renewing (
                        {bookingsCount[8] ||
                          0}
                        )
                      </button>
                      <button
                        style={{
                          border: "none",
                          background: "transparent",
                          borderBottom:
                            selectedBooking === "upcoming"
                              ? "3px solid #009ef7"
                              : "none",
                          padding: "5px 2px",
                          fontWeight: 600,
                          color: "#009ef7"
                        }}
                        onClick={() => {
                          setSelectedBooking("upcoming");
                          setBookingsPage(1);
                        }}
                      >
                        Upcoming (
                        {bookingsCount['upcoming'] ||
                          0}
                        )
                      </button>
                      <button
                        style={{
                          border: "none",
                          background: "transparent",
                          borderBottom:
                            selectedBooking === "completed"
                              ? "3px solid #2ecc71"
                              : "none",
                          padding: "5px 2px",
                          fontWeight: 600,
                          color: "#2ecc71"
                        }}
                        onClick={() => {
                          setSelectedBooking("completed");
                          setBookingsPage(1);
                        }}
                      >
                        Completed (
                        {(bookingsCount[6] ? bookingsCount[6] : 0) + (bookingsCount[12] ? bookingsCount[12] : 0)}
                        )
                      </button>
                      <button
                        style={{
                          border: "none",
                          background: "transparent",
                          borderBottom:
                            selectedBooking === "cancelled"
                              ? "3px solid #ed2626"
                              : "none",
                          padding: "5px 2px",
                          fontWeight: 600,
                          color: "#ed2626"
                        }}
                        onClick={() => {
                          setSelectedBooking("cancelled");
                          setBookingsPage(1);
                        }}
                      >
                        Cancelled (
                        {bookingsCount?.cancelled ||
                          0}
                        )
                      </button>
                      <button
                        style={{
                          border: "none",
                          background: "transparent",
                          borderBottom:
                            selectedBooking === "past_due"
                              ? "3px solid #ed2626"
                              : "none",
                          padding: "5px 2px",
                          fontWeight: 600,
                          color: "#ed2626"
                        }}
                        onClick={() => {
                          setSelectedBooking("past_due");
                          setBookingsPage(1);
                        }}
                      >
                        Past Due (
                        {bookingsCount[11] ||
                          0}
                        )
                      </button>
                    </div>
                    {bookingsLoading && <UsersListLoading />}
                    {bookings && !bookingsLoading &&
                      bookings.length > 0 ? (
                      <div className="row g-5 mt-1 booking-wrapper">
                        {bookings &&
                          bookings?.map((item) => {
                            return (
                              <div className="col-12 col-md-6 col-lg-6 pt-3">
                                <div
                                  className="card card-custom g-10"
                                  style={{
                                    border: "1px solid #b2b2b2",
                                    padding: "0 10px",
                                    background: "rgba(244,244,244,0.4)",
                                    borderRadius: "10px"
                                  }}
                                >
                                  <div
                                    className="row pt-4"
                                    style={{ height: "auto" }}
                                  >
                                    <h4
                                      className="booking_title"
                                      style={{
                                        color: getColorByBookingStatus(selectedBooking)
                                      }}
                                      onClick={() =>
                                        navigate(`/listings/${item.listing_id}`)
                                      }
                                    >
                                      {item.listing_name}
                                    </h4>
                                    <div className="row">
                                      <div className="fv-row mb-2 col-6">
                                        <h5 className="fw-bold fs-6 mb-2">
                                          Reservation Code
                                        </h5>
                                        <Link
                                          to={`/bookings?search_type=reservation_code&search=${item.reservation_code}`}
                                        // state={{ reservationNumber: item.reservation_code }}
                                        >
                                          <h4
                                            style={{
                                              color: getColorByBookingStatus(selectedBooking)
                                            }}
                                          >
                                            {item.reservation_code?.toUpperCase()}
                                          </h4>
                                        </Link>
                                      </div>
                                      <div className="fv-row mb-2 col-6">
                                        <h5 className="fw-bold fs-6 mb-2">
                                          Clock In
                                        </h5>
                                        <h4
                                          style={{
                                            color: getColorByBookingStatus(selectedBooking)
                                          }}
                                        >
                                          {moment(
                                            item?.clock_in,
                                            "hh:mm"
                                          ).format("LT")}
                                        </h4>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="fv-row mb-3 col-6">
                                        <h5 className="fw-bold fs-6 mb-2">
                                          Start Date
                                        </h5>
                                        <h4
                                          style={{
                                            color: getColorByBookingStatus(selectedBooking)
                                          }}
                                        >
                                          {convertUTCTimeToListingTime(item.start_date, item.timezone_id, 0, "DD MMM, YYYY")}
                                        </h4>
                                      </div>
                                      <div className="fv-row mb-3 col-6">
                                        <h5 className="fw-bold fs-6 mb-2">
                                          End Date
                                        </h5>
                                        <h4
                                          style={{
                                            color: getColorByBookingStatus(selectedBooking)
                                          }}
                                        >
                                          {convertUTCTimeToListingTime(item.end_date, item.timezone_id, 1, "DD MMM, YYYY")}
                                        </h4>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="fv-row mb-3 col-6">
                                        <h5 className="fw-bold fs-6 mb-2">
                                          Property Member
                                        </h5>
                                        <h4
                                          style={{
                                            color: getColorByBookingStatus(selectedBooking)
                                          }}
                                        >
                                          {item.owner_name || "-"}
                                        </h4>
                                      </div>
                                      <div className="fv-row mb-3 col-6">
                                        <h5 className="fw-bold fs-6 mb-2">
                                          Member Number
                                        </h5>
                                        <h4
                                          style={{
                                            color: getColorByBookingStatus(selectedBooking)
                                          }}
                                        >
                                          {item.owner_public_id || "-"}
                                        </h4>
                                      </div>
                                    </div>
                                    <div className="row">
                                      {+item.booking_type !== 1 && (
                                        <div className="fv-row mb-3 col-6">
                                          <h5 className="fw-bold fs-6 mb-2">
                                            Seats
                                          </h5>
                                          <h4
                                            style={{
                                              color: getColorByBookingStatus(selectedBooking)
                                            }}
                                          >
                                            {item.seats || "-"}
                                          </h4>
                                        </div>
                                      )}
                                      {+item.booking_type === 1 && (
                                        <div className="fv-row mb-3 col-6">
                                          <h5 className="fw-bold fs-6 mb-2">
                                            Hours
                                          </h5>
                                          <h4
                                            style={{
                                              color: getColorByBookingStatus(selectedBooking)
                                            }}
                                          >
                                            {moment(item.end_date).add(1, "minutes").diff(moment(item.start_date), "hours") || "-"}
                                          </h4>
                                        </div>
                                      )}
                                      {selectedBooking === "completed" && <div className="fv-row mb-3 col-6">
                                        <h5 className="fw-bold fs-6 mb-2">
                                          Status
                                        </h5>
                                        <h4
                                          style={{
                                            color: getColorByBookingStatus(item.status)
                                          }}
                                        >
                                          {item.status == 12 ? 'Suspended' : 'Completed'}
                                        </h4>
                                      </div>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    ) : !bookingsLoading && (
                      <div className="row mt-10">
                        <h5>
                          {" "}
                          No{" "}
                          {selectedBooking === "upcoming"
                            ? "Upcomings"
                            : selectedBooking === "progress"
                              ? "Active"
                              : selectedBooking === "cancelled"
                                ? "Cancelled"
                                : selectedBooking === "not-renewing" ?
                                  "Active & Not Renewing"
                                  : selectedBooking === "past_due" ?
                                    "Past Due"
                                    : "Completed"}{" "}
                          Bookings Yet
                        </h5>
                      </div>
                    )}
                    <div className="row mt-5">
                      <div
                        className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                          <select
                            className="form-select form-select-solid fw-bolder"
                            data-kt-select2="true"
                            data-placeholder="Select Status"
                            data-allow-clear="true"
                            data-kt-user-table-filter="two-step"
                            data-hide-search="true"
                            onChange={(e) => setBookingsPageSize(e.target.value)}
                            value={bookingsPageSize}
                          >
                            <option value="30">30</option>
                            <option value="60">60</option>
                            <option value="90">90</option>
                            <option value="120">120</option>
                          </select>
                        </div>
                      </div>
                      <div
                        className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-lg-end">
                        <div id="kt_table_users_paginate">
                          <ul className="pagination">
                            {bookingsPage > 1 && <li className={clsx("page-item", "previous")}>
                              <button
                                className={clsx("page-link", "next", "page-text")}
                                onClick={() => updateBookingsPage("dec")}
                                style={{ cursor: "pointer" }}
                                disabled={bookingsPage === 1}
                              >
                                Previous Page
                              </button>
                            </li>
                            }
                            <div style={{ display: "flex", gap: "5px", fontWeight: 700, fontSize: "14px" }}>
                              <span
                                style={{ color: "#ffa459" }}>{bookingsPage}</span>/ {Math.ceil(totalBookings / bookingsPageSize)}
                            </div>
                            <li className={clsx("page-item", "previous")}>
                              <button
                                className={clsx("page-link")}
                                onClick={() => updateBookingsPage("inc")}
                                style={{
                                  cursor: bookingsPage !== Math.ceil(totalBookings / bookingsPageSize) ? "pointer" : "default",
                                  background: bookingsPage !== Math.ceil(totalBookings / bookingsPageSize) ? "transparent" : "#e6e6e6",
                                  color: bookingsPage !== Math.ceil(totalBookings / bookingsPageSize) ? "#5e6278" : "#b2b2b2",
                                  marginLeft: bookingsPage === Math.ceil(totalBookings / bookingsPageSize) && "10px"
                                }}
                              >
                                Next Page
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  }
                  {/* hosted */}
                  <div className="separator separator-dashed my-7" />
                  {(haveAccess(currentUser, 'booking_list')) && <div>
                    <div
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <h2 style={{ color: "#2ecc71" }}>
                        Hosted Bookings
                      </h2>
                    </div>
                    <div
                      className="booking-type-header"
                      style={{
                        display: "flex",
                        // gap: "50px",
                        marginTop: "5px"
                      }}
                    >
                      <button
                        style={{
                          border: "none",
                          background: "transparent",
                          borderBottom:
                            selectedBookingHosted === "progress"
                              ? "3px solid #ffa459"
                              : "none",
                          padding: "5px 2px",
                          fontWeight: 600,
                          color: "#ffa459"
                        }}
                        onClick={() => {
                          setSelectedBookingHosted("progress");
                          setBookingsHostedPage(1);
                        }}
                      >
                        Active (
                        {hostedBookingsCount['ongoing'] || 0}
                        )
                      </button>
                      <button
                        style={{
                          border: "none",
                          background: "transparent",
                          borderBottom:
                            selectedBookingHosted === "not-renewing"
                              ? "3px solid #5e6278"
                              : "none",
                          padding: "5px 2px",
                          fontWeight: 600,
                          color: "#5e6278"
                        }}
                        onClick={() => {
                          setSelectedBookingHosted("not-renewing");
                          setBookingsHostedPage(1);
                        }}
                      >
                        Active & Not Renewing (
                        {hostedBookingsCount[8] ||
                          0}
                        )
                      </button>
                      <button
                        style={{
                          border: "none",
                          background: "transparent",
                          borderBottom:
                            selectedBookingHosted === "upcoming"
                              ? "3px solid #009ef7"
                              : "none",
                          padding: "5px 2px",
                          fontWeight: 600,
                          color: "#009ef7"
                        }}
                        onClick={() => {
                          setSelectedBookingHosted("upcoming");
                          setBookingsHostedPage(1);
                        }}
                      >
                        Upcoming (
                        {hostedBookingsCount['upcoming'] ||
                          0}
                        )
                      </button>
                      <button
                        style={{
                          border: "none",
                          background: "transparent",
                          borderBottom:
                            selectedBookingHosted === "completed"
                              ? "3px solid #2ecc71"
                              : "none",
                          padding: "5px 2px",
                          fontWeight: 600,
                          color: "#2ecc71"
                        }}
                        onClick={() => {
                          setSelectedBookingHosted("completed");
                          setBookingsHostedPage(1);
                        }}
                      >
                        Completed (
                        {(hostedBookingsCount[6] ? hostedBookingsCount[6] : 0) + (hostedBookingsCount[12] ? hostedBookingsCount[12] : 0)}
                        )
                      </button>
                      <button
                        style={{
                          border: "none",
                          background: "transparent",
                          borderBottom:
                            selectedBookingHosted === "cancelled"
                              ? "3px solid #ed2626"
                              : "none",
                          padding: "5px 2px",
                          fontWeight: 600,
                          color: "#ed2626"
                        }}
                        onClick={() => {
                          setSelectedBookingHosted("cancelled");
                          setBookingsHostedPage(1);
                        }}
                      >
                        Cancelled (
                        {hostedBookingsCount?.cancelled ||
                          0}
                        )
                      </button>
                      <button
                        style={{
                          border: "none",
                          background: "transparent",
                          borderBottom:
                            selectedBookingHosted === "past_due"
                              ? "3px solid #ed2626"
                              : "none",
                          padding: "5px 2px",
                          fontWeight: 600,
                          color: "#ed2626"
                        }}
                        onClick={() => {
                          setSelectedBookingHosted("past_due");
                          setBookingsHostedPage(1);
                        }}
                      >
                        Past Due (
                        {hostedBookingsCount[11] ||
                          0}
                        )
                      </button>
                    </div>
                    {hostedBookingsLoading && <UsersListLoading />}
                    {bookingsHosted && !hostedBookingsLoading &&
                      bookingsHosted.length > 0 ? (
                      <div className="row g-5 mt-1 booking-wrapper">
                        {bookingsHosted &&
                          bookingsHosted?.map((item) => {
                            return (
                              <div className="col-12 col-md-6 col-lg-6 pt-3">
                                <div
                                  className="card card-custom g-10"
                                  style={{
                                    border: "1px solid #b2b2b2",
                                    padding: "0 10px",
                                    background: "rgba(244,244,244,0.4)",
                                    borderRadius: "10px"
                                  }}
                                >
                                  <div
                                    className="row pt-4"
                                    style={{ height: "auto" }}
                                  >
                                    <h4
                                      className="booking_title"
                                      style={{
                                        color: getColorByBookingStatus(selectedBookingHosted)
                                      }}
                                      onClick={() =>
                                        navigate(`/listings/${item.listing_id}`)
                                      }
                                    >
                                      {item.listing_name}
                                    </h4>
                                    <div className="row">
                                      <div className="fv-row mb-2 col-6">
                                        <h5 className="fw-bold fs-6 mb-2">
                                          Reservation Code
                                        </h5>
                                        <Link
                                          to={`/bookings?search_type=reservation_code&search=${item.reservation_code}`}
                                        // state={{ reservationNumber: item.reservation_code }}
                                        >
                                          <h4
                                            style={{
                                              color: getColorByBookingStatus(selectedBookingHosted)
                                            }}
                                          >
                                            {item.reservation_code?.toUpperCase()}
                                          </h4>
                                        </Link>
                                      </div>
                                      <div className="fv-row mb-2 col-6">
                                        <h5 className="fw-bold fs-6 mb-2">
                                          Clock In
                                        </h5>
                                        <h4
                                          style={{
                                            color: getColorByBookingStatus(selectedBookingHosted)
                                          }}
                                        >
                                          {moment(
                                            item?.clock_in,
                                            "hh:mm"
                                          ).format("LT")}
                                        </h4>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="fv-row mb-3 col-6">
                                        <h5 className="fw-bold fs-6 mb-2">
                                          Start Date
                                        </h5>
                                        <h4
                                          style={{
                                            color: getColorByBookingStatus(selectedBookingHosted)
                                          }}
                                        >
                                          {convertUTCTimeToListingTime(item.start_date, item.timezone_id, 0, "DD MMM, YYYY")}
                                        </h4>
                                      </div>
                                      <div className="fv-row mb-3 col-6">
                                        <h5 className="fw-bold fs-6 mb-2">
                                          End Date
                                        </h5>
                                        <h4
                                          style={{
                                            color: getColorByBookingStatus(selectedBookingHosted)
                                          }}
                                        >
                                          {convertUTCTimeToListingTime(item.end_date, item.timezone_id, 1, "DD MMM, YYYY")}
                                        </h4>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="fv-row mb-3 col-6">
                                        <h5 className="fw-bold fs-6 mb-2">
                                          Trucker Member
                                        </h5>
                                        <h4
                                          style={{
                                            color: getColorByBookingStatus(selectedBookingHosted)
                                          }}
                                        >
                                          {item.customer_name || "-"}
                                        </h4>
                                      </div>
                                      <div className="fv-row mb-3 col-6">
                                        <h5 className="fw-bold fs-6 mb-2">
                                          Member Number
                                        </h5>
                                        <h4
                                          style={{
                                            color: getColorByBookingStatus(selectedBookingHosted)
                                          }}
                                        >
                                          {item.customer_public_id || "-"}
                                        </h4>
                                      </div>
                                    </div>
                                    <div className="row">
                                      {+item.booking_type !== 1 && (
                                        <div className="fv-row mb-3 col-6">
                                          <h5 className="fw-bold fs-6 mb-2">
                                            Seats
                                          </h5>
                                          <h4
                                            style={{
                                              color: getColorByBookingStatus(selectedBookingHosted)
                                            }}
                                          >
                                            {item.seats || "-"}
                                          </h4>
                                        </div>
                                      )}
                                      {+item.booking_type === 1 && (
                                        <div className="fv-row mb-3 col-6">
                                          <h5 className="fw-bold fs-6 mb-2">
                                            Hours
                                          </h5>
                                          <h4
                                            style={{
                                              color: getColorByBookingStatus(selectedBookingHosted)
                                            }}
                                          >
                                            {moment(item.end_date).add(1, "minutes").diff(moment(item.start_date), "hours") || "-"}
                                          </h4>
                                        </div>
                                      )}
                                      {selectedBookingHosted === "completed" && <div className="fv-row mb-3 col-6">
                                        <h5 className="fw-bold fs-6 mb-2">
                                          Status
                                        </h5>
                                        <h4
                                          style={{
                                            color: getColorByBookingStatus(item.status)
                                          }}
                                        >
                                          {item.status == 12 ? 'Suspended' : 'Completed'}
                                        </h4>
                                      </div>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    ) : !hostedBookingsLoading && (
                      <div className="row mt-10">
                        <h5>
                          {" "}
                          No{" "}
                          {selectedBookingHosted === "upcoming"
                            ? "Upcomings"
                            : selectedBookingHosted === "progress"
                              ? "Active"
                              : selectedBookingHosted === "cancelled"
                                ? "Cancelled"
                                : selectedBookingHosted === "not-renewing" ?
                                  "Active & Not Renewing"
                                  : selectedBookingHosted === "past_due" ?
                                    "Past Due"
                                    : "Completed"}{" "}
                          Hosted Bookings Yet
                        </h5>
                      </div>
                    )}
                    <div className="row mt-5">
                      <div
                        className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                          <select
                            className="form-select form-select-solid fw-bolder"
                            data-kt-select2="true"
                            data-placeholder="Select Status"
                            data-allow-clear="true"
                            data-kt-user-table-filter="two-step"
                            data-hide-search="true"
                            onChange={(e) => setBookingsHostedPageSize(e.target.value)}
                            value={bookingsHostedPageSize}
                          >
                            <option value="30">30</option>
                            <option value="60">60</option>
                            <option value="90">90</option>
                            <option value="120">120</option>
                          </select>
                        </div>
                      </div>
                      <div
                        className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-lg-end">
                        <div id="kt_table_users_paginate">
                          <ul className="pagination">
                            {bookingsHostedPage > 1 && <li className={clsx("page-item", "previous")}>
                              <button
                                className={clsx("page-link", "next", "page-text")}
                                onClick={() => updateHostedBookingsPage("dec")}
                                style={{ cursor: "pointer" }}
                                disabled={bookingsHostedPage === 1}
                              >
                                Previous Page
                              </button>
                            </li>
                            }
                            <div style={{ display: "flex", gap: "5px", fontWeight: 700, fontSize: "14px" }}>
                              <span
                                style={{ color: "#ffa459" }}>{bookingsHostedPage}</span>/ {Math.ceil(totalHostedBookings / bookingsHostedPageSize)}
                            </div>
                            <li className={clsx("page-item", "previous")}>
                              <button
                                className={clsx("page-link")}
                                onClick={() => updateHostedBookingsPage("inc")}
                                style={{
                                  cursor: bookingsHostedPage !== Math.ceil(totalHostedBookings / bookingsHostedPageSize) ? "pointer" : "default",
                                  background: bookingsHostedPage !== Math.ceil(totalHostedBookings / bookingsHostedPageSize) ? "transparent" : "#e6e6e6",
                                  color: bookingsHostedPage !== Math.ceil(totalHostedBookings / bookingsHostedPageSize) ? "#5e6278" : "#b2b2b2",
                                  marginLeft: bookingsHostedPage === Math.ceil(totalHostedBookings / bookingsHostedPageSize) && "10px"
                                }}
                              >
                                Next Page
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  }
                  <div className="separator separator-dashed my-7" />
                  {(haveAccess(currentUser, 'review_list')) && <Reviews allReviews={allReviews} />}
                </div>
              </div>
            </div>
          </div>
        </KTCardBody>
        :
        <div className='d-flex text-center w-100 align-content-center justify-content-center' style={{ height: '70vh', alignItems: 'center', fontSize: '42px', color: '#ffa049', background: 'rgba(0,0,0,0.1)' }}>
          You do not have permission to view
        </div>
      }

      {showEditModal && (
        <UserEditModal
          user={user}
          setShowEditModal={setShowEditModal}
          getUser={getUser}
        />
      )}
      {showVehicleModal && (
        <VehicleModal
          user={vehicle}
          setShowEditModal={setShowVehicleModal}
          setVehicle={setVehicle}
          getUser={getUser}
          customerDetails={user}
        />
      )}
      {showListingModal && (
        <AddListingModal
          getUser={getUser}
          user={user}
          setShowListingModal={setShowListingModal}
        />
      )}
      {showMessageModal && (
        <MessageModal
          messageType={messageType}
          setShowMessageModal={setShowMessageModal}
          getUser={getUser}
          user={user}
        />
      )}
      {openAdvocateModal && (
        <AdvocateModal
          user={user}
          getUser={getUser}
          setOpenAdvocateModal={setOpenAdvocateModal}
        />
      )
      }
      {
        openPointsModal && (
          <PointsModal
            user={user}
            getUser={getUser}
            setOpenPointsModal={setOpenPointsModal}
            openTopUpWalletModal={openTopUpWalletModal}
            setOpenTopUpWalletModal={setOpenTopUpWalletModal}
          />
        )
      }
      {
        openAPIModal && (
          <ApiModal
            user={user}
            getUser={getUser}
            setOpenAPIModal={setOpenAPIModal}
          />
        )
      }
      {
        openOrgModal && (
          <OrgModal
            user={user}
            getUser={getUser}
            setOpenOrgModal={setOpenOrgModal}
          />
        )
      }
      {
        openFreezeWalletModal && (
          <WalletFreezeModal
            user={user}
            getUser={getUser}
            openFreezeWalletModal={openFreezeWalletModal}
            setOpenFreezeWalletModal={setOpenFreezeWalletModal}
          />
        )      
      }
       {
        openResetStripeConfirmationModal && (
          <ConfirmationModal
            data={user}
            setOpenConfirmationModal={setOpenResetStripeConfirmationModal}
            onClick={resetStripe}
          />
        )      
      }
    </KTCard>
  );
};

export default Profile;
