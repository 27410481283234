import { UserEditModalForm } from "./UserEditModalForm"

const UserEditModalFormWrapper = ({
  selectedListingId,
  setSelectedListingId,
  openTimelineModal,
  setOpenTimelineModal,
  listing,
  getListing,
  amenities
}) => {
  const itemIdForUpdate = null;
  const isLoading = false;

  if (selectedListingId) {
    return (
      <UserEditModalForm
        isUserLoading={isLoading}
        user={{ id: undefined }}
        setSelectedListingId={setSelectedListingId}
        selectedListingId={selectedListingId}
        listing={listing}
        getListing={getListing}
        openTimelineModal={openTimelineModal}
        setOpenTimelineModal={setOpenTimelineModal}
        amenities={amenities}
      />
    );
  }
  return null;
};

export { UserEditModalFormWrapper };
