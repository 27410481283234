import { useEffect } from "react";
import { ConfirmationModalHeader } from "./ConfirmationModalHeader";
import { ConfirmationModalFormWrapper } from "./ConfirmationModalFormWrapper";

const ConfirmationModal = ({
  isListing = false,
  data,
  onClick = () => {},
  setOpenConfirmationModal,
}) => {
  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  return (
    <>
      <div
        className="modal fade show d-block"
        id="kt_modal_add_user"
        role="dialog"
        tabIndex={-1}
        aria-modal="true"
      >
        <div className={`modal-dialog modal-dialog-centered mw-650px`}>
          <div className="modal-content">
            <ConfirmationModalHeader setOpenConfirmationModal={setOpenConfirmationModal} data={data} isListing={isListing} />
            <div className={`modal-body scroll-y`}>
              <ConfirmationModalFormWrapper setOpenConfirmationModal={setOpenConfirmationModal} onClick={onClick} isListing={isListing} />
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
};

export { ConfirmationModal };
